// export default new UserService();
export const authHeader = () => {
  return {
    headers: {
      authorization: getToken()
    }
  };
};

export const getToken = (): string => {
  const userStr = localStorage.getItem("user");
  let user = null;
  if (userStr) user = JSON.parse(userStr);
  if (user) {
    return `Bearer ${user?.token || user?.accessToken}`; // for Node.js Express back-end
  }

  return "";
};
