import styled from "styled-components";
const BreadcrumbWrapper = styled.div`
  padding: 8px 12px;

  ol {
    li {
      a {
        text-transform: uppercase;
      }
    }
  }
`;
export { BreadcrumbWrapper };

