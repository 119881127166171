import styled from "styled-components";
const AccountPageWrapper = styled.div`
  height: 500px;
  min-height: 750px;
  width: 100%;
  margin-bottom: 88px;

  svg {
    color: #2f2321;
    &.__action {
      color: #f7931e;
      cursor: pointer;

      &--off {
        color: #b5272e;
      }
      &--on {
        color: green;
      }
    }
  }
  .status {
    font-weight 600;
  }
`;

export { AccountPageWrapper };
