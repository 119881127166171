import styled from "styled-components";
import { StyledNavigationProps } from "types/interface.types";

import { GlobalColors } from "globalStyles";
//styles

const NavigationWrapper = styled.nav<StyledNavigationProps>`
  height: 100vh;
  min-height: 700px;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
  width: ${(NavigationProps) => (NavigationProps.clicked ? "100%" : "0")};
  opacity: ${(NavigationProps) => (NavigationProps.clicked ? "1" : "0")};
  transition: width 0.8s, opacity 0.8s;
`;
const NavigationAvatar = styled.div`
  margin-top: 140px !important;
  margin-bottom: 50px !important;
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;
  margin: auto;
  padding: 20px;
`;
const NavItems = styled.ul`
  position: absolute;
  list-style: none;
  top: 300px;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: justify;
  width: 100%;

  & .profileImage {
    & img {
      width: 250px;
      height: 250px;
      margin: -35px -43px;
    }
  }
  // if in portraite mode on small device, hide the profile image
  @media (max-height: 560px) {
    top: 220px;
    .profileImage {
      display: none;
    }
  }

  & li {
    & a {
      display: inline-block;
      //font-weight: 300;
      text-decoration: none;
      padding: 1rem 2rem;
      background-color: black;
      color: ${GlobalColors.colorWhite};
      background-image: linear-gradient(
        120deg,
        transparent 0%,
        transparent 50%,
        ${GlobalColors.colorWhite} 50%
      );
      background-size: 240%;
      transition: all 0.4s;
      &:hover,
      &:active {
        background-position: 100%;
        color: black;
        transform: translateX(1rem);
      }
    }
  }
`;

export { NavItems, NavigationWrapper, NavigationAvatar };
