import { GlobalColors, GlobalLayout } from "globalStyles";
import styled from "styled-components";

const HeaderInnerWrapper = styled.div`
  z-index: 500;
  background-color: ${GlobalColors.colorHeaderTopBarBackground};
  height: 42px;

  & h1,
  h2,
  h3,
  h4 {
    color: #ebebeb !important;
    font-weight: bold;
  }
`;
const HeaderContentWrapper = styled.div`
  // width: ${GlobalLayout.Content_Width};
  // margin: auto;
`;

const HeaderBadgeWrapper = styled.div`
  width: 74px;
  position: absolute;
  top: 12px;
  right: 98px;
  height: 34px;

  & > div {
    float: left;
  }

  & svg {
    margin-left: 10px;
    color: #fff;
    cursor: pointer;
  }

  @media (max-width: 397px) {
    right: 60px;
  }
`;
export { HeaderInnerWrapper, HeaderContentWrapper, HeaderBadgeWrapper };

