import { LoremIpsum } from "components";
import * as React from "react";
import MediaQuery from "react-responsive";
import { AdminPageProps } from "types/interface.types";
import { AccountPageWrapper } from "./account.styles";

const ProfilePage: React.FC<AdminPageProps> = () => {
  return (
    <AccountPageWrapper>
      <h1>Profile page</h1>
      <MediaQuery maxWidth={992}>
        <h2>[Mobile]</h2>
        <LoremIpsum />
      </MediaQuery>
      <MediaQuery minWidth={992}>
        <h2>[Desktop]</h2>
        <div className={"box"}>
          <LoremIpsum />
        </div>
        <LoremIpsum />
      </MediaQuery>
    </AccountPageWrapper>
  );
};

export default ProfilePage;
