export enum ActionTypes {
  // Shopping Cart
  RESET_CART = "RESET_CART",
  INITIALIZE_CART = "INITIALIZE_CART",
  ADD_OR_UPDATE_CART = "ADD_OR_UPDATE_CART",
  REMOVE_ITEM_FROM_CART = "REMOVE_FROM_CART",
  CLEAR_CART = "CLEAR_CART",
  GET_CART_ITEM_COUNT = "CART_ITEM_COUNT",

  // Products
  FETCH_PRODUCT = "FETCH_PRODUCT",
  FETCH_FEATURED_PRODUCT = "FETCH_FEATURED_PRODUCT",
  FETCH_CATEGORY = "FETCH_CATEGORY",
  FETCH_BRAND = "FETCH_BRAND",

  // Users
  SET_USER = "SET_USER",
  GET_USER = "GET_USER"
}
