import styled from "styled-components";

const CartLandingWrapper = styled.div`
  height: 500px;
  min-height: 20px;
  width: 100%;
  margin-bottom: 160px;

  svg {
    cursor: pointer;
    color: #2f2321;
  }

  img {
    width: 6vw;
    height: 4.5vh;
    margin-top: 4px;
  }
  & .confirm {
    margin: 10px 0;
    float: right;
    font-weight: 700;
  }
`;

export { CartLandingWrapper };
