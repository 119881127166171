import CartBadge from "components/cart/Cart.Badge";
import { Navbar } from "components/index";
import LoginBadge from "components/login/LoginBadge";
import HeaderSearch from "components/search/HeaderSearch";
import React from "react";
import { NavigationProps } from "types/interface.types";
import { HeaderBadgeWrapper, HeaderInnerWrapper } from "./header.styles";

const Header: React.FC<NavigationProps> = ({ onRouteChanged }) => {
  const handleRouteChanged = (): void => {
    if (onRouteChanged) {
      onRouteChanged();
    }
  };
  return (
    <HeaderInnerWrapper>
      {/* <HeaderContentWrapper className="main-content"> */}
      <div className="main-inner-wrapper">
        <HeaderSearch />
        <HeaderBadgeWrapper>
          <LoginBadge />
          <CartBadge />
        </HeaderBadgeWrapper>
        <Navbar onRouteChanged={handleRouteChanged} />
      </div>

      {/* </HeaderContentWrapper> */}
    </HeaderInnerWrapper>
  );
};
export default Header;
