/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid, Stepper, Table } from "@mantine/core";
import orderService from "api/order.service";
import { StepperOrderStage } from "common/constants";
import { formatDate } from "common/utils";
import BusyIndicator from "components/busy/BusyIndicator";
import React, { useEffect, useState } from "react";
import { Order, OrderDetails } from "types/model.types";
import { AccountPageWrapper } from "./account.styles";
import { OrderPreviewWrapper } from "./page.product.styles";

type OrderPreviewPageProps = {
  order: Order | undefined; // web order id
};

const OrderPeviewPage: React.FC<OrderPreviewPageProps> = ({ order }) => {
  const [busy, setBusy] = useState(false);
  const [active, setActive] = useState(1);
  const [orderDetails, setOrderDetails] = useState<OrderDetails>([]);

  useEffect(() => {
    let mounted = true;

    if (order) {
      let stage: StepperOrderStage = StepperOrderStage.PICKING;
      stage =
        StepperOrderStage[order.orderStage as keyof typeof StepperOrderStage];
      if (mounted) setActive(stage);

      orderService.getOrderDetails(order.orderId).then((json) => {
        if (mounted) setOrderDetails(json.data);
      });
    }

    return () => {
      mounted = false;
    };
  }, []);

  const rows = orderDetails.map((item) => (
    <tr key={item.orderDetailId}>
      <td>{item.productCode}</td>
      <td>{item.productDesc}</td>
      {/* <td className="price">{item.cartonPrice}</td> */}
      <td className="price">{item.ordered}</td>
      <td className="price">{item.supplied}</td>
    </tr>
  ));

  return (
    <OrderPreviewWrapper>
      <div className="preview">
        <Grid grow>
          <Grid.Col className="preview__header--title" span={12}>
            <h1>John Jacobson Ltd</h1>
          </Grid.Col>
          <Grid.Col span={3} className="preview__header--c1">
            <div>John Jacobson Ltd 107 Hutt Park Road</div>
            <div>Gracefield</div>
            <div>Lower Hutt 5010</div>
          </Grid.Col>
          <Grid.Col span={6} className="preview__header--c2">
            <div>John Jacobson Ltd</div>
            <div>P.O. Box 38381</div>
            <div>Wellington Mail Centre 5045</div>
          </Grid.Col>
          <Grid.Col span={3} className="preview__header--c3">
            <div>Phone 04 5695248</div>
            <div>Fax 04 5663019</div>
            <div>
              Email{" "}
              <a
                href={`mailto:info@jjl.co.nz?subject=Order #:${order?.orderId}`}
              >
                info@jjl.co.nz
              </a>
            </div>
          </Grid.Col>
          <Grid.Col span={6} className="preview__header--subTitle">
            <Stepper active={active} onStepClick={setActive} breakpoint="sm">
              <Stepper.Step
                label="Order Submitted"
                description="Web Order Created"
              ></Stepper.Step>
              {/* Picking */}
              <Stepper.Step
                label="Processing"
                description="Order being fulfilled"
              ></Stepper.Step>
              {/* Packing */}
              <Stepper.Step
                label="Processed"
                description="Order being packed"
              ></Stepper.Step>
              {/* Complete */}
              <Stepper.Step
                label="Complete"
                description="Order Complete"
              ></Stepper.Step>
              <Stepper.Completed>
                Completed, click back button to get to previous step
              </Stepper.Completed>
            </Stepper>
          </Grid.Col>
          <Grid.Col span={8} className="preview__header--c1">
            Date Ordered: {order && formatDate(order?.createdOn)}
          </Grid.Col>
          <Grid.Col span={4} className="preview__header--c3">
            <div>
              ORDER NO:<span>{order?.orderId}</span>
            </div>
            <div>
              CUSTOMER NO:<span>{order?.invoiceToDebtorCode}</span>
            </div>
            <div>
              GST:<span>20-898-763</span>
            </div>
          </Grid.Col>

          <Table striped>
            <thead>
              <tr>
                <th>Code</th>
                <th>Title</th>
                {/* <th className="price">Carton Price</th> */}
                <th className="price">Quantity Ordered</th>
                <th className="price">Quantity Supplied</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
        </Grid>
      </div>
      <AccountPageWrapper>
        <BusyIndicator open={busy}></BusyIndicator>
      </AccountPageWrapper>
    </OrderPreviewWrapper>
  );
};

export default OrderPeviewPage;
