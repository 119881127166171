import authService from "api/auth.service";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { store } from "redux/configureStore";
import * as cartActions from "redux/store/cart/thunks";
import { CartButtonProps } from "types/interface.types";
import { CartButtonWrapper } from "./cart.styles";

/**
 * Only add cart button if the user is logged in and is customer
 * @returns Cart button
 */
const AddCartButton: React.FC<CartButtonProps> = ({
  id /* web product id */,
  productCode /* legacy code */,
  title,
  price,
  addItemToCart,
  removeItemFromCart
}) => {
  const [clicked, setClicked] = useState<boolean>(
    store.getState().shoppingCart.items.some((x) => x.productId === id)
  );
  const [show, setShow] = useState<boolean>(authService.isCustomer());

  useEffect(() => {
    let mounted = true;

    function refresh() {
      if (mounted) {
        // ensure compontent still mounted... otherwise don't set value
        setShow(authService.isCustomer());
      }
    }

    window.addEventListener("storage", () => {
      // When storage changes refetch
      refresh();
    });

    return () => {
      // When the component unmounts remove the event listener
      window.removeEventListener("storage", refresh);
      mounted = false;
    };
  }, []);

  return (
    <>
      {show && (
        <CartButtonWrapper
          onClick={() => {
            if (clicked && id) {
              removeItemFromCart && removeItemFromCart(id.toString());
            } else {
              addItemToCart &&
                addItemToCart({
                  id: undefined /*web cart item id - new so undefined */,
                  productId: id?.toString(),
                  quantity: 1,
                  title,
                  price,
                  productCode
                });
            }
            setClicked(!clicked);
          }}
        >
          <div className="pretext">
            <i className={`fas ${clicked ? "fa-check" : "fa-cart-plus"}`}></i>{" "}
            Add
          </div>
        </CartButtonWrapper>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  addItemToCart: bindActionCreators(cartActions.addItemToCart, dispatch),
  removeItemFromCart: bindActionCreators(
    cartActions.removeItemFromCart,
    dispatch
  )
});

export default connect(undefined, mapDispatchToProps)(AddCartButton);
