import { Group, MantineTheme, Text, useMantineTheme } from "@mantine/core";
import { Dropzone, DropzoneStatus, MIME_TYPES } from "@mantine/dropzone";
import { LinearProgress } from "@mui/material";
import imageService from "api/image.service";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Icon as TablerIcon, Photo, Upload, X } from "tabler-icons-react";
import { AdminPageProps } from "types/interface.types";

function getIconColor(status: DropzoneStatus, theme: MantineTheme) {
  return status.accepted
    ? theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 4 : 6]
    : status.rejected
    ? theme.colors.red[theme.colorScheme === "dark" ? 4 : 6]
    : theme.colorScheme === "dark"
    ? theme.colors.dark[0]
    : theme.colors.gray[7];
}

function ImageUploadIcon({
  status,
  ...props
}: React.ComponentProps<TablerIcon> & { status: DropzoneStatus }) {
  if (status.accepted) {
    return <Upload {...props} />;
  }

  if (status.rejected) {
    return <X {...props} />;
  }

  return <Photo {...props} />;
}

export const dropzoneChildren = (
  status: DropzoneStatus,
  theme: MantineTheme
) => (
  <Group
    position="center"
    spacing="xl"
    style={{ minHeight: 220, pointerEvents: "none" }}
  >
    <ImageUploadIcon
      status={status}
      style={{ color: getIconColor(status, theme) }}
      size={80}
    />

    <div>
      <Text size="xl" inline>
        Drag images here or click to select files
      </Text>
      <Text size="sm" color="dimmed" inline mt={7}>
        Attach as many files as you like, each file should not exceed 5mb
      </Text>
    </div>
  </Group>
);

const ImageUpload: React.FC<AdminPageProps> = () => {
  const theme = useMantineTheme();
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <>
      <h1>Image Upload</h1>
      {loading && <LinearProgress />}
      <Dropzone
        loading={loading}
        accept={[MIME_TYPES.png, MIME_TYPES.jpeg]}
        onDrop={(files) => {
          setLoading(true);

          for (let i = 0; i < files.length; i++) {
            imageService
              .upload(files[i])
              .then()
              .catch((e) => {
                toast.error(e);
              });
          }

          setTimeout(() => {
            setLoading(false);
          }, 5000);
        }}
        onReject={(files) =>
          console.log("files rejected: " + JSON.stringify(files))
        }
        maxSize={5 * 1024 ** 2}
      >
        {(status) => dropzoneChildren(status, theme)}
      </Dropzone>
    </>
  );
};

export default ImageUpload;
