import axios from "axios";
import { REST_URL } from "common/constants";
import { getToken } from "./common.service";

class ImageService {
  /**
   *
   * @returns array of file names
   */
  async get() {
    const response = await axios.get(REST_URL + "image/get", {
      headers: {
        "Content-Type": "multipart/form-data",
        authorization: getToken()
      }
    });

    if (response) {
      return response.data.images;
    }
    return [];
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async upload(file: string | Blob) {
    const fd = new FormData();
    fd.append("file", file);

    const response = await axios.post(REST_URL + "image/upload", fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        authorization: getToken()
      }
    });

    return response;
  }
}

export default new ImageService();
