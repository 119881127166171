import { MobileProfile } from "assets/index";
import React from "react";
import { NavLink } from "react-router-dom";
import { StyledNavigationProps } from "types/interface.types";
import {
  NavigationAvatar,
  NavigationWrapper,
  NavItems
} from "./navigation.styles";

function Navigation({ clicked, handleClick }: StyledNavigationProps) {
  return (
    <NavigationWrapper clicked={clicked}>
      <NavItems>
        <li className="profileImage">
          <NavigationAvatar>
            <img src={MobileProfile} />
          </NavigationAvatar>
        </li>
        <li>
          <NavLink
            id="hamburger-nav_my-account"
            onClick={handleClick}
            className="h3"
            to="/my-account"
            key="side-nav_my-account"
          >
            My Account
          </NavLink>
        </li>
        <li>
          <NavLink
            id="hamburger-nav_home"
            onClick={handleClick}
            className="h3"
            to="/"
            key="side-nav_home-link"
          >
            Home
          </NavLink>
        </li>
        <li>
          <NavLink
            id="hamburger-nav_products"
            onClick={handleClick}
            className="h3"
            to="/products"
            key="side-nav_products-link"
          >
            Products
          </NavLink>
        </li>
        <li>
          <NavLink
            id="hamburger-nav_about-us"
            onClick={handleClick}
            className="h3"
            to="/about-us"
            key="side-nav_about-us"
          >
            About Us
          </NavLink>
        </li>
        <li>
          <NavLink
            id="hamburger-nav_contact-us"
            onClick={handleClick}
            className="h3"
            to="/contact-us"
            key="side-contact-us"
          >
            Contact Us
          </NavLink>
        </li>
      </NavItems>
    </NavigationWrapper>
  );
}

export default Navigation;
