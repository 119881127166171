import styled from "styled-components";
const DialogWrapper = styled.div`
  & img {
    cursor: pointer;
  }
  .form {
    &-title {
      font-weight: 600;
      font-size: 1.2rem;
      margin-bottom: 10px !important;
    }
  }
`;
export { DialogWrapper };
