import axios from "axios";
import { REST_URL } from "common/constants";
import { EmailNotification } from "types/interface.types";

class EmailService {
  async send(payload: EmailNotification) {
    const response = await axios.post(REST_URL + "email/send", payload);
    return response;
  }
}

export default new EmailService();
