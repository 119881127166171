import styled from "styled-components";
const DropdownWrapper = styled.div`
  .select {
    z-index: 10;

    &--size {
      width: 300px;

      &-lg {
        width: 380px;
      }
    }
  }
`;
export { DropdownWrapper };

