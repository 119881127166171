import React from "react";
import { ErrorPageWrapper } from "./error.styles";

const NotFound: React.FC = () => {
  return (
    <ErrorPageWrapper>
      <h1>404</h1>
      <h2>This page does not exist</h2>
      <p>Oops, the page you are looking for can&apos;t be found!</p>
      <p>
        Return to <a href="/">Home</a>
      </p>
    </ErrorPageWrapper>
  );
};

export default NotFound;
