import axios from "axios";
import { REST_URL } from "common/constants";
import { Brand } from "types/model.types";
import { getToken } from "./common.service";

class BrandService {
  async get() {
    const response = await axios.get(REST_URL + "brand/get");
    return response ?? [];
  }

  async addEdit(b: Brand) {
    const response = await axios.post(
      REST_URL +
        `brand/${b.id !== undefined && b.id !== "" ? "update" : "add"}`,
      {
        nm: b.name,
        id: b.id
      },
      {
        headers: {
          authorization: getToken()
        }
      }
    );

    return response;
  }

  async delete(id: string | number) {
    const response = await axios.post(
      REST_URL + "brand/delete",
      {
        id
      },
      {
        headers: {
          authorization: getToken()
        }
      }
    );

    return response;
  }
}

export default new BrandService();
