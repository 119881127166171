import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { ISelectProps, LookupDatum } from "types/interface.types";
import { DropdownWrapper } from "./dropdown.styles";

const SelectCategory: React.FC<ISelectProps> = ({
  defaultOption,
  options,
  onSelectionChanged
}) => {
  const didMountRef = useRef(false);
  const [selectedOption, setSelectedOption] = useState<LookupDatum | null>(
    null
  );

  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true;
      defaultOption && setSelectedOption(defaultOption);
    }
  }, [options, defaultOption]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSelectionChange = (_: any) => {
    setSelectedOption(_);
    onSelectionChanged(_);
  };

  return (
    <DropdownWrapper>
      <Select
        id="categorySelection"
        value={selectedOption}
        onChange={handleSelectionChange}
        className="select select--size select--size-lg"
        isSearchable
        isClearable
        options={options}
      ></Select>
    </DropdownWrapper>
  );
};

export default SelectCategory;
