import cartService from "api/cart.service";
import { Dispatch } from "redux";
import { AppState } from "redux/configureStore";
import { Cart, CartItem } from "types/model.types";
import { AppActions } from "..";
import { ActionTypes } from "../types";

//get cart
export const getCart = (state: AppState): Cart => state.shoppingCart;

// add/update single item from cart
// this will either create new item with quan  = 1 or add/remove from quan if already exist
const addItemToCartSuccess = (item: CartItem): AppActions => ({
  type: ActionTypes.ADD_OR_UPDATE_CART,
  payload: item
});

export const addItemToCart = (item: CartItem) => {
  return async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const cartId: string | number = getState().shoppingCart.id ?? "";
    if (item.productId) {
      await cartService.updateCart(
        cartId,
        item.productId?.toString(),
        item.quantity
      );
    }
    dispatch(addItemToCartSuccess(item));
  };
};

// remove item from cart
const removeItemFromCartSuccess = (
  id: string | number | undefined
): AppActions => ({
  type: ActionTypes.REMOVE_ITEM_FROM_CART,
  payload: id
});
/**
 *
 * @param id The WebProductId
 * @returns
 */
export const removeItemFromCart = (id: string) => {
  return async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const cartId: string | number = getState().shoppingCart.id ?? "";
    await cartService.remove(cartId, id);
    dispatch(removeItemFromCartSuccess(id));
  };
};

// clear out cart
export const clearCart = (): AppActions => ({
  type: ActionTypes.CLEAR_CART
});

const initializeCartSuccess = (cart: Cart): AppActions => ({
  type: ActionTypes.INITIALIZE_CART,
  payload: cart
});
/**
 * Initialize cart when user first logs in... there's always 1 active cart for the customer.
 * @returns
 */
export const initializeCart = () => {
  return async (dispatch: Dispatch<AppActions>) => {
    const c = await cartService.initialize();
    if (c.data) {
      const cart: Cart = {
        id: c.data.id,
        createdOn: new Date(c.data.createdOn),
        items: []
      };

      const items = await cartService.get();
      // get cart items
      if (items && items.data) {
        cart.items = items.data;
      }

      dispatch(initializeCartSuccess(cart));
    } else dispatch(resetCart());
  };
};

export const resetCart = (): AppActions => ({
  type: ActionTypes.RESET_CART
});
