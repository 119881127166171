// Product details page

import ListItemIcon from "@mui/material/ListItemIcon";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  ShoppingCart,
  ShoppingCartOff,
  ShoppingCartX
} from "tabler-icons-react";
import { AccountMenuWrapper } from "./landing.styles";

const MembersMenu: React.FC = () => {
  window.scrollTo(0, 0); // always scroll to top on 1st load
  const history = useHistory();
  const location = useLocation();

  const handleMenuClick = (nm: string): void => {
    history.push(`/my-account/${nm}`);
    close();
  };

  return (
    <AccountMenuWrapper>
      <>
        <MenuItem
          selected={location.pathname === "/my-account/orders"}
          onClick={() => {
            handleMenuClick("orders");
          }}
        >
          <ListItemIcon>
            <ShoppingCart fontSize="small" />
          </ListItemIcon>{" "}
          Active Orders
        </MenuItem>
        <MenuItem
          selected={
            location.pathname === "/my-account/back-orders" ||
            location.pathname === "/my-account"
          }
          onClick={() => {
            handleMenuClick("back-orders");
          }}
        >
          <ListItemIcon>
            <ShoppingCartOff fontSize="small" />
          </ListItemIcon>
          Back Orders
        </MenuItem>

        <MenuItem
          selected={location.pathname === "/my-account/completed-orders"}
          onClick={() => {
            handleMenuClick("completed-orders");
          }}
        >
          <ListItemIcon>
            <ShoppingCartX fontSize="small" />
          </ListItemIcon>
          Completed Orders
        </MenuItem>
      </>
    </AccountMenuWrapper>
  );
};

export default MembersMenu;
