/**
 * Check if user is a super administrator
 * @param r The user role
 * @returns True if user is a super admin
 */

export const IsSuperAdmin = (r: string) => {
  if (r && r === process.env.REACT_APP_ROLE_SUPER_ADMIN) return true;
  return false;
};
/**
 * Check if user is in all admin roles
 * @param r The user role
 * @returns True if user is in all admin role(s)
 */
export const IsAdmin = (r: string | undefined) => {
  if (r === undefined) return false;

  const roles = process.env.REACT_APP_ROLE_ALL_ADMIN?.split(";"); // required role

  if (r && roles && roles.includes(r)) return true;
  return false;
};

export const IsCustomer = (r: string | undefined): boolean => {
  if (r === undefined) return false;
  if (r && r === process.env.REACT_APP_ROLE_MEMBERS) return true;
  return false;
};
