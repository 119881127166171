import {
  Chart,
  ChartData,
  ChartOptions,
  registerables,
  ScatterDataPoint
} from "chart.js";
import React, { useRef } from "react";

Chart.register(...registerables);

type ChartProps = {
  options: ChartOptions;
  data: ChartData<"line", (number | ScatterDataPoint | null)[], unknown>;
};

const LineChart: React.FC<ChartProps> = ({ options, data }: ChartProps) => {
  // use a ref to store the chart instance since it it mutable
  const chartRef = useRef<Chart | null>(null);
  // callback creates the chart on the canvas element
  const canvasCallback = (canvas: HTMLCanvasElement | null) => {
    if (!canvas) return;
    const ctx = canvas.getContext("2d");
    if (chartRef.current) {
      chartRef.current.destroy();
    }
    if (ctx) {
      chartRef.current = new Chart(ctx, {
        type: "line",
        data: data,
        options: options
      });
    }
  };
  return <canvas ref={canvasCallback} />;
};

export default LineChart;
