import {
  Container,
  createStyles,
  SimpleGrid,
  Text,
  ThemeIcon,
  Title,
  useMantineTheme
} from "@mantine/core";
import pageService from "api/page.service";
import AdminPageBoundary from "components/elements/AdminPageBoundary";
import RtfEditor from "components/elements/editor/RtfEditor";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import {
  Icon as TablerIcon
} from "tabler-icons-react";
import { PageProps, RichEditorRef } from "types/interface.types";
import { AboutLandingWrapper } from "./landing.styles";

interface FeatureProps {
  icon: TablerIcon;
  title: React.ReactNode;
  description: React.ReactNode;
}

export function Feature({ icon: Icon, title, description }: FeatureProps) {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  return (
    <div>
      <ThemeIcon
        className={classes.themeIcon}
        variant="filled"
        size={40}
        radius={40}
      >
        <Icon color="#fff" style={{ width: 20, height: 20 }} />
      </ThemeIcon>
      <Text style={{ marginTop: theme.spacing.sm, marginBottom: 7 }}>
        {title}
      </Text>
      <Text size="sm" color="dimmed" style={{ lineHeight: 1.6 }}>
        {description}
      </Text>
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: theme.spacing.xl * 2,
    paddingBottom: theme.spacing.xl * 4
  },
  card: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[0]
  },
  themeIcon: {
    backgroundColor: "#F7931D"
  },
  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 900,
    marginBottom: theme.spacing.md,
    textAlign: "center",

    [theme.fn.smallerThan("sm")]: {
      fontSize: 28,
      textAlign: "left"
    }
  },

  description: {
    textAlign: "center",

    [theme.fn.smallerThan("sm")]: {
      textAlign: "left"
    }
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    borderRadius: theme.radius.md,
    height: 90,
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    transition: "box-shadow 150ms ease, transform 100ms ease",

    "&:hover": {
      boxShadow: `${theme.shadows.md} !important`,
      transform: "scale(1.05)"
    }
  }
}));
interface FeaturesGridProps {
  title?: React.ReactNode;
  description?: React.ReactNode;
  data?: FeatureProps[];
}
const Landing: React.FC<PageProps & FeaturesGridProps> = function () {
  const didMountRef = useRef(false);
  const editorRef = useRef<RichEditorRef>(null);
  const { classes } = useStyles();
  const [markup, setMarkup] = useState<string>("");
  const [busy, setBusy] = useState<boolean>(true);
  const [edit, setEdit] = useState<boolean>(false);
  const theme = useMantineTheme();

  // const items = xmockdata.map((item) => (
  //   <UnstyledButton key={item.title} className={classes.item}>
  //     <item.icon color={theme.colors[item.color][6]} size={32} />
  //     <Text size="xs" mt={7}>
  //       {item.title}
  //     </Text>
  //   </UnstyledButton>
  // ));

  useEffect(() => {
    if (didMountRef.current) {
      // update
    } else {
      // initial mount: ComponentDidMount
      didMountRef.current = true;
      window.scrollTo(0, 0); // always scroll to top on 1st load

      pageService.get("about-us").then((json) => {
        setMarkup(json.data.HtmlMarkup ?? "no page data");
        setBusy(false);
      });
    }
  }, []);
  return (
    <AdminPageBoundary
      busy={busy}
      onEditClicked={() => {
        setEdit(!edit);
      } }
      onSaveClicked={() => {
        const markup = editorRef.current?.getContent() ?? "";
        pageService.update("about-us", markup).then(() => {
          setEdit(false);
          setMarkup(markup);
          toast.success("Page updated");
        });
      } }
    >
      {edit ? (
        <RtfEditor content={markup} stale={false} ref={editorRef} />
      ) : (
        <>
          <AboutLandingWrapper dangerouslySetInnerHTML={{ __html: markup }} />
          <Container style={{ display: "none" }} className={classes.wrapper}>
            <Title className={classes.title} />

            <Container size={560} p={0}>
              <Text size="sm" className={classes.description}>
                &nbsp;
              </Text>
            </Container>

            <SimpleGrid
              mt={60}
              cols={3}
              spacing={theme.spacing.xl * 2}
              breakpoints={[
                { maxWidth: 980, cols: 2, spacing: "xl" },
                { maxWidth: 755, cols: 1, spacing: "xl" }
              ]}
            ></SimpleGrid>
          </Container>
        </>
      )}
    </AdminPageBoundary>
  );
};

export default Landing;
