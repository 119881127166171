import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import "reactjs-popup/dist/index.css";
import { AppState } from "redux/configureStore";
import { CartWrapper } from "./cart.styles";

type CartBadgeProps = {
  total?: number;
};

const CartBadge: React.FC<CartBadgeProps> = ({ total }) => {
  const history = useHistory();

  return (
    <CartWrapper
      onClick={() => {
        history.push("/Cart");
      }}
    >
      <ShoppingCartOutlinedIcon className="cart" />
      <span>{total}</span>
    </CartWrapper>
  );
};

const mapStateToProps = (state: AppState): CartBadgeProps => ({
  total: state.shoppingCart.items.length
});

export default connect(mapStateToProps)(CartBadge);
