import { Button, Group, Paper, Text } from "@mantine/core";
import NotificationAddIcon from "@mui/icons-material/NotificationAdd";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import React from "react";
import { toast } from "react-toastify";
import { AdminPageProps } from "types/interface.types";
import { useStyles } from "../mantine.styles";
import { AccountPageWrapper } from "./account.styles";

const HashDataPage: React.FC<AdminPageProps> = () => {
  const { classes } = useStyles();
  return (
    <AccountPageWrapper>
      <Paper shadow="md" radius="lg">
        <div className={classes.wrapper}>
          <form
            className={classes.form}
            onSubmit={(event) => event.preventDefault()}
          >
            <Text size="lg" weight={700} className={classes.title}>
              Hash Data
            </Text>

            <div className={classes.fields}>
              <Group position="right" mt="md">
                <Button
                  onClick={() => {
                    toast.info("Under construction");
                  }}
                  leftIcon={<NotificationAddIcon style={{ color: "#fff" }} />}
                >
                  Send Notification
                </Button>
                {"  "}
                <Button
                  onClick={() => {
                    toast.info("Under construction");
                  }}
                  leftIcon={<NotificationsOffIcon style={{ color: "#fff" }} />}
                >
                  Clear Notifications
                </Button>
              </Group>
            </div>
          </form>
        </div>
      </Paper>
    </AccountPageWrapper>
  );
};

export default HashDataPage;
