import { Tabs } from "@mantine/core";
import React, { useRef } from "react";
import { Accessible, LockAccess } from "tabler-icons-react";
import { LoginProps } from "types/interface.types";
import Login from "./Login";
import { LoginWrapper } from "./login.styles";
import Register from "./Register";

const Landing: React.FC<LoginProps> = ({ onLoginSuccessful }) => {
  const registerTagRef = useRef<HTMLButtonElement>(null);

  const handleRegisterRequest = () => {
    if (registerTagRef) {
      registerTagRef.current?.click();
    }
  };

  return (
    <LoginWrapper>
      <Tabs>
        <Tabs.Tab
          label="Sign In"
          icon={<LockAccess size={28} strokeWidth={1} />}
        >
          <Login
            onLoginSuccessful={onLoginSuccessful}
            onRegisterRequest={handleRegisterRequest}
          />
        </Tabs.Tab>
        <Tabs.Tab
          ref={registerTagRef}
          label="Request Business Account"
          icon={<Accessible size={28} strokeWidth={1} />}
        >
          <Register />
        </Tabs.Tab>
      </Tabs>
    </LoginWrapper>
  );
};

export default Landing;
