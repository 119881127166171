import React, { useState } from "react";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators } from "redux";
import { ThunkDispatch } from "redux-thunk";
import * as cartActions from "redux/store/cart/thunks";
import { Minus, Plus } from "tabler-icons-react";
import { CartButtonProps } from "types/interface.types";
import { AddRemoveCartButtonWrapper } from "./cart.styles";

/**
 * Only add cart button if the user is logged in and is customer
 * @returns Cart button
 */
const AddRemoveCartButton: React.FC<CartButtonProps> = ({
  id,
  productCode,
  quantity,
  addItemToCart
}) => {
  const [quan, setQuan] = useState<number>(quantity);

  return (
    <AddRemoveCartButtonWrapper>
      <span
        className="minus"
        onClick={() => {
          const q = quan - 1 > 0 ? quan - 1 : 0;
          setQuan(q);
          addItemToCart &&
            addItemToCart({
              id: undefined,
              productId: id?.toString(),
              productCode,
              quantity: q
            });
        }}
      >
        <Minus size={16} />
      </span>
      <label>{quan}</label>
      <span
        className="plus"
        onClick={() => {
          const q: number = quan + 1 <= 1000 ? quan + 1 : 1000;
          setQuan(q);
          addItemToCart &&
            addItemToCart({
              id: undefined,
              productId: id?.toString(),
              productCode,
              quantity: q
            });
        }}
      >
        <Plus size={16} />
      </span>
    </AddRemoveCartButtonWrapper>
  );
};

// redux
const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  addItemToCart: bindActionCreators(cartActions.addItemToCart, dispatch)
});

export default connect(undefined, mapDispatchToProps)(AddRemoveCartButton);
