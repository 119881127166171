import authService from "api/auth.service";
import AccessDenied from "components/error/AccessDenied";
import {
  AboutLanding,
  AccountLanding,
  CartLanding,
  ContactLanding,
  Home,
  PageNotFound,
  ProductDetails,
  ProductLanding
} from "components/index";
import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { JwtAuthData, PageProps } from "types/interface.types";
import { DefaultLayoutWrapper } from "./defaultLayout.styles";

const DefaultLayout: React.FC<PageProps> = () => {
  const [userData, setUserData] = useState<JwtAuthData | null>(
    authService.getCurrentUser()
  );

  const history = useHistory();

  window.scrollTo(0, 0); // always scroll to top on 1st load

  useEffect(() => {
    let mounted = true;

    function refetch() {
      const user = authService.getCurrentUser();
      if (mounted) setUserData(user);
    }

    window.addEventListener("storage", () => {
      // When storage changes refetch
      refetch();
    });

    // initial mount
    const loggedInUser = authService.getCurrentUser();
    if (userData !== loggedInUser) {
      setUserData(loggedInUser);
    }

    return () => {
      // When the component unmounts remove the event listener
      window.removeEventListener("storage", refetch);
      mounted = false;
    };
  }, []);

  const handleRouteChange = (route: string): void => {
    history.push(route);
  };

  const switchPage = (): JSX.Element => {
    return (
      <Switch>
        <Route
          path="/"
          exact
          render={(): JSX.Element => <Home onChangeRoute={handleRouteChange} />}
        />
        <Route
          path="/home"
          exact
          render={(): JSX.Element => <Home onChangeRoute={handleRouteChange} />}
        />
        <Route
          path="/about-us"
          exact
          render={(): JSX.Element => <AboutLanding />}
        />
        <Route
          path="/products"
          exact
          render={(): JSX.Element => <ProductLanding />}
        />
        <Route
          path="/products/details/:id"
          exact
          render={(): JSX.Element => <ProductDetails />}
        />
        <Route
          path="/contact-us"
          exact
          render={(): JSX.Element => <ContactLanding />}
        />
        <Route path="/cart" render={(): JSX.Element => <CartLanding />} />
        {/* <Route path="/styles" render={(): JSX.Element => <ContactLanding />} /> */}
        {userData !== undefined && userData !== null ? (
          <>
            <Route
              path="/my-account"
              exact
              render={(): JSX.Element => <AccountLanding />}
            />
            <Route
              path="/my-account/:c"
              render={(): JSX.Element => <AccountLanding />}
            />{" "}
          </>
        ) : (
          <>
            <Route
              path="/my-account"
              render={(): JSX.Element => <AccessDenied />}
            />
          </>
        )}
        <Route path="*" render={(): JSX.Element => <PageNotFound />} />
      </Switch>
    );
  };

  return (
    <DefaultLayoutWrapper className="main-inner-wrapper">
      {switchPage()} {/* switch page base on route */}
    </DefaultLayoutWrapper>
  );
};
export default DefaultLayout;
