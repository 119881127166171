import { createGlobalStyle } from "styled-components";

interface IGlobalVariablesConstants {
  fontSizeBiggest: string;
  fontSizeBigger: string;
  fontSizeBig: string;
  fontSizeSmall: string;
  fontSizeMedium: string;
}
interface IGlobalColorConstants {
  colorWhite: string;
  colorBlack: string;
  colorPrimary: string;
  colorSecondary: string;
  colorDefaultFont: string;
  colorWhiteSmoke: string;
  colorTransparent: string;
  colorYellow: string;
  colorOrange: string;
  colorDefaultBackground: string;
  colorHeaderBackground: string;
  colorFooterBackground: string;
  colorHeaderTopBarBackground: string;
  colorNavSelector: string;
}

interface IGlobalLayoutConstants {
  Footer_Height: string;
  Header_Height: string;
  Content_Width: string;
}

const GlobalVariables: IGlobalVariablesConstants = {
  fontSizeBigger: "1.9375rem",
  fontSizeBiggest: "2.4375rem",
  fontSizeBig: "1.375rem",
  fontSizeSmall: "1.18rem",
  fontSizeMedium: "1.75rem"
};

export const GlobalLayout: IGlobalLayoutConstants = {
  Footer_Height: "40px",
  Header_Height: "140px",
  Content_Width: "75vw"
};

export const GlobalColors: IGlobalColorConstants = {
  colorWhite: "#fff",
  colorBlack: "#000",
  colorPrimary: "#B5272E",
  colorSecondary: "#E56425",
  colorDefaultFont: "#000",
  colorWhiteSmoke: "whitesmoke",
  colorTransparent: "transparent",
  colorYellow: "#FBB03B",
  colorOrange: "#F7931E",
  colorDefaultBackground: "#333", //#f3f3f3",
  colorNavSelector: "#E56425",
  colorHeaderBackground: "#ffffff",
  colorHeaderTopBarBackground: "#B5272E",
  colorFooterBackground: "#4B4B4B"
};

const GlobalStyle = createGlobalStyle`
  * { 
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style-type:none;
    font-family: 'PT Sans', sans-serif;
    font-weight:400;
  }  

  body {
    background-color:#4B4B4B;
    margin:0; 
    line-height: 1.1rem; 
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  table { 
    width: 100%; 
    border-collapse: collapse; 

    td, th { 
      padding: 10px; 
      text-align: left; 
      &:first-child{
        white-space: nowrap;
      }
    }
  }

  .section {
    margin-top:20px;
    margin-bottom: 30px;
    font-size: 1.8rem;
    line-height:2rem;
    &-title {
      font-weight: 700;
    }
  }

  // convert tabel to responsive layout -display as div instead of table/row/colums
  @media 
  only screen and (max-width: 760px), 
    (min-device-width: 768px) and (max-device-width: 1024px)  {

    /* Force table to not be like tables anymore */
    table, thead, tbody, th, td, tr { 
      display: block; 
    }
	
    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr { 
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    td { 
      /* Behave  like a "row" */
      border: none;
      position: relative;
    } 
  }

  h1, h2 {
    font-weight: 600;
    font-size: ${GlobalVariables.fontSizeBig};
  }
  h3, h4, h5, .h3{
    font-weight: 400;
    font-size: ${GlobalVariables.fontSizeMedium};
  }
  h1, h2, h3, h4, h5 { 
    color: #000;
    margin:18px 0; 
  }
  p {
      margin: 10px 10px;
      text-align: justify;
      text-justify: inter-word;
      line-height:20pt;
  }

  .main-inner-wrapper {
    width: ${GlobalLayout.Content_Width};
    margin: 0 auto;
    
    // screen too small...mobile phones...use full width
    @media only screen and (max-width:820px) {
        width:98vw;
        //border:1px dotted #ccc;
    }
  }

  .btn {
    //https://astronautweb.co/snippet/font-awesome/
    border: none;
    border-radius:5px;
    color: inherit;
    font-size:1.1em;
    background: none;
    cursor: pointer;
    padding: 5px 10px;
    display: inline-block;
    margin:4px 4px 4px 0;
    //text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
    outline: none;
    position: relative;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
     
    &-default {
      background: #3498db;
      color: #fff;
      &:hover {
         background: #2980b9;
      }
      &:active {
        background: #2980b9;
        top: 2px;
      }
    }
    &-disabled {
      background: #ccc;
      color: #fff;
      &:hover {
         background: #ddd;
      }
    }
  }
  /**
   * Popup styles
   */
 
  .modal {
    & button.close {
      display:none;
    }

    & div.header {
      text-align:center;
      font-size:30pt;
    }
    & div.content {
      padding:10px 0;
    }
    & div.actions {
      text-align:right;

      & button {
        margin:10px 0px 10px 10px;
      }
    }
  }
 
  .box {
    //margin:20px 0!important;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    border: 1px solid #C2C2C2;
    box-shadow: 1px 1px 4px #EBEBEB;
    -moz-box-shadow: 1px 1px 4px #EBEBEB;
    -webkit-box-shadow: 1px 1px 4px #EBEBEB;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    //padding: 7px;
    padding:10px 20px;
    outline: none;
  }
  .product {
    &--price {
      text-align: center;
      font-weight: 700;
      font-size: 1.3rem;

      &-money {
        &:before {
          content: "$";
        }
      }
      em {
        font-weight: 400;
        display: block;
        font-size: 0.7rem;
      }
    }
  }

  .image-wrapper {
    //overflow: hidden;
    //position: relative;
    //justify-content: center;

    &__f {
      height: 180px;
    }

    &__d {
      height: 360px;
      width: 360px;
    }

    & img { 
      //  min-width: 100%;
      //  height:auto;
      }
    }
  }

  //Main form styles
  .form {
    & .form-group {
      & label {
          display: block;
          padding-top:15px;
          text-transform: uppercase;
          font-weight:bold;

          &.required {
              &::after {
                  color:red;
                  content: " *";
              }
          }
      }
      & div {
        &.alert {
          color: #cc0033;
          display: inline-block;
          font-size: 12px;
          line-height: 15px;
          margin: 5px 0 0;
          // &-danger {
          // }
        }
      }

      & input {
        width: 100%;
        font-size:1.1rem;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        border: 1px solid #C2C2C2;
        box-shadow: 1px 1px 4px #EBEBEB;
        -moz-box-shadow: 1px 1px 4px #EBEBEB;
        -webkit-box-shadow: 1px 1px 4px #EBEBEB;
        border-radius: 3px;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        padding: 7px;
        outline: none;

        &:focus {
            border: 1px solid #0C0;
        }
      }
      & button,
      & input[type=button],
      & input[type=submit],
      & input[type=reset] {
        background-color: #04AA6D;
        border: none;
        color: #fff;
        font-size:1.2rem;
        padding: 14px 28px;
        margin:10px 0px;
        text-decoration: none;
        cursor: pointer;
        
        &:hover {
          background: #EA7B00;
        }
      }
    }
  }
  
  @media(min-width:576px){
    h1, h2 {
      font-size:${GlobalVariables.fontSizeBiggest};
    }
  }

  // mobile
  @media(max-width:992px){
    // #main-wrapper{
    //   //margin: 8.2vw 11.3vw;
    //   height:100%;
    // }
  }
  // desktop
  @media(min-width:992px){
    // #main-wrapper{
    //   //margin: 8.2vw 11.3vw;
    // }
  }
`;

export default GlobalStyle;
