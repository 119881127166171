import styled from "styled-components";
const ContactLandingWrapper = styled.div`
  img {
    width: 72vw;
    width: 100%;
    height: 45vh;
  }

  .z2 {
    margin-bottom: 20px;
    &-col1 {
      border-right: 3px solid #eee;
    }
    &-col2 {
      padding-left: 20px;
    }
  }

  .contact-grp {
    backgrround-color: yellow !important;
    font-family: verdana !important;

    color: #fff;
    font-weight: 400;
    font-size: 0.8em;

    &-label {
      font-size: 0.7rem;
    }
    &-text {
      margin-left: 33px;
    }

    & > div {
      margin-bottom: 10px;
    }

    svg {
      float: left;
      margin-right: 10px;
    }
  }

  @media only screen and (max-width: 820px) {
    img {
      width: 100%;
      height: 25vh;
    }
    .z2 {
      &-col1 {
        display: block;
        width: 100% !important;
      }
      &-col2 {
        display: block;
        width: 100% !important;
      }
    }
  }
`;
export { ContactLandingWrapper };
