import styled from "styled-components";
type ProductLandingWrapperType = {
  Total?: number;
  Columns?: number;
};
const ProductLandingWrapper = styled.div<ProductLandingWrapperType>`
  float: left;
  width: 99%;

  h1,
  h2,
  h3,
  h4 {
    line-height: 42px;
    text-transform: none;
  }
  & h4 {
    margin: 20px 0;
  }

  .product-filter {
    font-weight: 400;

    .itemSelected {
      color: #b5262e;
      font-weight: 700;
    }
    span {
      cursor: pointer;
      .itemCount {
        font-weight: 400;
        font-size: 0.8rem;
        color: gray;
        &:before {
          content: " (";
        }
        &:after {
          content: ")";
        }
      }
    }
  }

  .image-outter-wrapper {
    margin: auto;
    width: 160px;
    min-height: 180px;
  }
  .image-wrapper {
    & img {
      //    width: 50% !important;
    }
  }

  .product {
    .title-menu {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      svg {
        float: left;
        position: relative;
        top: 18px;
        width: 42px;
        height: 42px;
        cursor: pointer;
      }
      h1 {
        display: inline-block;
        text-indent: 10px;
      }
    }
    &__left-container {
      @media (max-width: 800px) {
        //display: none;
      }
    }
    &__right-container {
      padding-bottom: 50px;
      padding-left: 20px;

      .box {
        margin: 0 !important;
      }

      //& img,
      & svg {
        //width: 10vw;
        //height: 15vh;
        //margin: auto;
        //display: block;
        //align: center;
        //margin-bottom: 20px;

        @media (max-width: 1100px) {
          height: 8.8vh;
          width: 12vw;
        }
      }
      &--item {
        padding: 8px;
        border-bottom: 1px solid #c2c2c2;
        border-right: 1px solid #c2c2c2;
        min-height: 350px !important;

        &:nth-child(3n + 0) {
          border-right: none;
        }
        &:nth-last-child(-n + ${({ Columns }) => Columns ?? 3}) {
          border-bottom: none;
        }
      }
      &--title {
        /* Both of the following are required for text-overflow */
        font-weight: 400;
        min-height: 140px;
        max-width: 320px;
        font-size: 0.9rem !important;
      }
      &--cart {
        float: right;
        margin: 10px;
      }

      // Switch to list view
      @media (max-width: 600px) {
        .title-menu {
          svg {
            top: 28px;
            width: 22px;
            height: 22px;
          }
        }

        //& img,
        & svg {
          width: 13vw;
          height: 8vh;
          display: inline-block;
        }
        &--item {
          min-height: 150px !important;
          border-bottom: 1px solid #c2c2c2;
          border-right: none;

          & > * {
            position: relative;
            display: inline-block;
          }
        }
        &--title {
          top: -10px;
          white-space: normal; // cancel ellipsis overflow
          font-weight: 400;
          font-size: 1.1rem !important;
          width: 43vw;
          margin-left: 5px;

          @media (max-width: 400px) {
            width: 32vw;
          }
        }
        &--price {
          float: right;
        }
        &--cart {
          clear: both;
          left: 18px;
          float: right;
        }
      }
    }
  }
`;
const ProductLeftNavWrapper = styled.div`
  width: 200px;
  float: left;
`;
const ProductMainWrapper = styled.div`
  float: left;
  width: 76vw;

  @media (min-width: 900px) and (max-width: 1100px) {
    width: 67vw;
  }
  @media (min-width: 1100px) {
    width: 70vw;
  }
  @media (min-width: 1200px) {
    width: 79vw;
  }
  /* No less than 400px, no greater than 900px */
  @media (min-width: 400px) and (max-width: 900px) {
    width: 87vw;
    background-color: blue;
  }
`;

export { ProductLandingWrapper, ProductLeftNavWrapper, ProductMainWrapper };
