import { Navigation } from "components/elements/index";
import React, { useState } from "react";
import { PageProps } from "types/interface.types";
import { Icon, MenuLabel, NavBackground } from "./hamburger-menu.styles";

const HamburgerMenu: React.FC<PageProps> = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  return (
    <>
      <MenuLabel htmlFor="navi-toggle" onClick={handleClick}>
        <Icon clicked={click}>&nbsp;</Icon>
      </MenuLabel>
      <NavBackground clicked={click}>&nbsp;</NavBackground>
      <Navigation clicked={click} handleClick={() => handleClick()} />
    </>
  );
};
export default HamburgerMenu;
