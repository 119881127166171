import { Component, ErrorInfo, ReactNode } from "react";
import { toast } from "react-toastify";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
}
/**
 * Technique used to catch errors on our page
 * A class component becomes an error boundary if it defines either (or both) of the lifecycle
 * methods static getDerivedStateFromError() or componentDidCatch(). Use static getDerivedStateFromError() to render a fallback UI after an error has been thrown.
 * Use componentDidCatch() to log error information.
 * Error boundaries work like a JavaScript catch {} block, but for components. Only class components can be error boundaries.
 * In practice, most of the time you’ll want to declare an error boundary component once and use it throughout your application.
 */
class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: null
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    toast.error(`An error has occured: ${_.message}`);
    return { hasError: true, error: _ };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // send this to API to store into db
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      this.setState({ hasError: false, error: null });
      return <></>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
