import { Table } from "@mantine/core";
import { LinearProgress } from "@mui/material";
import productService from "api/product.service";
import { convertToCSV } from "common/utils";
import fileDownload from "js-file-download";
import React, { useEffect, useState } from "react";
import { Download, Eye } from "tabler-icons-react";
import { AdminPageProps } from "types/interface.types";
import {
  ReportFinancialYrSalesMargins,
  ReportProfitableProduct,
  ReportProfitableProducts,
  ReportTopCustomers,
  ReportTopSellingProducts
} from "types/model.types";
import { AccountPageWrapper } from "./account.styles";
import LineChart from "./Charts/LineChart";

const chartOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const
    },
    title: {
      display: true,
      text: "Top 30 Selling Products (Last 12 Months)"
    }
  }
};

const DashboardPage: React.FC<AdminPageProps> = () => {
  const [topSellingProducts, setTopSellingProducts] =
    useState<ReportTopSellingProducts>([]);
  const [topCustomers, setTopCustomers] = useState<ReportTopCustomers>([]);
  const [profitable, setProfitable] = useState<ReportProfitableProducts>([]);
  const [financialYrSalesMargin, setFinancialYrSalesMargin] =
    useState<ReportFinancialYrSalesMargins>([]);

  const [busy, setBusy] = useState<boolean>(true);
  const [report, setReport] = useState<number>(1);

  const chartData = {
    labels: topSellingProducts.map((p) => p.productCode),
    datasets: [
      {
        label: "Ordered",
        data: topSellingProducts.map((p) => p.totalQuantity),
        fill: false,
        borderColor: "#E1341E",
        tension: 0.1
      },
      {
        label: "Supplied",
        data: topSellingProducts.map((p) => p.totalSupplied),
        fill: true,
        borderColor: "#1ECBE1",

        tension: 0.1
      }
    ]
  };

  useEffect(() => {
    productService.getTopSellingProducts(30).then((json) => {
      if (json.data) {
        setTopSellingProducts(json.data);
        setBusy(false);
      }
    });
    productService.getTopCustomersBySales(30).then((json) => {
      if (json.data) {
        setTopCustomers(json.data);
      }
    });
    productService.getProfitableProduts(30).then((json) => {
      if (json.data) {
        setProfitable(json.data);
      }
    });
    productService.getFinancialYrSalesMargin(30).then((json) => {
      if (json.data) {
        setFinancialYrSalesMargin(json.data);
      }
    });
  }, []);

  const chartTypes = [
    {
      id: 1,
      title: "Top 30 Customers by sales",
      comments: "Top 30 customers based on sales made in last 12 months."
    },
    {
      id: 0,
      title: "Top 30 Selling Products",
      comments: "Top 30 products sold in last 12 months. [Default]"
    },
    {
      id: 2,
      title: "Profitable Products",
      comments: "Top 30 most profitable products over last 12 months."
    },
    { id: 3, title: "Financial Year Sales Margin", comments: "" }
  ];

  const handleDownload = (id: number) => {
    switch (id) {
      case 0: // top selling
        setBusy(true);
        productService
          .getTopSellingProducts(Number.MAX_SAFE_INTEGER)
          .then((json) => {
            if (json.data) {
              fileDownload(convertToCSV(json.data), "TopSellingProducts.csv");
            }
            setBusy(false);
          });
        break;
      case 1: // top customers
        setBusy(true);
        productService
          .getTopCustomersBySales(Number.MAX_SAFE_INTEGER)
          .then((json) => {
            if (json.data) {
              fileDownload(convertToCSV(json.data), "TopCustomersBySales.csv");
            }
            setBusy(false);
          });
        break;
      case 2: // profitable
        setBusy(true);
        productService
          .getProfitableProduts(Number.MAX_SAFE_INTEGER)
          .then((json) => {
            if (json.data) {
              fileDownload(convertToCSV(json.data), "ProfitableProducts.csv");
            }
            setBusy(false);
          });
        break;
      case 3: // financial yr
        setBusy(true);
        productService
          .getFinancialYrSalesMargin(Number.MAX_SAFE_INTEGER)
          .then((json) => {
            if (json.data) {
              fileDownload(
                convertToCSV(json.data),
                "FinancialYrSalesMargin.csv"
              );
            }
            setBusy(false);
          });
        break;
    }
  };

  const previewReport = (): JSX.Element => {
    switch (report) {
      case 1: //top customers
        return (
          <LineChart
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: "top" as const
                },
                title: {
                  display: true,
                  text: "Top 30 customers by sales (Last 12 Months)"
                }
              }
            }}
            data={{
              labels: topCustomers.map((p) => p.debtorCode),
              datasets: [
                {
                  label: "Total Sales",
                  data: topCustomers.map((p) => p.totalSales),
                  fill: false,
                  borderColor: "#E1341E",
                  tension: 0.1
                }
              ]
            }}
          />
        );
        break;
      case 2: // profitable
        return (
          <LineChart
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: "top" as const
                },
                title: {
                  display: true,
                  text: "Profitable Products Report"
                }
              }
            }}
            data={{
              labels: profitable.map(
                (p: ReportProfitableProduct) => p.productCode
              ),
              datasets: [
                {
                  label: "Cost Price",
                  data: profitable.map((p) => p.cp),
                  fill: false,
                  borderColor: "#E1341E",
                  tension: 0.1
                },
                {
                  label: "SP1 Margin",
                  data: profitable.map((p) => p.s1margin),
                  fill: false,
                  borderColor: "#1ecbe1",
                  tension: 0.1
                },
                {
                  label: "SP2 Margin",
                  data: profitable.map((p) => p.s2margin),
                  fill: false,
                  borderColor: "#3237cd",
                  tension: 0.1
                },
                {
                  label: "SP3 Margin",
                  data: profitable.map((p) => p.s3margin),
                  fill: false,
                  borderColor: "#ba45b3",
                  tension: 0.1
                },
                {
                  label: "SP4 Margin",
                  data: profitable.map((p) => p.s4margin),
                  fill: false,
                  borderColor: "#000",
                  tension: 0.1
                }
              ]
            }}
          />
        );
        break;
      case 3: // financial yr
        return (
          <>
            <LineChart
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    position: "top" as const
                  },
                  title: {
                    display: true,
                    text: "Financial Year Sales Margin Report"
                  }
                }
              }}
              data={{
                labels: financialYrSalesMargin.map((p) => p.productCode),
                datasets: [
                  {
                    label: "Margin MTD",
                    data: financialYrSalesMargin.map((p) => p.marginMtd),
                    fill: false,
                    borderColor: "#1910ef",
                    tension: 0.1
                  },
                  {
                    label: "Margins YTD",
                    data: financialYrSalesMargin.map((p) => p.marginYtd),
                    fill: false,
                    borderColor: "#d12e4a",
                    tension: 0.1
                  }
                ]
              }}
            />
          </>
        );
        break;
      default: // top selling
        return <LineChart options={chartOptions} data={chartData} />;
    }
  };

  const rows = chartTypes.map((element, k) => (
    <tr key={`ct-${k}${element.id}`} style={{ cursor: "pointer" }}>
      <td>{element.title}</td>
      <td>{element.comments}</td>
      <td>
        <Eye
          size={18}
          color="#F7931D"
          onClick={() => {
            setReport(element.id);
          }}
        />{" "}
        <Download
          onClick={() => {
            handleDownload(element.id);
          }}
          size={18}
          color="#B5262E"
        />
      </td>
    </tr>
  ));
  return (
    <>
      <AccountPageWrapper>
        <h2>Report Dashboard</h2>
        <Table>
          <thead>
            <tr>
              <th>Report Type</th>
              <th>Comments</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
        {busy && <LinearProgress />}
        <div>{previewReport()}</div>
      </AccountPageWrapper>
    </>
  );
};

export default DashboardPage;
