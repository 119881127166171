import {
  ActionIcon,
  Card,
  Group,
  SimpleGrid,
  Text,
  useMantineTheme
} from "@mantine/core";
import productService from "api/product.service";
import { asPriceHtml, showPrice } from "common/utils";
import AddCartButton from "components/cart/Cart.Button";
import ImageLoader from "components/elements/image-loader/ImageLoader";
import React, { useEffect, useState } from "react";
import "reactjs-popup/dist/index.css";
import { ListDetails } from "tabler-icons-react";
import { PageProps } from "types/interface.types";
import { Product, Products } from "types/model.types";
import { FeaturedProductWrapper } from "./landing.styles";

const TopSellingProducts: React.FC<PageProps> = ({ onChangeRoute }) => {
  const theme = useMantineTheme();
  const [featuredProducts, setFeaturedProducts] = useState<Products>([]);

  useEffect(() => {
    let mounted = true;
    // (async () => {
    //   productService.getTopSeller().then((json) => {
    //     if (mounted && json && json.data) {
    //       setFeaturedProducts(json.data);
    //     }
    //   });
    // })();
    productService.getTopSeller().then((json) => {
      if (mounted && json && json.data) {
        setFeaturedProducts(json.data);
      }
    });
    return () => {
      mounted = false;
    };
  }, []);

  return (
    <FeaturedProductWrapper>
      <div className="section">
        <span>Check out our&nbsp;</span>
        <span className="section-title">top sellers</span>
      </div>
      <SimpleGrid
        cols={6}
        spacing="lg"
        breakpoints={[
          { maxWidth: 1420, cols: 4, spacing: "md" },
          { maxWidth: 980, cols: 3, spacing: "md" },
          { maxWidth: 755, cols: 2, spacing: "sm" }
        ]}
      >
        {featuredProducts.map((p: Product, k: number) => {
          return (
            <Card key={`ts_${k}`} shadow="sm" className="card" p="lg">
              <Card.Section>
                <ImageLoader
                  id={p.productCode}
                  folder="f"
                  height={120}
                  name={p.featuredImageName}
                />
              </Card.Section>

              <Text weight={500} className="card-title">
                <div
                  className={`product product--price ${
                    showPrice() && "product--price-money"
                  }`}
                  dangerouslySetInnerHTML={{
                    __html: showPrice() ? asPriceHtml(p.sellPrice2) : ""
                  }}
                />
                {p.title}
              </Text>

              <Group
                position="apart"
                style={{ marginBottom: 2, marginTop: theme.spacing.sm }}
              >
                <ActionIcon
                  onClick={() => {
                    onChangeRoute &&
                      onChangeRoute(`products/details/${p.productCode}`);
                  }}
                >
                  <ListDetails size={48} strokeWidth={2} color={"#B5262E"} />
                </ActionIcon>
                <AddCartButton
                  id={p.id}
                  title={p.title}
                  quantity={1}
                  productCode={p.productCode}
                  price={p.sellPrice2}
                />
              </Group>
            </Card>
          );
        })}
      </SimpleGrid>
    </FeaturedProductWrapper>
  );
};

export default TopSellingProducts;
