/* eslint-disable @typescript-eslint/no-explicit-any */
import ImportExportIcon from "@mui/icons-material/ImportExport";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { LinearProgress } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridToolbarFilterButton
} from "@mui/x-data-grid";
import userService from "api/user.service";
import BusyIndicator from "components/busy/BusyIndicator";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AdminPageProps } from "types/interface.types";
import { User, Users } from "types/model.types";
import { AccountPageWrapper } from "./account.styles";

const UserPage: React.FC<AdminPageProps> = () => {
  const [busy, setBusy] = useState(true);
  const [users, setUsers] = useState<Users>([]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 0,
      hide: true,
      sortable: false,
      filterable: false,
      hideable: false
    },
    {
      field: "roleId",
      headerName: "Role ID",
      width: 0,
      hide: true,
      sortable: false,
      filterable: false,
      hideable: false
    },
    { field: "userCode", headerName: "Debtor Id", width: 80 },
    { field: "userName", headerName: "Login Name", width: 270 },
    { field: "roleName", headerName: "Role", width: 200 },
    {
      field: "lastLoginAttempt",
      headerName: "Last Login Attempt",
      width: 150,
      valueGetter: ({ value }) => {
        if (value && value !== "") {
          const d = new Date(value.split("T")[0]);

          if (d.getFullYear() === 1900) {
            return "";
          } else {
            return `${d.toLocaleDateString("en-NZ")}`;
          }
        }
        return "";
      }
    },
    { field: "failedLoginAttempt", headerName: "# Failed logins", width: 120 },
    {
      field: "import",
      headerName: "Import",
      width: 80,
      renderCell: (cellValues: any) => {
        //https://smartdevpreneur.com/add-buttons-links-and-other-custom-cells-in-material-ui-datagrid/#How_to_Add_a_Button
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const handleClick = (event: any, cellValues: any) => {
          event.stopPropagation();

          const u: User = cellValues.row;
          userService.import(u.userCode).then((json) => {
            if (json) {
              const updatedUsers: Users = users.map((_: User) =>
                _.userCode === u.userCode
                  ? {
                      ..._,
                      id: json.data.id,
                      import: false,
                      locked: true
                    }
                  : _
              );
              setUsers(updatedUsers);
              toast.success(`Account Imported from JJL Stock System`);
            }
          });
        };
        return (
          <>
            {/* <span>{JSON.stringify(cellValues.row.Featured)}</span> */}
            {cellValues.row.import ? (
              <ImportExportIcon
                fontSize="medium"
                className="__action"
                onClick={(event) => {
                  handleClick(event, cellValues);
                }}
              />
            ) : (
              <></>
            )}
          </>
        );
      }
    },
    {
      field: "locked",
      headerName: "Locked",
      width: 80,
      renderCell: (cellValues: any) => {
        //https://smartdevpreneur.com/add-buttons-links-and-other-custom-cells-in-material-ui-datagrid/#How_to_Add_a_Button
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const handleClick = (event: any, cellValues: any) => {
          event.stopPropagation();
          const u: User = cellValues.row;
          const newState = !u.locked;

          if (u.id) {
            userService.changeLockState(u.id.toString(), newState).then(() => {
              const updatedUsers: Users = users.map((_: User) =>
                _.id === u.id
                  ? {
                      ..._,
                      locked: newState
                    }
                  : _
              );
              setUsers(updatedUsers);
              toast.success(`Account ${newState ? "unlocked" : "lockded"}`);
            });
          }
        };
        return (
          <>
            {!cellValues.row.import &&
            cellValues.row.roleName !==
              process.env.REACT_APP_ROLE_SUPER_ADMIN ? (
              cellValues.row.locked ? (
                <LockIcon
                  className="__action __action--off"
                  fontSize="medium"
                  onClick={(event) => {
                    handleClick(event, cellValues);
                  }}
                ></LockIcon>
              ) : (
                <LockOpenIcon
                  className="__action __action--on"
                  fontSize="medium"
                  onClick={(event) => {
                    handleClick(event, cellValues);
                  }}
                />
              )
            ) : (
              <></>
            )}
          </>
        );
      }
    }
  ];

  useEffect(() => {
    let mounted = true;
    userService
      .get()
      .then((json) => {
        if (json) {
          if (mounted) setUsers(json.data);
        }
      })
      .catch((err) => {
        if (err.message) {
          toast.error(err.message);
        }
      })
      .finally(() => {
        if (mounted) setBusy(false);
      });

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <AccountPageWrapper>
      <h1>Users</h1>
      <BusyIndicator open={busy}></BusyIndicator>
      <DataGrid
        getRowId={(e: any) => e.id}
        rows={users}
        columns={columns}
        loading={busy}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        components={{
          Toolbar: GridToolbarFilterButton,
          LoadingOverlay: LinearProgress
        }}
      />
    </AccountPageWrapper>
  );
};

export default UserPage;
