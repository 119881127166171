import styled from "styled-components";
import { LineOnHover } from "mixins";
import { GlobalColors } from "globalStyles";
interface NavigationWrapperProps {
  position: string;
}
//styles
const NavItems = styled.ul`
  display: flex;
  justify-content: space-between;

  &.top-nav {
    li {
      &.header {
        &--type {
          &-standard {
            & a {
              top: 0;
            }
          }
          &-dropdown {
            & a {
              top: -9px;
            }
          }
        }
        // on header, our background is white...so we swap colors
        a {
          color: ${GlobalColors.colorBlack};
        }
      }
      // default background is black, so we use white
      a {
        cursor: pointer;
        color: ${GlobalColors.colorBlack};
        //font-weight:bold;
        font-weight: 700;
        //font-size:1.2rem;
        position: relative;
        // display: inline-block;
        text-decoration: none;
        //padding: 1rem 2rem;
        // background-image: linear-gradient(
        //   120deg,
        //   transparent 0%,
        //   transparent 50%,
        //   ${GlobalColors.colorBlack} 50%
        // );
        // background-size: 240%;
        // transition: all 0.4s;
        // &:hover,
        // &:active {
        //   background-position: 100%;
        //   color: #fff;
        //   transform: translateX(1rem);
        // }

        ${LineOnHover({
          height: "4px",
          color: `${GlobalColors.colorNavSelector}`,
          width: "100%",
          top: "24px",
          time: "0.5s"
        })}

        // &:hover {
        //   color:${GlobalColors.colorNavSelector};
        // }
        // &.first {
        //   &::before {
        //     content:none!important;
        //   }
        // }
        // &::before {
        //   content: " | ";
        //   padding: 0 12px;
        // }
        &:link {
          text-decoration: none;

          & svg {
            position: relative;
            top: 7px;
          }
        }
        &.link-active {
          color: ${GlobalColors.colorNavSelector};

          & svg {
            position: relative;
            top: 7px;
          }
        }
      }
    }
  }
`;

const NavigationWrapper = styled.nav<NavigationWrapperProps>`
  position: relative;
  top: 45px;
  right: 15px;
  min-width: 530px;

  & .popupMenu {
    display: relative;
  }
`;
export { NavItems, NavigationWrapper };
