import axios from "axios";
import { REST_URL } from "common/constants";
import { IsAdmin, IsCustomer } from "common/role-service";
import { resetCart } from "redux/store/cart/thunks";
import { ChangePassword, JwtAuthData } from "types/interface.types";
import { authHeader, getToken } from "./common.service";

class AuthService {
  async login(email: string, pwd: string) {
    const response = await axios.post(REST_URL + "signin", {
      email,
      pwd
    });
    if (response.data.token) {
      localStorage.setItem("user", JSON.stringify(response.data));
      //const userStr = localStorage.getItem("user");
      window.dispatchEvent(new Event("storage"));
    }
    return response.data;
  }

  async changePassword(payload: ChangePassword) {
    const response = await axios.post(
      REST_URL + "admin/changePassword",
      { oldPwd: payload.oldPassword, newPwd: payload.newPassword },
      authHeader()
    );

    return response ?? {};
  }

  logout() {
    localStorage.removeItem("user");
    window.dispatchEvent(new Event("storage"));
    resetCart();
  }

  register(username: string, email: string, password: string) {
    return axios.post(REST_URL + "signup", {
      username,
      email,
      password
    });
  }

  getCurrentUser(): JwtAuthData | null {
    const userStr = localStorage.getItem("user");
    let user: JwtAuthData | null = null;
    if (userStr) {
      user = JSON.parse(userStr);
    }
    return user;
  }

  /** validate token expiration */
  async vt() {
    const response = await axios.post(
      REST_URL + "admin/vt",
      {},
      {
        headers: {
          authorization: getToken()
        }
      }
    );

    return response;
  }

  isAdminUser(): boolean {
    const userData: JwtAuthData | null = this.getCurrentUser();
    return IsAdmin(userData?.user?.role ?? "");
  }

  isCustomer(): boolean {
    const userData: JwtAuthData | null = this.getCurrentUser();
    return IsCustomer(userData?.user?.role ?? "");
  }
}

export default new AuthService();
