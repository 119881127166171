import React from "react";
import { ErrorPageWrapper } from "./error.styles";

const AccessDenied: React.FC = () => {
  return (
    <ErrorPageWrapper>
      <h2>Access Denied</h2>
      <p>Oops, the page you are looking for can&apos;t be found!</p>
      <p>
        Return to <a href="/">Home</a>
      </p>
    </ErrorPageWrapper>
  );
};

export default AccessDenied;
