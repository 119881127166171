import axios from "axios";
import { REST_URL } from "common/constants";
import { getToken } from "./common.service";

class CartService {
  async initialize() {
    const response = await axios.post(REST_URL + "cart/initialize", undefined, {
      headers: {
        authorization: getToken()
      }
    });
    return response ?? {};
  }
  async get() {
    const response = await axios.post(REST_URL + "cart/get", undefined, {
      headers: {
        authorization: getToken()
      }
    });
    return response ?? [];
  }
  /**
   * Update cart item
   * @param c
   * @returns
   */
  async updateCart(
    cartId: string | number,
    productId: string,
    quantity: number
  ) {
    const response = await axios.post(
      REST_URL + "cart/update",
      {
        cartId,
        productId,
        quantity
      },
      {
        headers: {
          authorization: getToken()
        }
      }
    );

    return response;
  }
  async remove(cartId: string | number, productId: string) {
    const response = await axios.post(
      REST_URL + "cart/remove",
      {
        cartId,
        productId
      },
      {
        headers: {
          authorization: getToken()
        }
      }
    );

    return response;
  }

  async completeOrder(cartId: string | number) {
    const response = await axios.post(
      REST_URL + "cart/complete",
      {
        cartId
      },
      {
        headers: {
          authorization: getToken()
        }
      }
    );
    return response;
  }

  async clearCart() {
    const response = await axios.post(REST_URL + "cart/reset", undefined, {
      headers: {
        authorization: getToken()
      }
    });

    return response;
  }
}

export default new CartService();
