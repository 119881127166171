/* eslint-disable @typescript-eslint/no-explicit-any */
import { Modal } from "@mantine/core";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { LinearProgress } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridToolbarFilterButton
} from "@mui/x-data-grid";
import orderService from "api/order.service";
import { formatDate } from "common/utils";
import BusyIndicator from "components/busy/BusyIndicator";
import React, { useEffect, useState } from "react";
import { AdminPageProps } from "types/interface.types";
import { Order, Orders } from "types/model.types";
import { AccountPageWrapper } from "./account.styles";
import OrderPeviewPage from "./Page.OrderPreview";

const OrderPage: React.FC<AdminPageProps> = () => {
  const [busy, setBusy] = useState(false);
  const [orders, setOrders] = useState<Orders>([]);
  const [selectedOrder, setSelectedOrder] = useState<Order | undefined>();
  const [opened, setOpened] = useState(false);

  const columns: GridColDef[] = [
    {
      field: "orderId",
      headerName: "Order Number",
      width: 120
    },
    {
      field: "salesPerson",
      headerName: "Sales Person",
      width: 0,
      hide: true,
      sortable: false,
      filterable: false,
      hideable: false
    },
    {
      field: "createdOn",
      headerName: "Order Date",
      width: 150,
      valueGetter: ({ value }) => {
        if (value && value !== "") {
          return formatDate(value);
        }
        return "";
      }
    },
    {
      field: "invoiceToDebtorCode",
      headerName: "Invoice To Id",
      hide: true,
      sortable: false,
      filterable: false,
      hideable: false
    },
    {
      field: "invoiceToDebtorName",
      headerName: "Invoice To",
      width: 220
    },
    {
      field: "deliverToDebtorCode",
      headerName: "Deliver To Id",
      hide: true,
      sortable: false,
      filterable: false,
      hideable: false
    },
    {
      field: "deliverToDebtorName",
      headerName: "Delivered To",
      width: 220
    },
    {
      field: "orderStage",
      headerName: "Status",
      width: 220,
      cellClassName: "status"
    },

    {
      field: "ViewOrder",
      headerName: "Order Details",
      width: 150,
      hideable: false,
      filterable: false,
      sortable: false,
      renderCell: (cellValues: GridRenderCellParams) => {
        return (
          <RemoveRedEyeIcon
            fontSize="medium"
            className="__action"
            onClick={() => {
              setSelectedOrder(cellValues.row);
              setOpened(true);
            }}
          />
        );
      }
    }
  ];

  useEffect(() => {
    let mounted = true;

    orderService
      .get()
      .then((json) => {
        if (json && mounted) {
          const activeOrder: Orders = json.data.filter(
            (x: Order) => x.orderStage !== "COMPLETE"
          );
          setOrders(activeOrder);
          setBusy(false);
        }
      })
      .catch(() => {
        if (mounted) setBusy(false);
      });

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title=""
        size="100%"
      >
        <OrderPeviewPage order={selectedOrder} />
      </Modal>
      <AccountPageWrapper>
        <BusyIndicator open={busy}></BusyIndicator>
        <div className="section">
          <span>My active </span>
          <span className="section-title">orders</span>
        </div>
        <DataGrid
          getRowId={(e: any) => e.orderId}
          rows={orders}
          columns={columns}
          loading={busy}
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          components={{
            Toolbar: GridToolbarFilterButton,
            LoadingOverlay: LinearProgress
          }}
        />
      </AccountPageWrapper>
    </>
  );
};
export default OrderPage;
