import {
  Box,
  Button,
  Divider,
  Group,
  Image,
  Paper,
  SimpleGrid,
  Text,
  Textarea,
  TextInput
} from "@mantine/core";
import { useForm } from "@mantine/form";
import emailService from "api/email.service";
import { useStyles } from "components/mantine.styles";
import React from "react";
import { toast } from "react-toastify";
import { Search } from "tabler-icons-react";
import { EmailNotification, PageProps } from "types/interface.types";
import { ContactLandingWrapper } from "./landing.styles";

const Landing: React.FC<PageProps> = () => {
  const { classes } = useStyles();

  const form = useForm({
    initialValues: {
      email: "",
      name: "",
      subject: "",
      message: ""
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
      name: (value) =>
        !value || value.length > 80
          ? "Name is required and must not exceed 80 characters"
          : null,
      subject: (value) =>
        !value || value.length > 80
          ? "Subject is required and must not exceed 80 characters"
          : null,
      message: (value) =>
        !value || value.length > 250
          ? "Message is required and must not exceed 250 characters"
          : null
    }
  });

  return (
    <ContactLandingWrapper>
      <Paper shadow="md" radius="lg">
        <div className={classes.wrapper}>
          <div className={classes.contacts}>
            <Text
              size="lg"
              weight={700}
              className={classes.title}
              sx={{ color: "#fff" }}
            >
              Contact information
            </Text>

            <div className="contact-grp">
              <div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-at"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <circle cx="12" cy="12" r="4"></circle>
                    <path d="M16 12v1.5a2.5 2.5 0 0 0 5 0v-1.5a9 9 0 1 0 -5.5 8.28"></path>
                  </svg>
                </div>
                <div>
                  <div className="contact-grp-label">Email</div>
                  <div className="contact-grp-text">info@jjl.co.nz</div>
                </div>
              </div>
              <div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-phone"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2"></path>
                  </svg>
                </div>
                <div>
                  <div className="contact-grp-label">Phone</div>
                  <div className="contact-grp-text">+64 4 569 5248</div>
                  <div className="contact-grp-text">+64 21 326 626</div>
                </div>
              </div>
              <div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-map-pin"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <circle cx="12" cy="11" r="3"></circle>
                    <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z"></path>
                  </svg>
                </div>
                <div>
                  <div className="contact-grp-label">Address</div>
                  <div className="contact-grp-text">107 Hutt Park Road</div>
                  <div className="contact-grp-text">Gracefield</div>
                  <div className="contact-grp-text">Lower Hutt 5010</div>
                  <div className="contact-grp-text">New Zealand</div>
                </div>
              </div>
              <div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-sun"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <circle cx="12" cy="12" r="4"></circle>
                    <path d="M3 12h1m8 -9v1m8 8h1m-9 8v1m-6.4 -15.4l.7 .7m12.1 -.7l-.7 .7m0 11.4l.7 .7m-12.1 -.7l-.7 .7"></path>
                  </svg>
                </div>
                <div>
                  <div className="contact-grp-label">Trading hours</div>
                  <div className="contact-grp-text">8 a.m. – 5 p.m.</div>
                </div>
              </div>
            </div>

            {/* <ContactIconsList variant="white" /> */}
          </div>

          <form
            className={classes.form}
            onSubmit={form.onSubmit((values: EmailNotification) => {
              emailService
                .send(values)
                .then((json) => {
                  if (json) {
                    if (json.data?.err) {
                      toast.error(
                        "Failed to send message.  Please try again later"
                      );
                      return;
                    }
                    if (json?.status === 200) {
                      toast.success("Message sent");
                      form.reset();
                    }
                  }
                })
                .catch((e) => {
                  if (e && e.message) {
                    toast.error(e.message);
                  }
                });
            })}
          >
            <Text size="lg" weight={700} className={classes.title}>
              Get in touch
            </Text>

            <div className={classes.fields}>
              <SimpleGrid cols={2} breakpoints={[{ maxWidth: "sm", cols: 1 }]}>
                <TextInput
                  label="Your name"
                  placeholder="Your name"
                  required
                  {...form.getInputProps("name")}
                />
                <TextInput
                  label="Your email"
                  placeholder="Your email"
                  required
                  {...form.getInputProps("email")}
                />
              </SimpleGrid>

              <TextInput
                mt="md"
                label="Subject"
                placeholder="Subject"
                {...form.getInputProps("subject")}
                required
              />

              <Textarea
                mt="md"
                label="Your message"
                placeholder="Please include all relevant information"
                minRows={3}
                {...form.getInputProps("message")}
                required
              />

              <Group position="right" mt="md">
                <Button type="submit" className={classes.control}>
                  Send message
                </Button>
              </Group>
              <Divider
                my="xs"
                variant="dashed"
                labelPosition="center"
                label={
                  <>
                    <Search size={12} />
                    <Box ml={5}>Find us on Google Maps</Box>
                  </>
                }
              />
              <a
                target={"_blank"}
                href="https://www.google.com/maps/place/107+Hutt+Park+Road,+Gracefield,+Lower+Hutt+5010/@-41.2374266,174.9109133,17z/data=!3m1!4b1!4m5!3m4!1s0x6d38aa3ae63b6889:0xbd73e2f5b7504e7c!8m2!3d-41.2374266!4d174.9109133"
                rel="noreferrer"
              >
                <Image
                  src="images/jjl-location.png"
                  alt={"JJL Address"}
                  height={220}
                />
              </a>
            </div>
          </form>
        </div>
      </Paper>
    </ContactLandingWrapper>
  );
};

export default Landing;
