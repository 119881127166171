import styled from "styled-components";
const PageBoundaryWrapper = styled.div`
  .editor {
    margin-bottom: 20px;
    border-top: 1px dotted #f15a37;
    border-bottom: 1px solid #f15a37;
    color: #b5272e;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 0px;
    background-color: #f3f3f3;

    & > span {
      font-weight: 600;
      position: relative;
      top: 10px;
      left: 20px;
    }
    .btn {
      float:right;
      font-size: 1em;
      width:120px!important;
      color: #b5272e;
      &-default {
        background-color: transparent !important;
      }
    }
  }
`;

export { PageBoundaryWrapper };

