import axios from "axios";
import { REST_URL } from "common/constants";
import { Category } from "types/model.types";
import { getToken } from "./common.service";

class CategoryService {
  async get() {
    const response = await axios.get(REST_URL + "category/get");
    return response ?? [];
  }

  async addEdit(c: Category) {
    const response = await axios.post(
      REST_URL +
        `category/${c.id !== undefined && c.id !== "" ? "update" : "add"}`,
      {
        nm: c.name,
        id: c.id
      },
      {
        headers: {
          authorization: getToken()
        }
      }
    );

    return response;
  }

  async delete(id: string) {
    const response = await axios.post(
      REST_URL + "category/delete",
      {
        id
      },
      {
        headers: {
          authorization: getToken()
        }
      }
    );

    return response;
  }
}

export default new CategoryService();
