/* eslint-disable @typescript-eslint/no-explicit-any */
import { GlobalColors } from "globalStyles";
import styled from "styled-components";

type ProductType = {
  img: any;
};
const ProductDetailsWrapper = styled.div`
  //height: 100vh;
  background-color: white;
  img {
    transform: none !important;
  }
  .pd {
    &-price,
    &-units {
      margin: 30px 0;
    }
    &-price {
      font-size: 1.35rem;
      font-weight: 600;
      &::before {
        content: "$";
      }
    }
    &-units {
      font-size: 0.9rem;
    }
    &-cart {
      display: inline-block;
    }
    &-info {
      ul li {
        list-style-type: circle;
        margin-left: 30px !important;
      }
    }
    table {
      tr td {
        border-bottom: 1px solid #ccc;
      }
    }
  }
  .card {
    margin-bottom: 10px;
    &-section {
      display: flex;
      justify-content: center;
    }
    &-button {
      float: right;
    }
  }

  h1,
  h2 {
    font-size: 1.875rem;
  }
  h2,
  h3,
  h4,
  h5 {
    font-size: 1.5rem;
  }
  h1,
  h2,
  h3,
  h4 {
    margin: 18px 0;
    color: #000;
    line-height: 42px;
    text-transform: none;
  }
`;

const ProductsWrapper = styled.div`
  clear: both;
  margin-top: 20vh;
  margin-bottom: 20vh;
  @media (max-width: 998px) {
    text-align: center;
  }
`;
const Title = styled.div`
  margin-bottom: 10vh;
  @media (min-width: 998px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
const ProductsButton = styled.button`
  text-align: center;
  height: 3%;
  width: 15%;
  text-transform: uppercase;
  background-color: ${GlobalColors.colorTransparent};
  padding: 0.5rem;
  &:hover {
    background-color: ${GlobalColors.colorBlack};
    color: ${GlobalColors.colorWhite};
  }
  @media (max-width: 998px) {
    width: 40%;
    height: 10vh;
    margin-top: 3%;
  }
`;
const ProductsContainer = styled.div`
  @media (min-width: 998px) {
    z-index: 100;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 2% 2%;
    grid-template-areas:
      ". . . ."
      ". . . .";
  }
`;
const Product = styled.div<ProductType>`
  cursor: pointer;
  position: relative;
  color: ${GlobalColors.colorWhite};
  min-height: 150px;
  padding: 18% 20% 3vh 10%;
  margin-bottom: 3.6%;
  width: 100%;
  height: 30vh;
  z-index: -1;
  background-image: url(${({ img }) => img});
  background-size: 100%;
  background-repeat: no-repeat;
  text-align: left;
  transition: all 300ms ease-in-out;
  @media (min-width: 998px) {
    display: flex;
    align-items: flex-end;
    height: 50vh;
    padding-bottom: 30%;
    z-index: 100;
  }
  h3 {
    width: 50%;
    z-index: 1001;
    @media (max-width: 500px) {
      font-size: 1.785rem;
    }
  }
  &:after {
    content: "";
    position: absolute;
    background-color: ${GlobalColors.colorWhiteSmoke};
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 300ms ease-in-out;
    opacity: 0;
  }
  &:hover {
    color: ${GlobalColors.colorBlack};
    &:after {
      opacity: 0.5;
    }
  }
`;
export {
  ProductsWrapper,
  ProductsButton,
  Title,
  ProductsContainer,
  Product,
  ProductDetailsWrapper
};
