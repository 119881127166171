import styled from "styled-components";

const LogoWrapper = styled.img`
  cursor: pointer;
  width: 248px;
  height: 76px;
  position: relative;
  margin-top: 8px;
  margin-left: -5px;

  &.mobile {
    width: 56px;
    height: 56px;
    margin-top: 25px;
    margin-left: 10px;
  }
`;

export default LogoWrapper;
