import { CircularProgress, Dialog, DialogContent, Fade } from "@mui/material";
import Stack from "@mui/material/Stack";
import React from "react";
import { BusyWrapper } from "./busyIndicator.styles";

type BusyProps = {
  open: boolean;
};

const BusyIndicator: React.FC<BusyProps> = ({ open }) => {
  return (
    <Dialog open={open} maxWidth="md">
      <DialogContent>
        <BusyWrapper>
          <Fade
            in={open}
            style={{
              transitionDelay: "100ms"
            }}
            unmountOnExit
          >
            <Stack sx={{ color: "grey.500" }} spacing={2} direction="row">
              <h3>Please wait...</h3>
              <CircularProgress color="success" />
            </Stack>
          </Fade>
        </BusyWrapper>
      </DialogContent>
    </Dialog>
  );
};

export default BusyIndicator;
