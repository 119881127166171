import axios from "axios";
import { REST_URL } from "common/constants";
import { authHeader } from "./common.service";

class PageService {
  async get(page: string) {
    const response = await axios.post(REST_URL + "getPage", { page });
    return response ?? {};
  }

  async update(page: string, markup: string) {
    const response = await axios.post(
      REST_URL + "updatePage",
      {
        page,
        markup
      },
      authHeader()
    );

    return response ?? {};
  }
}

export default new PageService();
