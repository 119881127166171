import styled from "styled-components";

const ErrorPageWrapper = styled.div`
  min-height: 100px;
  margin: auto;
  text-align: center;

  p,
  h1,
  h2 {
    text-align: center;
  }
  h1 {
    font-size: 8.6rem;
  }
  h2 {
    font-size: 2.4rem;
  }
`;
export { ErrorPageWrapper };

