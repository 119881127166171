// Product details page

import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import BrandingWatermarkIcon from "@mui/icons-material/BrandingWatermark";
import CategoryIcon from "@mui/icons-material/Category";
import CompareIcon from "@mui/icons-material/Compare";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EnhancedEncryptionIcon from "@mui/icons-material/EnhancedEncryption";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuItem from "@mui/material/MenuItem";
import { IsSuperAdmin } from "common/role-service";
import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { AccountMenuWrapper } from "./landing.styles";
interface IAdminMenuProps {
  role?: string;
}

const AdminMenu: React.FC<IAdminMenuProps> = ({ role }) => {
  const didMountRef = useRef(false);
  const history = useHistory();

  const handleMenuClick = (nm: string): void => {
    history.push(`/my-account/${nm}`);
  };
  useEffect(() => {
    if (didMountRef.current) {
      // update
    } else {
      // initial mount: ComponentDidMount
      didMountRef.current = true;
      window.scrollTo(0, 0); // always scroll to top on 1st load
    }
  }, [role]);
  return (
    <AccountMenuWrapper>
      {IsSuperAdmin(role ?? "") ? (
        <>
          <MenuItem
            onClick={() => {
              handleMenuClick("hd");
            }}
          >
            <ListItemIcon>
              <EnhancedEncryptionIcon fontSize="small" />
            </ListItemIcon>{" "}
            Hash Data
          </MenuItem>

          <MenuItem
            onClick={() => {
              handleMenuClick("hc");
            }}
          >
            <ListItemIcon>
              <CompareIcon fontSize="small" />
            </ListItemIcon>{" "}
            Compare Hash
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleMenuClick("system-notification");
            }}
          >
            <ListItemIcon>
              <NotificationsActiveIcon fontSize="small" />
            </ListItemIcon>{" "}
            System Notification
          </MenuItem>
          <Divider />
        </>
      ) : (
        <></>
      )}
      <MenuItem
        onClick={() => {
          handleMenuClick("dashboard");
        }}
      >
        <ListItemIcon>
          <DashboardIcon fontSize="small" />
        </ListItemIcon>{" "}
        Dashboard
      </MenuItem>

      <MenuItem
        onClick={() => {
          handleMenuClick("products");
        }}
      >
        <ListItemIcon>
          <QrCode2Icon fontSize="small" />
        </ListItemIcon>
        Products
      </MenuItem>

      <MenuItem
        onClick={() => {
          handleMenuClick("categories");
        }}
      >
        <ListItemIcon>
          <CategoryIcon fontSize="small" />
        </ListItemIcon>
        Categories
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleMenuClick("brands");
        }}
      >
        <ListItemIcon>
          <BrandingWatermarkIcon fontSize="small" />
        </ListItemIcon>
        Brands
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleMenuClick("users");
        }}
      >
        <ListItemIcon>
          <SupervisorAccountIcon fontSize="small" />
        </ListItemIcon>
        Users
      </MenuItem>
      {/* <MenuItem
        onClick={() => {
          handleMenuClick("settings");
        }}
      >
        <ListItemIcon>
          <Settings fontSize="small" />
        </ListItemIcon>
        Settings
      </MenuItem> */}
      <MenuItem
        onClick={() => {
          handleMenuClick("images");
        }}
      >
        <ListItemIcon>
          <AddAPhotoIcon fontSize="small" />
        </ListItemIcon>
        Images
      </MenuItem>
      <Divider />
      {/* <MediaQuery maxWidth={992}>
        <h2>[Mobile]</h2>
        <p> Lorem epsum</p>
      </MediaQuery>

      <MediaQuery minWidth={992}>
        <h2>[Desktop]</h2>
        <p> Lorem epsum</p>
      </MediaQuery> */}
    </AccountMenuWrapper>
  );
};

export default AdminMenu;
