import {
  HamburgerMenu,
  Logo,
  NavigationDesktop
} from "components/elements/index";
import React from "react";
import MediaQuery from "react-responsive";
import { NavigationProps } from "types/interface.types";
import { NavbarLogo, NavbarTabs, NavbarWrapper } from "./navbar.styles";

const Navbar: React.FC<NavigationProps> = ({ onRouteChanged }) => {
  const handleOnRouteChanged = (): void => {
    if (onRouteChanged) onRouteChanged();
  };

  const position = "fixed";

  return (
    <NavbarWrapper>
      <NavbarLogo className="logo">
        <Logo />
      </NavbarLogo>
      <NavbarTabs>
        <MediaQuery maxWidth={992}>
          <HamburgerMenu />
        </MediaQuery>
        <MediaQuery minWidth={992}>
          <NavigationDesktop
            position={`${position}`}
            className="header"
            onRouteChanged={handleOnRouteChanged}
          />
        </MediaQuery>
      </NavbarTabs>
    </NavbarWrapper>
  );
};

export default Navbar;
