import { Button, Group, Paper, PasswordInput, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import authService from "api/auth.service";
import React from "react";
import { toast } from "react-toastify";
import { ChangePassword, PageProps } from "types/interface.types";
import { useStyles } from "../mantine.styles";
import { AccountPageWrapper } from "./account.styles";

const ChangePasswordPage: React.FC<PageProps> = () => {
  const { classes } = useStyles();

  const form = useForm({
    initialValues: {
      oldPassword: "",
      newPassword: ""
    },

    validate: {
      oldPassword: (value) =>
        !value || value.length > 80
          ? "Old password is required and must not exceed 30 characters"
          : null,
      newPassword: (value) =>
        !value || value.length > 80
          ? "Old password is required and must not exceed 30 characters"
          : null
    }
  });

  return (
    <AccountPageWrapper>
      <Paper shadow="md" radius="lg">
        <div className={classes.wrapper}>
          <form
            className={classes.form}
            onSubmit={form.onSubmit((values: ChangePassword) => {
              authService.changePassword(values).then((json) => {
                if (json.data.message.valid) {
                  toast.success(json.data.message);
                } else {
                  toast.info(json.data.message);
                }
                form.reset();
              });
            })}
          >
            <Text size="lg" weight={700} className={classes.title}>
              Change Password
            </Text>

            <div className={classes.fields}>
              <PasswordInput
                label="Old Password"
                placeholder="******"
                required
                {...form.getInputProps("oldPassword")}
              />{" "}
              <PasswordInput
                label="New Password"
                placeholder="******"
                required
                {...form.getInputProps("newPassword")}
              />
              <Group position="right" mt="md">
                <Button type="submit" className={classes.control}>
                  Change Password
                </Button>
              </Group>
            </div>
          </form>
        </div>
      </Paper>
    </AccountPageWrapper>
  );
};

export default ChangePasswordPage;
