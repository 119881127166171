// Product details page

import { Card, Grid, Paper, Text } from "@mantine/core";
import productService from "api/product.service";
import { NoImageFilename } from "common/constants";
import { asPriceHtml, showPrice } from "common/utils";
import AddCartButton from "components/cart/Cart.Button";
import ImageLoader from "components/elements/image-loader/ImageLoader";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Product, Products } from "types/model.types";
import { ProductDetailsWrapper } from "./products.styles";

type QueryParams = {
  id: string;
};

const DetailPage: React.FC = () => {
  const params: QueryParams = useParams();
  const [product, setProduct] = useState<Product | undefined>(undefined);
  const [featured, setFeatured] = useState<Products | undefined>();

  useEffect(() => {
    productService.getById(params.id).then((json) => {
      if (json && json.data.length === 1) {
        setProduct(json.data[0]);
      }
    });
    productService.getFeatured().then((json) => {
      setFeatured(json.data);
    });
  }, [params.id]);

  const asPrice = (amount: number): JSX.Element => {
    if (!amount)
      return (
        <span>
          0<sup>00</sup>
        </span>
      );
    const amt: string = amount.toFixed(2).toString();

    return (
      <span>
        {amt.substring(0, amt.indexOf("."))}
        <sup>{amt.substring(amt.indexOf(".") + 1)}</sup>
      </span>
    );
  };

  return (
    <ProductDetailsWrapper>
      {product ? (
        <Grid>
          <Grid.Col span={12}>
            <Paper shadow="xs" p="lg">
              <Grid>
                <Grid.Col lg={5}>
                  <div className="image-wrapper image-wrapper__d">
                    <ImageLoader
                      folder="d"
                      height={360}
                      name={product?.detailedImageName ?? NoImageFilename}
                    />
                  </div>
                </Grid.Col>
                <Grid.Col lg={7}>
                  <h1>{product?.title}</h1>
                  <div className={`pd ${showPrice() && "pd-price"}`}>
                    {showPrice() && asPrice(product?.sellPrice2)}
                  </div>
                  <div className="pd pd-units">
                    <em>{product?.unitsCtn} / CTN</em>
                  </div>
                  <div className="pd pd-cart">
                    <AddCartButton
                      id={product.id}
                      quantity={1}
                      title={product.title}
                      productCode={product.productCode}
                      price={product.sellPrice2}
                    />
                  </div>
                </Grid.Col>
              </Grid>
            </Paper>
          </Grid.Col>
          <Grid.Col span={12}>
            <Grid>
              <Grid.Col lg={7}>
                <div
                  className="pd pd-info"
                  dangerouslySetInnerHTML={{ __html: product?.productDesc }}
                />
              </Grid.Col>
              <Grid.Col lg={5}>
                <Grid>
                  {featured?.map((f, k) => {
                    return <Grid.Col span={4} key={k}></Grid.Col>;
                  })}
                </Grid>

                {featured?.map((f, k) => {
                  return (
                    k < 10 && (
                      <Card key={k} shadow="sm" p="lg" className="card">
                        <table>
                          <tbody>
                            <tr
                              key={`tr-${k}`}
                              style={{ verticalAlign: "top" }}
                            >
                              <td rowSpan={2}>
                                <ImageLoader
                                  id={f.productCode}
                                  folder="f"
                                  height={120}
                                  name={f?.detailedImageName ?? NoImageFilename}
                                />
                              </td>
                              <td>
                                <Text weight={500}>{f.title}</Text>
                              </td>
                              <td>
                                <div
                                  className={`product product--price ${
                                    showPrice() && "product--price-money"
                                  }`}
                                  dangerouslySetInnerHTML={{
                                    __html: showPrice()
                                      ? asPriceHtml(f.sellPrice2)
                                      : ""
                                  }}
                                />
                              </td>
                            </tr>
                            <tr key={`tr-${k}-2`}>
                              <td></td>
                              <td colSpan={2}>
                                <div className="card-button">
                                  <AddCartButton
                                    id={f.id}
                                    price={f.sellPrice2}
                                    productCode={f.productCode}
                                    quantity={1}
                                    title={f.title}
                                  />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Card>
                    )
                  );
                })}
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>
      ) : (
        <>
          <h2>Product Information</h2>
          <p>
            Requested product does not exist. Please try again later or select
            another product
          </p>
        </>
      )}
    </ProductDetailsWrapper>
  );
};
export default DetailPage;
