import styled from "styled-components";

const DefaultLayoutWrapper = styled.div`
  padding: 20px;

  @media (min-height: 1000px) {
    min-height: 950px !important;
  }
`;
export { DefaultLayoutWrapper };
