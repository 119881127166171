import axios from "axios";
import { REST_URL } from "common/constants";
import { getToken } from "./common.service";

class OrderService {
  async get() {
    const response = await axios.post(REST_URL + "order/getOrders", undefined, {
      headers: {
        authorization: getToken()
      }
    });
    return response ?? [];
  }
  async getByDebtor(debtorCode: number | undefined) {
    const response = await axios.post(REST_URL + "order/getOrdersByCustomer", {
      debtorCode
    });
    return response ?? [];
  }
  async getOrderDetails(orderId: number) {
    const response = await axios.post(
      REST_URL + "order/getOrderDetails",
      { orderId },
      {
        headers: {
          authorization: getToken()
        }
      }
    );
    return response ?? [];
  }
  async backOrders() {
    const response = await axios.post(
      REST_URL + "order/getBackOrders",
      undefined,
      {
        headers: {
          authorization: getToken()
        }
      }
    );
    return response ?? [];
  }
}

export default new OrderService();
