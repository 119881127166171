import styled from "styled-components";
const LoginWrapper = styled.div`
  padding: 20px;

  .mantine-Tabs-tabIcon {
    width: 30px;
    position: relative;
    top: -4px;
  }

  @media (max-width: 400px) {
    .mantine-Tabs-tabIcon {
      display: none !important;
    }
    .mantine-Tabs-tabControl {
      padding-right: 10px;
    }
  }
`;

const SignInWrapper = styled.div`
  min-height: 900;
  background-size: cover;
  box-shadow: 0 0 3px 3px #fff inset;

  .mantine-Tabs-tabIcon {
    width: 30px;
    position: relative;
    top: -4px;
  }
  h4 {
    text-transform: none !important;
  }

  &.cover {
    background-image: url(/images/login1.jpg);
    &-reg {
      background-image: url(/images/login2.jpg);
    }

    & div {
      & svg {
        color: #04aa6d;
        position: relative;
        top: 4px;
        width: 24px;
        height: 24px;
        margin-right: 20px;
      }
    }
  }

  & .form {
    border-right: 1px solid #eee;
    min-height: 900px;
    max-width: 450px;
    padding-top: 80px;
  }
  & .title {
    color: #000;

    &-small {
      font-size: 1.1rem;
      font-weight: normal;
    }
  }
`;

export { LoginWrapper, SignInWrapper };
