/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Modal } from "@mantine/core";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { LinearProgress } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridToolbarFilterButton
} from "@mui/x-data-grid";
import categoryService from "api/category.service";
import { PageAction } from "common/constants";
import BusyIndicator from "components/busy/BusyIndicator";
import React, { useEffect, useRef, useState } from "react";
import { AdminPageProps } from "types/interface.types";
import { Categories, Category } from "types/model.types";
import { AccountPageWrapper } from "./account.styles";
import EditCategory from "./dialogs/EditCategory";

const CategoryPage: React.FC<AdminPageProps> = () => {
  const didMountRef = useRef(false);
  const [busy, setBusy] = useState(false);
  const [categories, setCategories] = useState<Categories>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<
    Category | undefined
  >();
  const columns: GridColDef[] = [
    {
      field: "action",
      headerName: "",
      sortable: false,
      filterable: false,
      hideable: false,
      width: 60,
      renderCell: (cellValues: any) => {
        //https://smartdevpreneur.com/add-buttons-links-and-other-custom-cells-in-material-ui-datagrid/#How_to_Add_a_Button
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const handleClick = (event: any, cellValues: any) => {
          const c: Category = cellValues.row;
          setSelectedCategory(c);
          setOpen(true);
          event.stopPropagation();
        };

        return (
          <ModeEditIcon
            className="__action"
            onClick={(event) => {
              handleClick(event, cellValues);
            }}
          />
        );
      }
    },
    {
      field: "delete",
      headerName: "",
      sortable: false,
      filterable: false,
      hideable: false,
      width: 60,
      renderCell: (cellValues: any) => {
        //https://smartdevpreneur.com/add-buttons-links-and-other-custom-cells-in-material-ui-datagrid/#How_to_Add_a_Button
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const handleClick = (event: any, cellValues: any) => {
          const c: Category = cellValues.row;
          setSelectedCategory(c);

          if (c.id && c.id !== "") {
            categoryService.delete(c.id.toString()).then((json) => {
              const updated = categories.filter((item) => item.id !== c.id);
              setCategories(updated);
            });
          }

          event.stopPropagation();
        };

        return (
          <DeleteIcon
            className="__action"
            onClick={(event) => {
              handleClick(event, cellValues);
            }}
          />
        );
      }
    },
    {
      field: "id",
      headerName: "ID",
      width: 0,
      hide: true,
      sortable: false,
      filterable: false,
      hideable: false
    },
    { field: "name", headerName: "Category Name", width: 800 },
    { field: "total", headerName: "# Products", width: 100 }
  ];

  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true;
      setBusy(true);
      categoryService.get().then((json) => {
        if (json) {
          setCategories(json.data);
        }
        setBusy(false);
      });
    }
  }, [categories, selectedCategory]);

  return (
    <AccountPageWrapper>
      <h1>Categories</h1>
      <Button
        onClick={() => {
          setSelectedCategory(undefined);
          setOpen(true);
        }}
        leftIcon={<AddCircleIcon style={{ color: "#fff" }} />}
      >
        Add Category
      </Button>
      <br />
      <br />
      <BusyIndicator open={busy}></BusyIndicator>
      <DataGrid
        getRowId={(e: any) => e.id}
        rows={categories}
        columns={columns}
        loading={busy}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        components={{
          Toolbar: GridToolbarFilterButton,
          LoadingOverlay: LinearProgress
        }}
      />
      <Modal
        centered
        opened={open}
        onClose={() => setOpen(false)}
        title="Category!"
      >
        <EditCategory
          category={selectedCategory}
          onSaveComplete={(_: Category, a: PageAction) => {
            setOpen(false);

            if (a === PageAction.ADD) {
              setBusy(true);

              categoryService.get().then((json) => {
                if (json) {
                  setCategories(json.data);
                }
                setBusy(false);
              });
            } else {
              const updated = categories.map((item) => {
                return item.id === _?.id ? _ : item;
              });

              setCategories(updated);
            }
          }}
        />
      </Modal>
    </AccountPageWrapper>
  );
};

export default CategoryPage;
