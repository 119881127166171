/* eslint-disable @typescript-eslint/no-explicit-any */
import { Editor } from "@tinymce/tinymce-react";
import authService from "api/auth.service";
import imageService from "api/image.service";
import { IsSuperAdmin } from "common/role-service";
import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useState
} from "react";
import { RichEditorField, RichEditorRef } from "types/interface.types";

const RtfEditor: ForwardRefRenderFunction<RichEditorRef, RichEditorField> = (
  props,
  ref
) => {
  const [contentEditor, setContentEditor] = useState<string | undefined>(
    props.content
  );
  const handleEditorChange = (content: string): void => {
    setContentEditor(content);
  };

  useImperativeHandle(ref, () => ({
    getContent(): string {
      const html: string = contentEditor ?? "";
      setContentEditor(undefined);
      return html;
    }
  }));

  useEffect(() => {
    if (contentEditor === undefined) {
      setContentEditor(props.content ?? undefined);
    }
  }, [props]);

  useEffect(() => {
    const handler = (e: any) => {
      if (
        e.target.closest(
          ".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root"
        ) !== null
      ) {
        e.stopImmediatePropagation();
      }
    };

    document.addEventListener("focusin", handler);
    return () => document.removeEventListener("focusin", handler);
  }, []);

  return (
    <Editor
      init={{
        placeholder: props.placeHolder ?? "Enter product description here...",
        height: 500,
        fontsize_formats:
          "8pt 10pt 11pt 12pt 14pt 18pt 20pt 24pt 28pt 36pt 48pt 72pt",
        theme: "silver",
        menubar: false,
        paste_data_images: true,
        plugins: ["fullscreen lists table paste link image code preview hr"],
        toolbar:
          "undo redo | table tabledelete | styleselect fontsizeselect | " +
          "bold italic hr |alignleft aligncenter alignright alignjustify | forecolor backcolor " +
          " | numlist bullist outdent indent | image link | removeformat preview fullscreen" +
          `${
            IsSuperAdmin(authService.getCurrentUser()?.user.role ?? "")
              ? " | code "
              : ""
          }`,
        invalid_elements: "script",
        images_file_types: "jpg,jpeg,png",
        file_picker_types: "image",
        extended_valid_elements: "svg[*]",
        image_list: async function (success: any) {
          const images = await imageService.get();
          success(images ?? []);
        },
        content_style: `body { margin:0; line-height: 1.1rem; } 
          p { margin: 0; } 
          h1,  h2 { font-size: 1.875rem; }
          h3, h4, h5 { font-size: 1.5rem; }
          h1, h2, h3, h4 { margin:18px 0; color: #000; }
          ul li {
            list-style-type: circle;
            margin-left: 30px !important;
          }
          table {width: 100%; border-collapse: collapse;}
          td, th { padding: 10px; text-align: left; &:first-child { white-space: nowrap; } }`,

        // images_upload_credentials: false,
        images_upload_handler: function (blobInfo, success, failure) {
          imageService.upload(blobInfo.blob()).then((json) => {
            if (json.status < 200 || json.status >= 300) {
              return failure("HTTP Error: " + json.status);
            }
            return success(`/images/${blobInfo.filename()}`);
          });
        }
      }}
      value={contentEditor}
      onEditorChange={handleEditorChange}
    />
  );
};

export default forwardRef(RtfEditor);
