import { Button } from "@mantine/core";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import { LinearProgress } from "@mui/material";
import Link from "@mui/material/Link";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import cartService from "api/cart.service";
import BusyIndicator from "components/busy/BusyIndicator";
import AdminPageBoundary from "components/elements/AdminPageBoundary";
import React, { useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "reactjs-popup/dist/index.css";
import { AnyAction, bindActionCreators } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState } from "redux/configureStore";
import * as cartActions from "redux/store/cart/thunks";
import { ShoppingCartPlus } from "tabler-icons-react";
import { Cart, CartItem } from "types/model.types";
import AddRemoveCartButton from "./Cart.Button2";
import { CartLandingWrapper } from "./landing.styles";

type ShoppingCartPageProps = {
  total?: number;
  cart?: Cart;
  addItemToCart?: (item: CartItem) => void;
  removeItemFromCart?: (id: string) => void;
  resetCart?: () => void;
  initializeCart?: () => void;
};

const Landing: React.FC<ShoppingCartPageProps> = ({
  total,
  cart,
  removeItemFromCart,
  initializeCart
}) => {
  const [busy, setBusy] = useState(false);

  const columns: GridColDef[] = [
    {
      field: "productId",
      headerName: "ID",
      width: 100,
      hide: true,
      sortable: false,
      filterable: false,
      hideable: false
    },
    { field: "productCode", headerName: "Code", width: 120 },
    { field: "title", headerName: "Title", width: 735 },
    {
      field: "price",
      headerName: "Carton Price",
      headerAlign: "right",
      align: "right",
      width: 155,
      renderCell: (cellValues: GridRenderCellParams) => {
        return <>{(cellValues.row.price ?? 0).toFixed(2)}</>;
      }
    },
    {
      field: "quantity",
      headerName: "Quantity (CTN)",
      width: 120,
      renderCell: (cellValues: any) => {
        //https://smartdevpreneur.com/add-buttons-links-and-other-custom-cells-in-material-ui-datagrid/#How_to_Add_a_Button
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (
          <AddRemoveCartButton
            id={cellValues.row.productId}
            price={cellValues.row.price}
            productCode={cellValues.row.productCode}
            quantity={cellValues.row.quantity}
            title={cellValues.row.title}
          />
        );
      }
    },
    {
      field: "subTotal",
      headerName: "Sub Total",
      headerAlign: "right",
      align: "right",
      width: 155,
      renderCell: (cellValues: GridRenderCellParams) => {
        const calSubTotal = (): string => {
          const p: number = cellValues.row.price ?? 0;
          const q: number = cellValues.row.quantity ?? 0;
          const t: string = (p * q).toFixed(2);
          return t;
        };
        return <>{calSubTotal()}</>;
      }
    },

    {
      field: "action",
      headerName: "Action",
      width: 80,
      renderCell: (cellValues: GridRenderCellParams) => {
        //https://smartdevpreneur.com/add-buttons-links-and-other-custom-cells-in-material-ui-datagrid/#How_to_Add_a_Button
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const handleClick = (event: any, cellValues: GridRenderCellParams) => {
          event.stopPropagation();
          removeItemFromCart && removeItemFromCart(cellValues.row.productId);
        };
        return (
          <DeleteSweepIcon
            fontSize="medium"
            className="__action"
            onClick={(event) => {
              handleClick(event, cellValues);
            }}
          />
        );
      }
    }
  ];

  /**
   * Submit customer order.  This will feed into the order into the JJL stock system.
   */
  const handleConfirmOrder = () => {
    if (!cart || !cart.id) {
      toast.warn("Unable to finalize order.  Cart is empty!");
      return;
    }

    try {
      setBusy(true);
      cartService
        .completeOrder(cart.id)
        .then(() => {
          initializeCart && initializeCart();
          toast.success("Your order has been submitted");
        })
        .catch((e) => {
          toast.error(e.message);
        });
    } finally {
      setTimeout(() => {
        setBusy(false);
      }, 2000);
    }
  };

  return (
    <AdminPageBoundary>
      <CartLandingWrapper>
        <div className="section">
          <span>Your Shopping Cart (</span>
          <span className="section-title">{total}</span>
          <span>) items</span>
        </div>
        <Link
          id="continue-shopping"
          underline="hover"
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: "1.3rem",
            margin: "5px 0"
          }}
          color="inherit"
          className="href"
          href="/products"
        >
          Continue shopping
        </Link>

        <BusyIndicator open={busy}></BusyIndicator>
        <DataGrid
          getRowId={(e: CartItem) => e.productCode}
          rows={cart?.items ?? []}
          columns={columns}
          loading={busy}
          pageSize={50}
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          components={{
            LoadingOverlay: LinearProgress
          }}
        />
        <Button
          disabled={!cart || cart.items.length === 0}
          onClick={handleConfirmOrder}
          className="confirm"
          leftIcon={<ShoppingCartPlus color="#fff" />}
        >
          Confirm Order
        </Button>
      </CartLandingWrapper>
    </AdminPageBoundary>
  );
};

// redux

const mapStateToProps = (state: AppState): ShoppingCartPageProps => ({
  total: state.shoppingCart.items.length,
  cart: state.shoppingCart
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  addItemToCart: bindActionCreators(cartActions.addItemToCart, dispatch),
  removeItemFromCart: bindActionCreators(
    cartActions.removeItemFromCart,
    dispatch
  ),
  resetCart: bindActionCreators(cartActions.resetCart, dispatch),
  initializeCart: bindActionCreators(cartActions.initializeCart, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Landing);
