import { GlobalColors } from "globalStyles";
import styled from "styled-components";

const HeaderSearchWrapper = styled.div`
  @media (max-width: 900px) {
    left: -80px;
    width: 50vw;
  }
  @media (max-width: 1200px) {
    left: -80px;
  }
  @media (max-width: 397px) {
    min-width: 220px;
    left: -60px;
  }

  @media (max-width: 400px) {
    .mantine-Popover-target,
    .mantine-Popover-root {
      .mantine-Popover-target,
      .mantine-Popover-root {
        width: 220px;
      }
    }
  }
  @media (min-width: 900px) {
    .mantine-Popover-target,
    .mantine-Popover-root {
      .mantine-Popover-target,
      .mantine-Popover-root {
        width: 45vw;
      }
    }
  }

  .mantine-Popover-target,
  .mantine-Popover-root {
    height: 22px;
  }

  position: relative;
  border-radius: 5px;
  padding: 13px;
  background-color: #fff;
  width: 60vw;
  min-width: 250px;
  margin: auto;
  height: 36px;
  top: 3px;

  & .search-results {
    cursor: pointer !important;
    color: red;
  }

  & svg {
    border: none;
    position: relative;
    top: -6px;
    left: -4px;
    color: #ccc;
    z-index: 10;
  }
  & button,
  input {
    height: 29px;
    border: none;
  }
  .search-input {
  }
  & button {
    position: absolute;
    right: 3px;
    top: 3px;
    background-color: #f7931e;
    color: #fff;
    //font-weight:bold;
    font-size: 1.1rem;
    width: 90px;
    border-radius: 4px;
    cursor: pointer;
  }
  & input {
    display: flex;
    position: relative;
    top: -38px;
    left: -4px;
    width: 100% !important;
    color: ${GlobalColors.colorHeaderTopBarBackground};
    padding-left: 28px;
    padding-right: 100px;

    &:focus {
      border-color: none;
      box-shadow: none;
      outline: none;
    }
  }
  & .searchContainer {
    color: white;
    margin: auto;
    width: 65%;
    padding-top: 6px;
    text-align: center;

    &--box {
      border-radius: 5px;
      padding: 3px;
      background-color: #fff;
      width: 40vw;
      min-width: 250px;
      margin: auto;

      &-s1 {
        position: relative;
        width: 100%;
        // search icon
      }
    }
    & h1,
    h2,
    h3,
    h4 {
      color: white;
      font-size: 1.1rem;
    }
  }
`;
export { HeaderSearchWrapper };
