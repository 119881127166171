/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Modal } from "@mantine/core";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { LinearProgress } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridToolbarFilterButton
} from "@mui/x-data-grid";
import brandService from "api/brand.service";
import { PageAction } from "common/constants";
import BusyIndicator from "components/busy/BusyIndicator";
import React, { useEffect, useRef, useState } from "react";
import { AdminPageProps } from "types/interface.types";
import { Brand, Brands, Category } from "types/model.types";
import { AccountPageWrapper } from "./account.styles";
import EditBrand from "./dialogs/EditBrand";

const BrandsPage: React.FC<AdminPageProps> = () => {
  const didMountRef = useRef(false);
  const [busy, setBusy] = useState(false);
  const [brands, setBrands] = useState<Brands>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedBrand, setSelectedBrand] = useState<Brand | undefined>();
  const columns: GridColDef[] = [
    {
      field: "action",
      headerName: "",
      sortable: false,
      filterable: false,
      hideable: false,
      width: 60,
      renderCell: (cellValues: any) => {
        //https://smartdevpreneur.com/add-buttons-links-and-other-custom-cells-in-material-ui-datagrid/#How_to_Add_a_Button
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const handleClick = (event: any, cellValues: any) => {
          const item: Brand = cellValues.row;
          setSelectedBrand(item);
          setOpen(true);
          event.stopPropagation();
        };

        return (
          <ModeEditIcon
            className="__action"
            onClick={(event) => {
              handleClick(event, cellValues);
            }}
          />
        );
      }
    },
    {
      field: "delete",
      headerName: "",
      sortable: false,
      filterable: false,
      hideable: false,
      width: 60,
      renderCell: (cellValues: any) => {
        //https://smartdevpreneur.com/add-buttons-links-and-other-custom-cells-in-material-ui-datagrid/#How_to_Add_a_Button
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const handleClick = (event: any, cellValues: any) => {
          const b: Category = cellValues.row;
          setSelectedBrand(b);
          if (b.id && b.id !== "") {
            brandService.delete(b.id).then(() => {
              const updated = brands.filter((item) => item.id !== b.id);
              setBrands(updated);
            });
          }
          event.stopPropagation();
        };

        return (
          <DeleteIcon
            className="__action"
            onClick={(event) => {
              handleClick(event, cellValues);
            }}
          />
        );
      }
    },
    {
      field: "id",
      headerName: "ID",
      width: 0,
      hide: true,
      sortable: false,
      filterable: false,
      hideable: false
    },
    { field: "name", headerName: "Brand Name", width: 800 },
    { field: "total", headerName: "# Products", width: 100 }
  ];

  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true;
      setBusy(true);
      brandService.get().then((json) => {
        if (json) {
          setBrands(json.data);
        }
        setBusy(false);
      });
    }
  }, [brands, selectedBrand]);

  return (
    <AccountPageWrapper>
      <h1>Brands</h1>
      <Button
        onClick={() => {
          setSelectedBrand(undefined);
          setOpen(true);
        }}
        leftIcon={<AddCircleIcon style={{ color: "#fff" }} />}
      >
        Add Brand
      </Button>
      <br />
      <br />

      <BusyIndicator open={busy}></BusyIndicator>
      <DataGrid
        getRowId={(e: any) => e.id}
        rows={brands}
        columns={columns}
        loading={busy}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        components={{
          Toolbar: GridToolbarFilterButton,
          LoadingOverlay: LinearProgress
        }}
      />
      <Modal
        centered
        opened={open}
        onClose={() => setOpen(false)}
        title="Brand!"
      >
        <EditBrand
          brand={selectedBrand}
          onSaveComplete={(_: Brand, a: PageAction) => {
            setOpen(false);

            if (a === PageAction.ADD) {
              setBusy(true);

              brandService.get().then((json) => {
                if (json) {
                  setBrands(json.data);
                }
                setBusy(false);
              });
            } else {
              const updated = brands.map((item) => {
                return item.id === _.id ? _ : item;
              });

              setBrands(updated);
            }
          }}
        />
      </Modal>
    </AccountPageWrapper>
  );
};

export default BrandsPage;
