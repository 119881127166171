import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import BrandingWatermarkIcon from "@mui/icons-material/BrandingWatermark";
import CategoryIcon from "@mui/icons-material/Category";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupIcon from "@mui/icons-material/Group";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import InventoryIcon from "@mui/icons-material/Inventory";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import ReportIcon from "@mui/icons-material/Report";
import SettingsIcon from "@mui/icons-material/Settings";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useRouteMatch } from "react-router-dom";
import { BreadcrumbWrapper } from "./breadcrumb.styles";

const Breadcrumb: React.FC = () => {
  const location = useLocation();
  const match = useRouteMatch();
  const [linkCss, setLinkCss] = useState<string>(
    "MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineHover css-3nyjit-MuiTypography-root-MuiLink-root"
  );

  useEffect(() => {
    const homeLink = document.getElementById("breadcrumb-home-link");
    if (homeLink?.className && linkCss !== homeLink?.className) {
      setLinkCss(homeLink?.className);
    }
  }, [linkCss]);

  const buildBreadcrumb = (): string[] => {
    let path = location.pathname;
    if (path.indexOf("products/details")) {
      path = path.substring(0, path.lastIndexOf("/"));
    }
    return path.split("/");
  };

  return (
    <BreadcrumbWrapper>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
      >
        <Link
          id="breadcrumb-home-link"
          underline="hover"
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          href="/"
        >
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          HOME
        </Link>

        {buildBreadcrumb().map((crumb) => {
          if (crumb && crumb !== "") {
            return (
              <NavLink
                id="header-nav_products"
                className={linkCss}
                to={`${match.url}${crumb === "details" ? "products" : crumb}`}
                key="top-nav_products-link"
              >
                {crumb === "products" ? (
                  <QrCode2Icon sx={{ mr: 0.5 }} fontSize="inherit" />
                ) : crumb === "users" ? (
                  <GroupIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                ) : crumb === "settings" ? (
                  <SettingsIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                ) : crumb === "dashboard" ? (
                  <DashboardIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                ) : crumb === "profile" ? (
                  <AssignmentIndIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                ) : crumb === "orders" ? (
                  <ProductionQuantityLimitsIcon
                    sx={{ mr: 0.5 }}
                    fontSize="inherit"
                  />
                ) : crumb === "about-us" ? (
                  <InfoIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                ) : crumb === "brands" ? (
                  <BrandingWatermarkIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                ) : crumb === "categories" ? (
                  <CategoryIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                ) : crumb === "contact-us" ? (
                  <ConnectWithoutContactIcon
                    sx={{ mr: 0.5 }}
                    fontSize="inherit"
                  />
                ) : crumb === "my-account" ? (
                  <SupervisorAccountIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                ) : crumb === "details" ? (
                  <InventoryIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                ) : (
                  <ReportIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                )}
                {crumb}
              </NavLink>

              //     <Link
              //     underline="hover"
              //     sx={{ display: 'flex', alignItems: 'center' }}
              //     color="inherit"
              //     href={`${match.url}${buildBreadcrumb()[1]}`}
              // >
              //     {crumb ==='products' ?
              //         <QrCode2Icon sx={{ mr: 0.5 }} fontSize="inherit" /> :
              //         crumb === 'about-us' ? <InfoIcon sx={{ mr: 0.5 }} fontSize="inherit" /> :
              //             crumb === 'contact-us' ? <ConnectWithoutContactIcon sx={{ mr: 0.5 }} fontSize="inherit" /> :
              //                 crumb === 'my-account' ? <SupervisorAccountIcon sx={{ mr: 0.5 }} fontSize="inherit" /> :
              //                  <BrowserNotSupportedIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              //     }
              //     {crumb}
              // </Link>
            );
          }
        })}
      </Breadcrumbs>
    </BreadcrumbWrapper>
  );
};

export default Breadcrumb;
