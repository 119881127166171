import { Paper, Title } from "@mantine/core";
import React from "react";
import { SignInWrapper } from "./login.styles";

const Register: React.FC = () => {
  return (
    <SignInWrapper className="cover cover-reg">
      <Paper className="form" radius={0} p={30}>
        <Title order={2} className="title" mt="md" mb={50}>
          Don&rsquo;t have a business account?
        </Title>
        <Title mt="md" className="title title-small" mb={50}>
          <em>The benefits of a JJL business account:</em>
        </Title>
        <div>
          <svg
            className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24"
            data-testid="DoneIcon"
          >
            <path d="M9 16.2 4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"></path>
          </svg>
          Create and/or track orders
        </div>
        <div>
          <svg
            className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24"
            data-testid="DoneIcon"
          >
            <path d="M9 16.2 4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"></path>
          </svg>
          Review past order(s)
        </div>
        <div>
          <svg
            className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24"
            data-testid="DoneIcon"
          >
            <path d="M9 16.2 4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"></path>
          </svg>
          No extra costs or conditions
        </div>
        <br />
        <Title mt="md" className="title title-small" mb={50}>
          Want to know more, get in touch with us
          <br /> on +64 4 569 5248
        </Title>
      </Paper>
    </SignInWrapper>
  );
};

export default Register;
