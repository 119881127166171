/* eslint-disable @typescript-eslint/no-explicit-any */
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { LinearProgress } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridToolbarFilterButton
} from "@mui/x-data-grid";
import orderService from "api/order.service";
import BusyIndicator from "components/busy/BusyIndicator";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AdminPageProps } from "types/interface.types";
import { BackOrders } from "types/model.types";
import { AccountPageWrapper } from "./account.styles";

const BackOrderPage: React.FC<AdminPageProps> = () => {
  const [busy, setBusy] = useState(true);
  const [backOrders, setBackOrders] = useState<BackOrders>([]);

  const columns: GridColDef[] = [
    {
      field: "productId",
      headerName: "ID",
      width: 0,
      hide: true,
      sortable: false,
      filterable: false,
      hideable: false
    },
    {
      field: "productCode",
      headerName: "Product Id",
      width: 0,
      hide: true,
      sortable: false,
      filterable: false,
      hideable: false
    },
    {
      field: "productDesc",
      headerName: "Product Description",
      width: 580
    },
    { field: "quantity", headerName: "Quantity", width: 120 },
    {
      field: "orderedOn",
      headerName: "Last Ordered On",
      width: 150,
      valueGetter: ({ value }) => {
        if (value && value !== "") {
          const d = new Date(value.split("T")[0]);

          if (d.getFullYear() === 1900) {
            return "";
          } else {
            return `${d.toLocaleDateString("en-NZ")}`;
          }
        }
        return "";
      }
    },

    {
      field: "AddToCart",
      headerName: "Add to cart",
      width: 150,
      hideable: false,
      filterable: false,
      sortable: false,
      renderCell: (cellValues: any) => {
        //https://smartdevpreneur.com/add-buttons-links-and-other-custom-cells-in-material-ui-datagrid/#How_to_Add_a_Button
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const handleClick = (event: any, cellValues: any) => {
          event.stopPropagation();
          toast.info(
            `Under construction - Add ${cellValues.row.Quantity} to shopping cart`
          );
        };
        return (
          <AddShoppingCartIcon
            fontSize="medium"
            className="__action"
            onClick={(event) => {
              handleClick(event, cellValues);
            }}
          />
        );
      }
    }
  ];
  useEffect(() => {
    let mounted = true;

    orderService
      .backOrders()
      .then((json) => {
        if (json && mounted) {
          setBackOrders(json.data);
          setBusy(false);
        }
      })
      .catch(() => {
        if (mounted) setBusy(false);
      });

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <AccountPageWrapper>
      <div className="section">
        <span>My back </span>
        <span className="section-title">orders</span>
      </div>
      <BusyIndicator open={busy}></BusyIndicator>
      <DataGrid
        getRowId={(e: any) => e.productId}
        rows={backOrders}
        columns={columns}
        loading={busy}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        components={{
          Toolbar: GridToolbarFilterButton,
          LoadingOverlay: LinearProgress
        }}
      />
    </AccountPageWrapper>
  );
};
export default BackOrderPage;
