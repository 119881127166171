import {
  Anchor,
  Button,
  Paper,
  PasswordInput,
  Text,
  TextInput,
  Title
} from "@mantine/core";
import { useForm } from "@mantine/form";
import AuthService from "api/auth.service";
import { DEFAULT_LANDING_PAGE } from "common/constants";
import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { AnyAction, bindActionCreators } from "redux";
import { ThunkDispatch } from "redux-thunk";
import * as cartActions from "redux/store/cart/thunks";
import { LoginProps } from "types/interface.types";
import { SignInWrapper } from "./login.styles";

type LoginData = {
  email: string;
  password: string;
};

const Login: React.FC<LoginProps> = ({
  onLoginSuccessful,
  onRegisterRequest,
  initializeCart
}) => {
  const history = useHistory();

  const form = useForm({
    initialValues: {
      email: "",
      password: ""
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
      password: (value) => (!value ? "Password is required" : null)
    }
  });

  return (
    <SignInWrapper className="cover">
      <Paper className="form" radius={0} p={30}>
        <form
          onSubmit={form.onSubmit((values: LoginData) => {
            AuthService.login(values.email, values.password).then(
              (resp) => {
                if (resp.message) {
                  toast.error(resp.message);
                } else {
                  onLoginSuccessful();
                  initializeCart && initializeCart();
                  history.push(
                    history.location.pathname ?? DEFAULT_LANDING_PAGE
                  );
                }
              },
              (error) => {
                const resMessage =
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString();
                resMessage && toast.error(resMessage);
              }
            );
          })}
        >
          <Title order={2} className="title" align="center" mt="md" mb={50}>
            Welcome back!
          </Title>

          <TextInput
            label="Email address"
            placeholder="your-email@gmail.com"
            size="md"
            required
            {...form.getInputProps("email")}
          />

          <PasswordInput
            label="Password"
            placeholder="Your password"
            mt="md"
            size="md"
            required
            {...form.getInputProps("password")}
          />
          <Button type="submit" fullWidth mt="xl" size="md">
            Login
          </Button>
        </form>
        <Text align="center" mt="md">
          Don&apos;t have an account?{" "}
          <Anchor<"a">
            href="#"
            weight={700}
            onClick={(event) => {
              event.preventDefault();
              if (onRegisterRequest) onRegisterRequest();
            }}
          >
            Request an account
          </Anchor>
        </Text>
      </Paper>
    </SignInWrapper>
  );
};

// redux
const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  initializeCart: bindActionCreators(cartActions.initializeCart, dispatch)
});

export default connect(undefined, mapDispatchToProps)(Login);
