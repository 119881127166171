import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk, { ThunkMiddleware } from "redux-thunk";
import { AppActions } from "./store";
import { cartReducer } from "./store/cart/reducers";

//
// combine all our reducers
export const rootReducer = combineReducers({
  shoppingCart: cartReducer
});

//
// single source of truth - app state
export type AppState = ReturnType<typeof rootReducer>;

//
// create our store
export const store = createStore(
  rootReducer,
  applyMiddleware(thunk as ThunkMiddleware<AppState, AppActions>)
);
