import styled from "styled-components";
const EditProductWrapper = styled.div`
  position: relative;
  height: 80vh;
  min-height: 850px;
  overflow: hidden;
`;
const ProductListWrapper = styled.div`
  height: 500px;
  width: 100%;
`;
const OrderPreviewWrapper = styled.div`
  .price {
    text-align: right;
  }
  & .preview {
    font-size: 1.2rem;

    &__header {
      border-bottom: 2px solid #000;
      font-weight: 600;
      &--title {
        border-bottom: 2px solid #000;
      }
      &--title,
      &--subTitle {
        text-align: center;
      }
      &--subTitle {
        font-weight: 600;
        font-size: 1.3rem;
        padding: 30px 0;
      }
      &--c1 {
        text-align: left;
      }
      &--c2 {
        text-align: center;
      }
      &--c3 {
        text-align: right;
        span {
          width: 200px;
          display: inline-block;
        }
      }
    }
    &__footer {
      padding: 30px 0;
      color: red;
    }
  }
`;
export { EditProductWrapper, ProductListWrapper, OrderPreviewWrapper };
