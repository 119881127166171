import { facebook, instagram, pinterest, twitter } from "assets";
import React from "react";
import {
  Copyright,
  CopyrightWrapper,
  FooterWrapper,
  Icon,
  IconsList,
  ListItem,
  SocialMediaWrapper
} from "./footer.styles";

const Footer: React.FC = () => {
  return (
    <FooterWrapper>
      <div className="main-inner-wrapper">
        <SocialMediaWrapper>
          <IconsList>
            <ListItem>
              <Icon src={facebook} />
            </ListItem>
            <ListItem>
              <Icon src={twitter} />
            </ListItem>
            <ListItem>
              <Icon src={pinterest} />
            </ListItem>
            <ListItem>
              <Icon src={instagram} />
            </ListItem>
          </IconsList>
        </SocialMediaWrapper>
        <CopyrightWrapper>
          <Copyright>
            @2022 John Jacobson Limited: All rights reserved.&nbsp;&nbsp;&nbsp;
            Version: {process.env["REACT_APP_BUILD_VERSION"]}
          </Copyright>
        </CopyrightWrapper>
      </div>
    </FooterWrapper>
  );
};
export default Footer;
