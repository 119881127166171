import { Cart } from "types/model.types";
import { ActionTypes } from "../types";
import { CartActionTypes } from "./actions";

const initialCartState: Cart = {
  id: undefined,
  createdOn: new Date(),
  items: []
}; // initialize cart with empty shopping cart

export const cartReducer = (
  state: Cart = initialCartState,
  action: CartActionTypes
): Cart => {
  switch (action.type) {
    case ActionTypes.ADD_OR_UPDATE_CART: {
      if (action.payload.productCode === undefined) return state;
      // if doesnt exist, add to array otherwise update it
      const inCart = state.items.some(
        (item) => item.productCode === action.payload.productCode
      );

      if (inCart) {
        // already in cart, shallow copy cart items

        state = {
          ...state,
          items: state.items.map((item) =>
            item.productCode === action.payload.productCode
              ? {
                  // found item, shallow copy item and update quantity property
                  ...item,
                  quantity: action.payload.quantity
                }
              : item
          )
        };
      } else {
        if (action.payload.productCode) {
          state = {
            ...state,
            items: [...state.items, action.payload]
          };
        }
      }

      return state;
    }

    case ActionTypes.REMOVE_ITEM_FROM_CART: {
      state = {
        ...state,
        items: state.items.filter((item) => item.productId !== action.payload)
      };
      return state;
    }
    case ActionTypes.RESET_CART:
      state = initialCartState;
      return state;

    case ActionTypes.CLEAR_CART: {
      return {
        ...state,
        id: undefined,
        items: []
      };
    }
    case ActionTypes.INITIALIZE_CART:
      state = action.payload;
      return state;

    default:
      return state;
  }
};
