import styled from "styled-components";
const NavbarWrapper = styled.div`
  position: relative;
  top: 5px;
  height: 100px;
`;
const NavbarLogo = styled.div`
  position: relative;
  width: 300px;
  top: 0;
  left: 0;
`;
const NavbarTabs = styled.div`
  position: absolute;
  top: 0;
  right: 10px;
  height: 100px;
`;
export { NavbarWrapper, NavbarLogo, NavbarTabs };
