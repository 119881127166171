/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Grid, Paper } from "@mantine/core";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Fade from "@mui/material/Fade";
import LinearProgress from "@mui/material/LinearProgress";
import AuthService from "api/auth.service";
import BrandService from "api/brand.service";
import CategoryService from "api/category.service";
import productService, { EditProductPayload } from "api/product.service";
import { NoImageFilename } from "common/constants";
import BusyIndicator from "components/busy/BusyIndicator";
//import FileUploadDialog from "./FileUpload";
import SelectBrand from "components/dropdown/SelectBrand";
import SelectCategory from "components/dropdown/SelectCategory";
import RtfEditor from "components/elements/editor/RtfEditor";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import {
  EditProductFormData,
  FileUploadPageProps,
  LookupData,
  LookupDatum,
  ProductDialogPageProps,
  RichEditorRef
} from "types/interface.types";
import { Brand, Category, Product } from "types/model.types";
import { DialogWrapper } from "./dialog.styles";

interface IProductImageProps {
  dImageRef: any;
  fImageRef: any;
}

const EditProductDialog: React.FC<ProductDialogPageProps> = ({
  open,
  product,
  onClose,
  onSave
}) => {
  const didMountRef = useRef(false);
  const [busy, setBusy] = useState<boolean>(false);
  const [brands, setBrands] = useState<LookupData>([]);
  const [categories, setCategories] = useState<LookupData>([]);
  const detailedImageRef = useRef<HTMLInputElement | null>(null);
  const featuredImageRef = useRef<HTMLInputElement | null>(null);
  const [detailedImgPreview, setDetailedImgPreview] = useState<
    string | undefined
  >();
  const [featuredImgPreview, setFeaturedImgPreview] = useState<
    string | undefined
  >();
  const [detailedImage, setDetailedImage] = useState<FileUploadPageProps>();

  const editorRef = useRef<RichEditorRef>(null);
  const [openFileUpload, setOpenFileUpload] = useState<boolean>(false);

  const [formState, setFormState] = useState<
    EditProductFormData & IProductImageProps
  >({
    initialize: true,
    brand: undefined,
    category: undefined,
    productDesc: undefined,
    detailedPhoto: undefined,
    featuredPhoto: undefined,
    dImageRef: null,
    fImageRef: null
  });

  useEffect(() => {
    if (didMountRef.current) {
      if (!open) return;

      if (formState.initialize && open && !busy) {
        setBusy(true);
        let dImageName = product?.detailedImageName;
        if (dImageName === null || dImageName === undefined)
          dImageName = NoImageFilename;
        let fImageName = product?.featuredImageName;
        if (fImageName === null || fImageName === undefined)
          fImageName = NoImageFilename;

        Promise.all([
          productService.getImage("d", dImageName),
          productService.getImage("f", fImageName),
          Promise.resolve(brands.find((x) => x.label === product?.brandName)),
          Promise.resolve(
            categories.find((x) => x.label === product?.categoryName)
          )
        ])
          .then((values) => {
            const newState = {
              ...formState,
              initialize: false,
              productDesc: product?.productDesc,
              brand: values[2],
              category: values[3],
              detailedPhoto: values[0].data.img,
              featuredPhoto: values[1].data.img
            };
            setFormState(newState);
            setBusy(false);
          })
          .catch((e) => {
            handleClose();
          });
      }
    } else {
      didMountRef.current = true;
      BrandService.get().then((json) => {
        if (json) {
          const brands: LookupData = json.data.map((x: Brand) => {
            return {
              label: x.name,
              value: x.id
            };
          });
          setBrands(brands);
        }
      });
      CategoryService.get().then((json) => {
        if (json) {
          const categories: LookupData = json.data.map((x: Category) => {
            return {
              label: x.name,
              value: x.id
            };
          });
          setCategories(categories);
        }
      });
    }
  }, [open, busy, product, formState]);

  const handleClose = () => {
    /* null: can be use as selected...undefiend, initial mount/load of prev data */
    setFormState({
      initialize: true,
      brand: undefined,
      category: undefined,
      productDesc: undefined,
      detailedPhoto: undefined,
      featuredPhoto: undefined,
      dImageRef: null,
      fImageRef: null
    });
    setBusy(false);
    onClose();
  };

  // /**
  //  * Handle saving
  //  */
  const handleSave = async () => {
    if (product) {
      setBusy(true);

      const id = product.id;
      const code = product.productCode.toString();
      let dImageName = product.detailedImageName;
      if (dImageName === null || dImageName === undefined)
        dImageName = NoImageFilename;
      let fImageName = product.featuredImageName;
      if (fImageName === null || fImageName === undefined)
        fImageName = NoImageFilename;

      const productDesc = editorRef.current?.getContent() ?? "";
      if (formState.dImageRef) {
        dImageName = `${product.productCode}.${formState.dImageRef.name
          .split(".")
          .pop()}`;
      }
      if (formState.fImageRef) {
        fImageName = `${product.productCode}.${formState.fImageRef.name
          .split(".")
          .pop()}`;
      }

      const payload: EditProductPayload = {
        id: id ?? "",
        productDesc,
        brand: formState.brand?.value ?? "",
        category: formState.category?.value ?? "",
        dImgName: dImageName,
        fImgName: fImageName
      };

      // save product details
      await productService
        .update(payload)
        .then((json) => {
          if (json.data.message === "Token Expired") {
            toast.error(
              "Your session has expired.  Please refresh your session by logging off and logging back in."
            );
            AuthService.logout();
            onClose();
            setBusy(false);
            return;
          }

          // save images
          //  refactor to upload in 1 call
          if (formState.dImageRef) {
            // if exist, user has changed the detailed image...
            const formData = new FormData();
            formData.append("code", code);
            formData.append("folder", "d");
            formData.append(
              "image",
              formState.dImageRef,
              formState.dImageRef.name
            );
            productService.uploadPhoto(formData).then((json) => {
              toast.success("Detailed photo uploaded");
            });
          }
          if (formState.fImageRef) {
            // if exist, user has changed the detailed image...
            const formData = new FormData();
            formData.append("code", code);
            formData.append("folder", "f");
            formData.append(
              "image",
              formState.fImageRef,
              formState.fImageRef.name
            );
            productService.uploadPhoto(formData).then((json) => {
              toast.success("Featured photo uploaded");
            });
          }
          toast.success("Product details updated successfully...");

          const updatedProduct: Product = {
            ...product,
            categoryName: formState.category?.label ?? "",
            brandName: formState.brand?.label ?? "",
            productDesc: productDesc,
            detailedImageName: dImageName,
            featuredImageName: fImageName
          };

          onSave && onSave(updatedProduct);
          handleClose();
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };

  // On file select (from the pop up)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDetailedImageChange = (event: any) => {
    // Update the state
    if (event.target.files.length === 1) {
      setFormState({
        ...formState,
        detailedPhoto: URL.createObjectURL(event.target.files[0]),
        dImageRef: event.target.files[0]
      });
    }
  };
  const onFeaturedImageChange = (event: any) => {
    if (event.target.files.length === 1) {
      setFormState({
        ...formState,
        featuredPhoto: URL.createObjectURL(event.target.files[0]),
        fImageRef: event.target.files[0]
      });
    }
  };

  return (
    <>
      <BusyIndicator open={open && busy} />
      <>
        <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
          <DialogTitle>
            [{product?.productCode}] - {product?.title}
          </DialogTitle>
          <DialogContent>
            <DialogWrapper>
              <Grid grow gutter="xs">
                <Grid.Col span={6}>
                  <label htmlFor="brand" className="form-title">
                    Brand
                  </label>
                  <SelectBrand
                    options={brands}
                    defaultOption={
                      brands.find((x) => x.label === product?.brandName) ?? null
                    }
                    onSelectionChanged={(e: LookupDatum | null) => {
                      if (e !== formState.brand) {
                        setFormState({ ...formState, brand: e });
                      }
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <label htmlFor="category" className="form-title">
                    Category
                  </label>
                  <SelectCategory
                    options={categories}
                    defaultOption={
                      categories.find(
                        (x) => x.label === product?.categoryName
                      ) ?? null
                    }
                    onSelectionChanged={(e: LookupDatum | null) => {
                      if (e !== formState.category) {
                        setFormState({ ...formState, category: e });
                      }
                    }}
                  />
                </Grid.Col>

                <Grid.Col span={12}>
                  <label htmlFor="description" className="form-title">
                    Description
                  </label>
                  <RtfEditor
                    content={formState.productDesc}
                    stale={false}
                    ref={editorRef}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <label htmlFor="password" className="required form-title">
                    Detailed Photo
                  </label>
                  <input
                    type="file"
                    ref={detailedImageRef}
                    multiple={false}
                    accept="image/png, image/jpeg, image/jpg"
                    hidden
                    onChange={onDetailedImageChange}
                  />
                  <Paper shadow="xs" p="md">
                    <img
                      onClick={() => {
                        detailedImageRef?.current?.click();
                      }}
                      width={300}
                      height={300}
                      src={formState.detailedPhoto}
                    />
                  </Paper>
                </Grid.Col>
                <Grid.Col span={6}>
                  <label htmlFor="password" className="required form-title">
                    Featured Photo
                  </label>
                  <input
                    type="file"
                    ref={featuredImageRef}
                    multiple={false}
                    accept="image/png, image/jpeg, image/jpg"
                    hidden
                    onChange={onFeaturedImageChange}
                  />
                  <Paper shadow="xs" p="md">
                    <img
                      onClick={() => {
                        featuredImageRef?.current?.click();
                      }}
                      width={300}
                      height={300}
                      src={formState.featuredPhoto}
                    />
                  </Paper>
                </Grid.Col>
              </Grid>

              {/* {pageState.message && (
                <div >
                  <div className="alert alert-danger" role="alert">
                    {pageState.message}
                  </div>
                </div>
              )} */}
            </DialogWrapper>
          </DialogContent>
          <DialogActions>
            <Fade
              style={{
                transitionDelay: "800ms"
              }}
              unmountOnExit
            >
              <LinearProgress />
            </Fade>
            <Button onClick={handleClose} disabled={busy}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleSave();
              }}
              disabled={busy}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </>
    </>
  );
};

export default EditProductDialog;
