import { Accordion, List } from "@mantine/core";
import brandService from "api/brand.service";
import categoryService from "api/category.service";
import React, { useEffect, useRef, useState } from "react";
import { BrandFlickr } from "tabler-icons-react";
import { Brand, Brands, Categories, Category } from "types/model.types";
type ProductMenuProps = {
  onCategoryClicked: (category: Category) => void;
  onBrandClicked: (brand: Brand) => void;
};

const ProductMenu: React.FC<ProductMenuProps> = ({
  onCategoryClicked,
  onBrandClicked
}) => {
  const ref = useRef<any>();
  const [categories, setCategories] = useState<Categories>([]);
  const [brands, setBrands] = useState<Brands>([]);
  const [selectedBrandId, setSelectedBrandId] = useState<
    string | number | undefined
  >(undefined);
  const [selectedCategoryId, setSelectedCategoryId] = useState<
    string | number | undefined
  >(undefined);

  useEffect(() => {
    categoryService.get().then((json) => {
      if (json) {
        setCategories(json.data);
      }
    });
    brandService.get().then((json) => {
      if (json) {
        setBrands(json.data);
        if (ref.current) ref.current.click();
      }
    });
  }, []);

  return (
    <Accordion
      multiple
      disableIconRotation
      className="product-filter"
      initialItem={0}
    >
      <Accordion.Item label="Categories" aria-expanded>
        <List spacing="xs" size="sm" center icon={<BrandFlickr size={16} />}>
          {categories.map((c: Category, k: number) => {
            return (
              <List.Item
                onClick={() => {
                  setSelectedCategoryId(
                    selectedCategoryId === c.id ? undefined : c.id
                  );
                  onCategoryClicked(c);
                }}
                key={`cat_${k}`}
                className={selectedCategoryId === c.id ? "itemSelected" : ""}
              >
                {c.name}
                {c.total > 0 && <span className="itemCount">{c.total}</span>}
              </List.Item>
            );
          })}
        </List>
      </Accordion.Item>

      <Accordion.Item label="Brands" controlRef={ref}>
        <List spacing="xs" size="sm" center icon={<BrandFlickr size={16} />}>
          {brands.map((b: Brand, k: number) => {
            return (
              <List.Item
                onClick={() => {
                  setSelectedBrandId(
                    selectedBrandId === b.id ? undefined : b.id
                  );
                  onBrandClicked(b);
                }}
                key={`brand_${k}`}
                className={selectedBrandId === b.id ? "itemSelected" : ""}
              >
                {b.name}
                {b.total > 0 && <span className="itemCount">{b.total}</span>}
              </List.Item>
            );
          })}
        </List>
      </Accordion.Item>
    </Accordion>
  );
};

export default ProductMenu;
