import { Center, Paper } from "@mantine/core";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import authService from "api/auth.service";
import notificationService from "api/notification.service";
import {
  AppWrapper,
  MainContentWrapper,
  MainFooterWrapper,
  MainHeaderWrapper
} from "app.styles";
import { SESSION_EXPIRED, SESSION_EXPIRED_MSG } from "common/constants";
import ErrorBoundary from "components/ErrorBoundary";
import { Breadcrumb } from "components/index";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AnyAction, bindActionCreators } from "redux";
import { ThunkDispatch } from "redux-thunk";
import * as cartActions from "redux/store/cart/thunks";
import { IAppStateProps } from "types/interface.types";
import { DefaultLayout, Footer, Header } from "./components/index";
import GlobalStyle from "./globalStyles";

const App: React.FC<IAppStateProps> = ({ resetCart }) => {
  const location = useLocation();
  const [notificationMarkup, setNotificationMarkup] = useState<
    string | undefined
  >(undefined);
  const [minimizeNotification, setMinizeNotification] =
    useState<boolean>(false);

  useEffect(() => {
    let mounted = true;
    // check if user is already logged in... if so, double check token is still valid
    if (authService.getCurrentUser() !== null) {
      authService.vt().then((json) => {
        if (json && json.data.message === SESSION_EXPIRED) {
          toast.warn(SESSION_EXPIRED_MSG);
          authService.logout();
          resetCart && resetCart();
        }
      });
    } else {
      resetCart && resetCart();
    }

    notificationService.get().then((json) => {
      if (mounted) {
        setNotificationMarkup(json?.data?.message ?? undefined);
      }
    });

    // now setup to check every 60 mins
    const authServiceInterval = setInterval(() => {
      if (authService.getCurrentUser() !== null) {
        authService.vt().then((json) => {
          if (json && json.data.message === SESSION_EXPIRED) {
            toast.warn(SESSION_EXPIRED_MSG);
            authService.logout();
            resetCart && resetCart();
          }
        });
      } else resetCart && resetCart();
    }, 1000 * 60 * 30); // 1000 ms = 1second => check every 30mins

    const notificationInterval = setInterval(() => {
      notificationService.get().then((json) => {
        setNotificationMarkup(json?.data?.message ?? undefined);
      });
    }, 1000 * 60 * 10); // 1000 ms = 1second => check every 30mins

    return () => {
      mounted = false;
      clearInterval(authServiceInterval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
      clearInterval(notificationInterval);
    };
  }, []);

  return (
    <AppWrapper>
      {/* load our global styles */}
      <GlobalStyle />
      {/* main page header */}
      <MainHeaderWrapper>
        <Header />
      </MainHeaderWrapper>
      {/* DefaultLayout is responsible for switching out main content pages base on route data */}
      <MainContentWrapper>
        {/* {process.env.NODE_ENV === "development" && <ScreenTest />} */}
        {notificationMarkup && (
          <div className={`alert ${minimizeNotification && "minimise"}`}>
            <span
              className={`closebtn `}
              onClick={() => {
                setNotificationMarkup(undefined);
                setMinizeNotification(!minimizeNotification);
              }}
            >
              {minimizeNotification ? <>&uml;</> : <>&times;</>}
            </span>
            <div>
              <NotificationsActiveIcon />
              <span>System Notification</span>
            </div>
            <div
              className="message"
              dangerouslySetInnerHTML={{ __html: notificationMarkup ?? "" }}
            />
          </div>
        )}
        <div className="main-inner-wrapper breadcrumb">
          <Breadcrumb />
        </div>
        {(location.pathname.trim() === "/" ||
          location.pathname.toLowerCase() === "/home") && (
          <div className="home">
            <div className="home-banner">
              <img src="/images/3.jpg" />
            </div>
            <div className="home-banner__feature">
              <Center className="home" style={{ height: 200 }}>
                <Paper className="home-banner__feature-item" shadow="xs" p="md">
                  <img src="/images/hl1.jpg" />
                </Paper>
                <Paper className="home-banner__feature-item" shadow="xs" p="md">
                  <img src="/images/hl2.jpg" />
                </Paper>
                <Paper className="home-banner__feature-item" shadow="xs" p="md">
                  <img src="/images/hl3.jpg" />
                </Paper>
              </Center>
            </div>
          </div>
        )}
        <ErrorBoundary>
          <DefaultLayout />
        </ErrorBoundary>{" "}
      </MainContentWrapper>
      {/* main footer */}
      <MainFooterWrapper>
        <Footer />
      </MainFooterWrapper>
      <ToastContainer autoClose={3000} hideProgressBar />
    </AppWrapper>
  );
};

// redux
const mapDispatchToProps = (dispatch: ThunkDispatch<IAppStateProps, any, AnyAction>) => ({
  resetCart: bindActionCreators(cartActions.resetCart, dispatch)
});

export default connect(undefined, mapDispatchToProps)(App);
