import styled from "styled-components";
const BusyWrapper = styled.div`
  text-transform: none !important;
  text-align: centre;
  display: flex;
  align-items: center;
  justify-content: center;
  h3 {
    position: relative;
    top: 8px;
    text-transform: none;
    font-size: 1.3rem;
  }
  & svg {
    color: red;
  }
`;
export { BusyWrapper };

