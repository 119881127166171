import { Box, Button, Group, TextInput } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import brandService from "api/brand.service";
import { PageAction } from "common/constants";
import React from "react";
import { toast } from "react-toastify";
import { Brand } from "types/model.types";
import * as Yup from "yup";

export type EditBrandProps = {
  onSaveComplete: (b: Brand, a: PageAction) => void;
  brand: Brand | undefined;
};
const EditBrand: React.FC<EditBrandProps> = ({ brand, onSaveComplete }) => {
  const schema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .required("This field is required!")
      .min(3, "Min 3 characters")
  });

  const form = useForm({
    schema: yupResolver(schema),
    initialValues: {
      name: brand?.name ?? ""
    },
    validate: {
      name: (value) => value.length > 3
    }
  });

  const handleSave = () => {
    if (form.validate().hasErrors) return;

    const b: Brand = brand
      ? { ...brand, name: form.values.name }
      : {
          id: "",
          name: form.values.name,
          total: 0
        };

    brandService.addEdit(b).then((json) => {
      if (json.data.err) {
        toast.error("Failed to save brand information");
      } else {
        toast.success(json.data.message);
        onSaveComplete(b, b.id !== "" ? PageAction.EDIT : PageAction.ADD);
      }
    });
  };

  return (
    <Box sx={{ maxWidth: 300 }} mx="auto">
      <form onSubmit={form.onSubmit((values) => console.log(values))}>
        <TextInput
          required
          maxLength={50}
          label="Name"
          placeholder="Enter brand name"
          {...form.getInputProps("name")}
        />

        <Group position="right" mt="md">
          <Button type="submit" onClick={handleSave}>
            Submit
          </Button>
        </Group>
      </form>
    </Box>
  );
};

export default EditBrand;
