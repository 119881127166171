import { Modal, Popover, useMantineTheme } from "@mantine/core";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuItem from "@mui/material/MenuItem";
import AuthService from "api/auth.service";
import { DefaultLogoDesktop } from "assets/desktop";
import { DefaultLogoMobile } from "assets/mobile";
import LoginPage from "components/auth/Landing";
import React, { useEffect, useRef, useState } from "react";
import MediaQuery from "react-responsive";
import { useHistory } from "react-router-dom";
//import Logout from "@mui/icons-material/Logout";
import { Lock, Logout } from "tabler-icons-react";
import { JwtAuthData } from "types/interface.types";
import { LoginBadgeWrapper } from "./login.styles";

const LoginBadge: React.FC = () => {
  const didMountRef = useRef(false);
  const [itemCount] = useState<number>(0);
  const [user, setUser] = useState<JwtAuthData | null>(null);
  const [opened, setOpened] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const theme = useMantineTheme();
  const history = useHistory();

  useEffect(() => {
    function refetch() {
      const user = AuthService.getCurrentUser();
      setUser(user);
    }

    window.addEventListener("storage", () => {
      // When storage changes refetch
      refetch();
    });

    if (didMountRef.current) {
      // update
    } else {
      didMountRef.current = true;
      // initial mount
      const user = AuthService.getCurrentUser();

      if (user) {
        setUser(user);
      }
    }

    return () => {
      // When the component unmounts remove the event listener
      window.removeEventListener("storage", refetch);
    };
  }, [itemCount, user]);

  const close = () => {
    window.location.reload();
  };

  return (
    <LoginBadgeWrapper>
      {user !== undefined && user !== null ? (
        <Popover
          opened={opened}
          onClose={() => setOpened(false)}
          trapFocus={false}
          closeOnEscape={false}
          target={
            <HowToRegIcon
              onMouseEnter={() => {
                setOpened(true);
                setOpenModal(false);
              }}
            />
          }
          width={260}
          position="bottom"
          withArrow
        >
          <div>
            <MenuItem
              onClick={() => {
                AuthService.logout();
                close();
              }}
            >
              <ListItemIcon>
                <Logout data-autofocus fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push("/my-account/change-password");
                close();
              }}
            >
              <ListItemIcon>
                <Lock data-autofocus fontSize="small" />
              </ListItemIcon>
              Change Password
            </MenuItem>
          </div>
        </Popover>
      ) : (
        <AccountCircleIcon
          onClick={() => {
            setOpenModal(true);
          }}
        />
      )}
      <Modal
        size="full"
        opened={openModal}
        overlayColor={
          theme.colorScheme === "dark"
            ? theme.colors.dark[9]
            : theme.colors.gray[2]
        }
        overlayOpacity={0.55}
        overlayBlur={3}
        onClose={() => setOpenModal(false)}
      >
        <div className="modal">
          <div className="header">
            <MediaQuery maxWidth={992}>
              <img
                src={DefaultLogoMobile}
                style={{ position: "relative", marginTop: "20px" }}
                height={"100px"}
              />
            </MediaQuery>

            <MediaQuery minWidth={992}>
              <img src={DefaultLogoDesktop} height={"120px"} />
            </MediaQuery>
          </div>
          <div className="content">
            <LoginPage onLoginSuccessful={() => setOpenModal(false)} />
          </div>
        </div>
      </Modal>
    </LoginBadgeWrapper>
  );
};

export default LoginBadge;
