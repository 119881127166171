import moment from "moment";

export enum PageAction {
  ADD,
  EDIT,
  DELETE
}

export const CurrentDate = moment().format("YYYY-MM-DD");
export const CurrentDateTime = moment().format("YYYY-MM-DD HH:MM:SS");
export const NoImageFilename = "no-image.png";

export const REST_URL =
  process.env.REACT_APP_JJL_REST_URL ?? "http://localhost:3001/";
export const SESSION_EXPIRED = "Token Expired";
export const SESSION_EXPIRED_MSG =
  "Session Expired.  You have been logged off the site.";
export const DEFAULT_LANDING_PAGE = "/";

export enum RoleType {
  ADMIN = "Administrators",
  CUSTOMER = "Customers",
  Guest = "Anonymous",
  SUPERADMIN = "SuperUsers"
}
export enum OrderStage {
  PICKING = 0,
  PACKINg = 1,
  COMPLETE = 2
}
export enum StepperOrderStage {
  PICKING = 2,
  PACKING = 3,
  COMPLETE = 4
}
