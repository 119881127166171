import styled from "styled-components";
const CartWrapper = styled.div`
  //cart badge - # of cart items
  & span {
    position: absolute;
    top: -8px;
    right: 0;
    border-radius: 50%;
    //width: 18px;
    height: 18px;
    padding: 2px;
    color: #000;
    text-align: center;
    font-size: 0.7rem;
    color: #fff;
  }
`;

const CartButtonWrapper = styled.button`
  display: block;
  padding: 1.5em 1em 0.5em 1em;
  border-radius: 100px;
  border: none;
  font-size: 1.1em;
  position: relative;
  background: #0652dd;
  cursor: pointer;
  overflow: hidden;
  transition: transform 0.1s;
  z-index: 1;
  width: 95px;
  margin: 0 auto;

  &:hover {
    transform: scale(0.9);
  }

  .pretext {
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    text-transform: uppercase;
    font-weight: 400;
    justify-content: center;
    align-items: center;
    font-family: "Quicksand", sans-serif;
  }
  i {
    margin-right: 10px;
  }
`;

const AddRemoveCartButtonWrapper = styled.div`
  width: 100px;

  & span {
    height: 100%;
    width: 30px;
    display: inline-block;
    border: 1px solid #ccc;
    cursor: pointer;

    & svg {
      margin-left: 5px;
      margin-top: 4px;
      text-align: center;
      color: #000;
    }
  }

  & label {
    position: relative;
    width: 40px;
    display: inline-block;
    text-align: center;
  }
`;

export { CartWrapper, CartButtonWrapper, AddRemoveCartButtonWrapper };
