// Product details page
import HomeIcon from "@mui/icons-material/Home";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Divider } from "@mui/material";
import Grid from "@mui/material/Grid";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuItem from "@mui/material/MenuItem";
import AuthService from "api/auth.service";
import { RoleType } from "common/constants";
import { IsAdmin, IsCustomer } from "common/role-service";
import { PageNotFound } from "components";
import React, { useEffect, useState } from "react";
import MediaQuery from "react-responsive";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import Popup from "reactjs-popup";
import { Lock, Logout } from "tabler-icons-react";
import { JwtAuthData, PageProps } from "types/interface.types";
import { AccountLandingWrapper, AccountMenuWrapper } from "./landing.styles";
import AdminMenu from "./Menu.Admin";
import MembersMenu from "./Menu.Members";
import BackOrders from "./Page.BackOrders";
import Brands from "./Page.Brands";
import Categories from "./Page.Categories";
import ChangePasswordPage from "./Page.ChangePassword";
import CompareHash from "./Page.CompareHash";
import CompletedOrder from "./Page.CompletedOrders";
import Dashboard from "./Page.Dashboard";
import HashData from "./Page.HashData";
import ImageUpload from "./Page.ImageUpload";
import Orders from "./Page.Orders";
import ProductPage from "./Page.Products";
import Profile from "./Page.Profile";
import Settings from "./Page.Settings";
import SystemNotification from "./Page.SystemNotification";
import Users from "./Page.Users";

const Landing: React.FC<PageProps> = () => {
  const [userData, setUserData] = useState<JwtAuthData | null>(
    AuthService.getCurrentUser()
  );

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    let mounted = true;

    window.scrollTo(0, 0); // always scroll to top on 1st load

    function refresh() {
      const loggedInUser = AuthService.getCurrentUser();
      // check if user is a customer or admin... default landing is dashboard...so if customer, take them to the backorder page

      if (userData !== loggedInUser && mounted) {
        setUserData(loggedInUser);
      }
    }

    // add event listener to storage chagne event
    window.addEventListener("storage", () => {
      // When storage changes refetch
      refresh();
    });

    if (
      (location.pathname === "/my-account/" ||
        location.pathname === "/my-account") &&
      userData?.user.role === RoleType.CUSTOMER
    ) {
      history.push("/my-account/orders");
      return;
    }

    return () => {
      // When the component unmounts remove the event listener
      window.removeEventListener("storage", refresh);
      mounted = false;
    };
  }, []);

  const switchPage = (): JSX.Element => {
    return (
      <Switch>
        <Route
          path="/my-account"
          exact
          render={(): JSX.Element => <Dashboard />}
        />
        <Route
          path="/my-account/dashboard"
          exact
          render={(): JSX.Element => <Dashboard />}
        />
        <Route
          path="/my-account/users"
          exact
          render={(): JSX.Element => <Users />}
        />
        <Route
          path="/my-account/products"
          exact
          render={(): JSX.Element => <ProductPage />}
        />
        <Route
          path="/my-account/categories"
          exact
          render={(): JSX.Element => <Categories />}
        />
        <Route
          path="/my-account/brands"
          exact
          render={(): JSX.Element => <Brands />}
        />
        <Route
          path="/my-account/settings"
          exact
          render={(): JSX.Element => <Settings />}
        />
        <Route
          path="/my-account/profile"
          exact
          render={(): JSX.Element => <Profile />}
        />
        <Route
          path="/my-account/orders"
          exact
          render={(): JSX.Element => <Orders />}
        />
        <Route
          path="/my-account/back-orders"
          exact
          render={(): JSX.Element => <BackOrders />}
        />
        <Route
          path="/my-account/completed-orders"
          exact
          render={(): JSX.Element => <CompletedOrder />}
        />
        <Route
          path="/my-account/hd"
          exact
          render={(): JSX.Element => <HashData />}
        />
        <Route
          path="/my-account/hc"
          exact
          render={(): JSX.Element => <CompareHash />}
        />
        <Route
          path="/my-account/system-notification"
          exact
          render={(): JSX.Element => <SystemNotification />}
        />
        <Route
          path="/my-account/change-password"
          exact
          render={(): JSX.Element => <ChangePasswordPage />}
        />

        <Route
          path="/my-account/images"
          exact
          render={(): JSX.Element => <ImageUpload />}
        />
        <Route path="*" render={(): JSX.Element => <PageNotFound />} />
      </Switch>
    );
  };
  return (
    <AccountLandingWrapper>
      <Grid container className="account">
        <MediaQuery minWidth={1280}>
          <Grid item xs={3} className={"account__left-container box"}>
            <AccountMenuWrapper>
              <h4>Accounts Menu </h4>
              {IsAdmin(userData?.user?.role) && (
                <AdminMenu role={userData?.user?.role} />
              )}
              {IsCustomer(userData?.user?.role) && <MembersMenu />}
              <MenuItem
                onClick={() => {
                  history.push("/my-account/change-password");
                }}
              >
                <ListItemIcon>
                  <Lock fontSize="small" />
                </ListItemIcon>
                Change Password
              </MenuItem>
              <MenuItem
                onClick={() => {
                  AuthService.logout();
                  history.push("/");
                }}
              >
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Sign Out
              </MenuItem>
            </AccountMenuWrapper>
          </Grid>
          <Grid item xs={9} className={"account__right-container"}>
            {switchPage()}
          </Grid>
        </MediaQuery>
        <MediaQuery maxWidth={1280}>
          <Grid item xs={12} className={"account__right-container"}>
            <AccountMenuWrapper className="mobile">
              <h4>Accounts Menu </h4>
              <Popup trigger={<MoreVertIcon />} nested>
                {() => (
                  <>
                    <MenuItem
                      onClick={() => {
                        history.push(`/`);
                        close();
                      }}
                    >
                      <ListItemIcon>
                        <HomeIcon />
                      </ListItemIcon>
                      Home
                    </MenuItem>
                    <Divider />
                    <AdminMenu />
                    <MembersMenu />
                  </>
                )}
              </Popup>
            </AccountMenuWrapper>
            {switchPage()}
          </Grid>
        </MediaQuery>
      </Grid>
    </AccountLandingWrapper>
  );
};

export default Landing;
