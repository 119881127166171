import React from "react";
import { PaginationWrapper } from "./pagination.styles";

interface IPaginationProps {
  totalPages: number;
  currentPage: number;
  onPageChanged: (page: number) => void;
}

const Pagination: React.FC<IPaginationProps> = ({
  totalPages,
  currentPage,
  onPageChanged
}) => {
  return (
    <PaginationWrapper>
      {currentPage !== 1 && (
        <button
          onClick={() => onPageChanged(currentPage - 1)}
          type="button"
          className="pageItem sides"
        >
          &lt;
        </button>
      )}
      {currentPage <= totalPages && (
        <button
          onClick={() => onPageChanged(1)}
          type="button"
          className={`pageItem ${currentPage === 1 ? "active" : ""}`}
        >
          {1}
        </button>
      )}
      {currentPage > 3 && <div className="separator">...</div>}
      {currentPage === totalPages && totalPages > 3 && (
        <button
          onClick={() => onPageChanged(currentPage - 2)}
          type="button"
          className="pageItem"
        >
          {currentPage - 2}
        </button>
      )}
      {currentPage > 2 && (
        <button
          onClick={() => onPageChanged(currentPage - 1)}
          type="button"
          className="pageItem"
        >
          {currentPage - 1}
        </button>
      )}
      {currentPage !== 1 && currentPage !== totalPages && (
        <button
          onClick={() => onPageChanged(currentPage)}
          type="button"
          className="pageItem active"
        >
          {currentPage}
        </button>
      )}
      {currentPage < totalPages - 1 && (
        <button
          onClick={() => onPageChanged(currentPage + 1)}
          type="button"
          className="pageItem"
        >
          {currentPage + 1}
        </button>
      )}
      {currentPage === 1 && totalPages > 3 && (
        <button
          onClick={() => onPageChanged(currentPage + 2)}
          type="button"
          className="pageItem"
        >
          {currentPage + 2}
        </button>
      )}
      {currentPage < totalPages - 2 && <div className="separator">...</div>}
      {totalPages > 1 && (
        <button
          onClick={() => onPageChanged(totalPages)}
          type="button"
          className={`pageItem ${currentPage === totalPages ? "active" : ""} `}
        >
          {totalPages}
        </button>
      )}
      {totalPages > 0 && currentPage !== totalPages && (
        <button
          onClick={() => onPageChanged(currentPage + 1)}
          type="button"
          className="pageItem sides"
        >
          &gt;
        </button>
      )}
    </PaginationWrapper>
  );
};

export default Pagination;
