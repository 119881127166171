import { Grid, LinearProgress } from "@mui/material";
import authService from "api/auth.service";
import React, { Component, ReactNode } from "react";
import { PageBoundaryWrapper } from "./element.styles";

interface Props {
  children: ReactNode;
  busy?: boolean;
  onEditClicked?: () => void;
  onSaveClicked?: () => void;
}
interface State {
  title: string;
}
/**
 * Technique used to catch errors on our page
 * A class component becomes an error boundary if it defines either (or both) of the lifecycle
 * methods static getDerivedStateFromError() or componentDidCatch(). Use static getDerivedStateFromError() to render a fallback UI after an error has been thrown.
 * Use componentDidCatch() to log error information.
 * Error boundaries work like a JavaScript catch {} block, but for components. Only class components can be error boundaries.
 * In practice, most of the time you’ll want to declare an error boundary component once and use it throughout your application.
 */
class AdminPageBoundary extends Component<Props> {
  public state: State = {
    title: "Edit"
  };
  public render() {
    return (
      <PageBoundaryWrapper>
        <Grid container>
          {authService.isAdminUser() && (
            <>
              <Grid item className="editor" xs={10}>
                <span>Page Tool:</span>
              </Grid>
              <Grid
                item
                className="editor"
                xs={2}
                style={{
                  position: "relative",
                  textAlign: "right"
                }}
              >
                <button
                  className="btn btn-default"
                  onClick={() => {
                    {
                      if (this.state.title === "Edit") {
                        this.props.onEditClicked && this.props.onEditClicked();
                      } else {
                        this.props.onSaveClicked && this.props.onSaveClicked();
                      }
                    }

                    this.setState({
                      title: this.state.title === "Edit" ? "Save" : "Edit"
                    });
                  }}
                >
                  <i
                    className={`fas ${
                      this.state.title === "Edit" ? "fa-pen" : "fa-save"
                    }`}
                  ></i>{" "}
                  {this.state.title}
                </button>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            {this.props.busy && <LinearProgress />}
            {this.props.children}
          </Grid>
        </Grid>
      </PageBoundaryWrapper>
    );
  }
}

export default AdminPageBoundary;
