import authService from "api/auth.service";
import { JwtAuthData } from "types/interface.types";

/**
 * Client side pagination
 * @param items
 * @param activePage
 * @param itemsPerPage
 * @returns
 */
export const paginator = (
  items: any,
  activePage: number,
  itemsPerPage: number
) => {
  const page = activePage || 1,
    per_page = itemsPerPage || 10,
    offset = (page - 1) * per_page,
    paginatedItems = items.slice(offset).slice(0, per_page),
    total_pages = Math.ceil(items.length / per_page);

  return {
    page: page,
    per_page: per_page,
    pre_page: page - 1 ? page - 1 : null,
    next_page: total_pages > page ? page + 1 : null,
    total: items.length,
    total_pages: total_pages,
    data: paginatedItems
  };
};

export const formatDate = (dateString: string): string => {
  const d = new Date(dateString.split("T")[0]);

  if (d.getFullYear() === 1900) {
    return "";
  } else {
    return `${d.toLocaleDateString("en-NZ")}`;
  }
};

export const showPrice = (): boolean => {
  const u: JwtAuthData | null = authService.getCurrentUser();
  return u !== null;
};

export const asPriceHtml = (amount: number): string => {
  if (!amount) return "<span>0<sup>00</sup></span>";

  const amt: string = amount.toFixed(2).toString();

  return `<span>${amt.substring(0, amt.indexOf("."))}<sup>${amt.substring(
    amt.indexOf(".") + 1
  )}</sup></span>`;
};
export const convertToCSV = (data: any) => {
  const array = [Object.keys(data[0])].concat(data);

  return array
    .map((it) => {
      return Object.values(it).toString();
    })
    .join("\n");
};
