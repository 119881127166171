import axios from "axios";
import { REST_URL } from "common/constants";
import { getToken } from "./common.service";

class NotificationService {
  /**
   *
   * @returns array of file names
   */
  async get() {
    const response = await axios.post(REST_URL + "notification/get", undefined);

    return response;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async send(msg: string) {
    const response = await axios.post(
      REST_URL + "notification/send",
      { msg },
      {
        headers: {
          authorization: getToken()
        }
      }
    );

    return response;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async clear() {
    const response = await axios.post(
      REST_URL + "notification/clear",
      undefined,
      {
        headers: {
          authorization: getToken()
        }
      }
    );

    return response;
  }
}

export default new NotificationService();
