import axios from "axios";
import { REST_URL } from "common/constants";
import { getToken } from "./common.service";

export type EditProductPayload = {
  id: string | number;
  productDesc: string;
  brand: string;
  category: string;
  dImgName: string;
  fImgName: string;
};

class ProductService {
  async get(
    page: number,
    sortDir: string | undefined = "ASC",
    category?: string,
    brand?: string,
    pageSize: number | undefined = 30,
    sortBy: string | undefined = "Fkey"
  ) {
    const response = await axios.post(REST_URL + "product/getProducts", {
      page,
      sortDir,
      category,
      brand,
      pageSize,
      sortBy
    });
    return response ?? [];
  }
  async search(searchTerm: string) {
    const response = await axios.post(REST_URL + "product/search", {
      searchTerm
    });
    return response ?? [];
  }

  async getById(productCode: string) {
    const response = await axios.get(
      REST_URL + `product/details/${productCode}`
    );
    return response ?? [];
  }

  async getFeatured() {
    const response = await axios.post(REST_URL + "product/getFeatured");
    return response ?? [];
  }
  async getTopSeller() {
    const response = await axios.post(REST_URL + "product/getTopSeller");
    return response ?? [];
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async uploadPhoto(payload: FormData) {
    const response = await axios.post(
      REST_URL + "product/uploadImage",
      payload,
      {
        onUploadProgress: (ProgressEvent) => {
          console.log(
            `upload progress: ${Math.round(
              (ProgressEvent.loaded / ProgressEvent.total) * 100
            )} %`
          );
        },
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: getToken()
        }
      }
    );

    return response;
  }

  async update(payload: EditProductPayload) {
    const response = await axios.post(REST_URL + "product/update", payload, {
      headers: {
        authorization: getToken()
      }
    });

    return response;
  }

  //async getImage(l: string, n: string) {
  async getImage(l: string, n: string) {
    const response = await axios.get(`${REST_URL}product/getImage/${l}/${n}`);
    return response;
  }

  async toggleFeature(id: string | number, featured: boolean) {
    const payload = {
      id,
      featured
    };
    const response = await axios.post(
      REST_URL + "product/toggleFeature",
      payload,
      {
        headers: {
          authorization: getToken()
        }
      }
    );

    return response;
  }
  // reports
  async getTopSellingProducts(maxSize: number | undefined) {
    const response = await axios.post(
      `${REST_URL}product/topSellingProducts`,
      {
        maxSize
      },
      {
        headers: {
          authorization: getToken()
        }
      }
    );
    return response;
  }
  async getTopCustomersBySales(maxSize: number | undefined) {
    const response = await axios.post(
      `${REST_URL}product/topCustomers`,
      {
        maxSize
      },
      {
        headers: {
          authorization: getToken()
        }
      }
    );
    return response;
  }
  async getProfitableProduts(maxSize: number | undefined) {
    const response = await axios.post(
      `${REST_URL}product/profitableProducts`,
      {
        maxSize
      },
      {
        headers: {
          authorization: getToken()
        }
      }
    );
    return response;
  }
  async getFinancialYrSalesMargin(maxSize: number | undefined) {
    const response = await axios.post(
      `${REST_URL}product/financialYrMargin`,
      {
        maxSize
      },
      {
        headers: {
          authorization: getToken()
        }
      }
    );
    return response;
  }
}

export default new ProductService();
