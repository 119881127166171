// Product details page

import { Drawer, Paper, Text } from "@mantine/core";
import { Grid } from "@mui/material";
import productService from "api/product.service";
import { asPriceHtml, showPrice } from "common/utils";
import AddCartButton from "components/cart/Cart.Button";
import ImageLoader from "components/elements/image-loader/ImageLoader";
import Pagination from "components/elements/pagination/Pagination";
import TopSellingProducts from "components/home/TopSellingProducts";
import React, { useEffect, useRef, useState } from "react";
import MediaQuery from "react-responsive";
import { Menu2 } from "tabler-icons-react";
import { PageProps, ScreenProps } from "types/interface.types";
import { Brand, Category, Product, Products } from "types/model.types";
import ProductMenu from "./Landing.Menu";
import { ProductLandingWrapper } from "./landing.styles";

const Landing: React.FC<PageProps> = () => {
  const didMountRef = useRef(false);
  const [gridSize, setGridSize] = useState<number>(9);
  const [itemGridSize, setItemGridSize] = useState<number>(4);
  const size = useWindowSize();
  const [selectedBrand, setSelectedBrand] = useState<Brand | undefined>();
  const [selectedCategory, setSelectedCategory] = useState<
    Category | undefined
  >();
  const [products, setProducts] = useState<Products>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [prevPage, setPrevPage] = useState<number>(1);
  const [pageSize] = useState<number>(30);
  const listViewSize = 600;
  const [opened, setOpened] = useState(false);

  // Hook
  function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState<ScreenProps>({
      width: 1000,
      height: 780
    });

    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }
  useEffect(() => {
    if (didMountRef.current) {
      // update

      if (
        prevPage !== currentPage ||
        (selectedBrand === undefined && selectedCategory === undefined)
      ) {
        setPrevPage(currentPage);
        productService
          .get(currentPage, "ASC", selectedCategory?.name, selectedBrand?.name)
          .then((json) => {
            if (json) {
              const p: Product = json.data[0];
              setTotalRecords(p?.totalRows ?? 0);
              setProducts(json.data);
              if (json.data.length === 0) {
                setCurrentPage(1);
                setPrevPage(1);
              }
            }
          });
      }
    } else {
      // initial mount: ComponentDidMount
      didMountRef.current = true;
      window.scrollTo(0, 0); // always scroll to top on 1st load
      productService.get(currentPage, "ASC").then((json) => {
        if (json) {
          const p: Product = json.data[0];
          setTotalRecords(p?.totalRows ?? 1);
          setProducts(json.data);
        }
      });
    }
    if (size.width <= listViewSize) {
      if (itemGridSize !== 12) {
        setItemGridSize(12);
      }
    } else {
      if (itemGridSize !== 4) {
        setItemGridSize(4);
      }
    }

    if (size.width > listViewSize && size.width <= 993) {
      if (gridSize !== 12) {
        setGridSize(12);
      }
    } else if (size.width > 993 && size.width <= 1455) {
      if (gridSize !== 12) {
        setGridSize(12);
      }
    } else if (size.width <= listViewSize) {
      if (gridSize !== 12) {
        setGridSize(12);
      }
    } else {
      if (gridSize !== 9) {
        setGridSize(9);
      }
    }
  }, [
    size,
    selectedCategory,
    selectedBrand,
    currentPage,
    prevPage,
    totalRecords
  ]);

  // const asPrice = (amount: number): JSX.Element => {
  //   if (!amount)
  //     return (
  //       <span>
  //         0<sup>00</sup>
  //       </span>
  //     );
  //   const amt: string = amount.toFixed(2).toString();

  //   return (
  //     <span>
  //       {amt.substring(0, amt.indexOf("."))}
  //       <sup>{amt.substring(amt.indexOf(".") + 1)}</sup>
  //     </span>
  //   );
  // };

  return (
    <>
      <Drawer
        opened={opened}
        onClose={() => setOpened(false)}
        title="Product Filters"
        padding="xl"
        size="xl"
      >
        <ProductLandingWrapper>
          <ProductMenu
            onBrandClicked={(b: Brand) => {
              if (selectedBrand === b) setSelectedBrand(undefined);
              else setSelectedBrand(b);
              setPrevPage(-1);
              setOpened(false);
            }}
            onCategoryClicked={(c: Category) => {
              if (c === selectedCategory) setSelectedCategory(undefined);
              else setSelectedCategory(c);
              setPrevPage(-1);
              setOpened(false);
            }}
          />
        </ProductLandingWrapper>
      </Drawer>
      <ProductLandingWrapper Columns={3}>
        <Grid container spacing={0} className={"product"}>
          <MediaQuery minWidth={1455}>
            <Grid item xs={3} className={"product__left-container"}>
              <ProductMenu
                onBrandClicked={(b: Brand) => {
                  if (selectedBrand === b) setSelectedBrand(undefined);
                  else setSelectedBrand(b);
                  setPrevPage(-1);
                }}
                onCategoryClicked={(c: Category) => {
                  if (c === selectedCategory) setSelectedCategory(undefined);
                  else setSelectedCategory(c);
                  setPrevPage(-1);
                }}
              />
            </Grid>
          </MediaQuery>

          <Grid item xs={gridSize} className={"product__right-container"}>
            <div className="title-menu">
              <MediaQuery minWidth={1455}>
                <h1>
                  Products{" "}
                  {selectedCategory ? ` - ${selectedCategory?.name}` : ""}
                </h1>
              </MediaQuery>
              <MediaQuery maxWidth={1455}>
                <Menu2 onClick={() => setOpened(true)} size={16} />
                <h1>
                  Products{" "}
                  {selectedCategory ? ` - ${selectedCategory?.name}` : ""}
                </h1>
              </MediaQuery>
            </div>
            <div className="section">
              <span>Browse our huge range of </span>
              <span className="section-title">products</span>
            </div>
            <Pagination
              currentPage={currentPage}
              totalPages={Math.ceil(totalRecords / pageSize)}
              onPageChanged={(page) => {
                setCurrentPage(page);
              }}
            />
            <div>
              <em>{totalRecords} results found</em>
            </div>
            <Grid container spacing={0} className="box">
              {products.length === 0 ? (
                <h3>
                  No products found matching your selected category and/or
                  brand.
                </h3>
              ) : (
                products.map((p: Product) => (
                  <Grid
                    item
                    xs={itemGridSize}
                    key={p.id}
                    className="product__right-container--item"
                  >
                    <div className="image-outter-wrapper">
                      <div className="image-wrapper">
                        <ImageLoader
                          id={p.productCode}
                          folder="f"
                          name={p.featuredImageName}
                        />
                      </div>
                    </div>
                    <div
                      className={`product product--price ${
                        showPrice() && "product--price-money"
                      }`}
                      dangerouslySetInnerHTML={{
                        __html: showPrice() ? asPriceHtml(p.sellPrice2) : ""
                      }}
                    />

                    <Paper p="md" className="product__right-container--title">
                      <Text>{p.title}</Text>
                    </Paper>
                    <div className="product__right-container--cart">
                      <AddCartButton
                        id={p.id}
                        quantity={1}
                        title={p.title}
                        productCode={p.productCode}
                        price={p.sellPrice2}
                      />
                    </div>
                  </Grid>
                ))
              )}
            </Grid>
            <Pagination
              currentPage={currentPage}
              totalPages={Math.ceil(totalRecords / pageSize)}
              onPageChanged={(page) => {
                setCurrentPage(page);
              }}
            />{" "}
            <TopSellingProducts />
          </Grid>
        </Grid>
      </ProductLandingWrapper>
    </>
  );
};

export default Landing;
