//import { IconX, IconCheck } from "@tabler/icons";
import { Popover } from "@mantine/core";
import SearchIcon from "@mui/icons-material/Search";
import productService from "api/product.service";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { PageProps } from "types/interface.types";
import { Products } from "types/model.types";
import { HeaderSearchWrapper } from "./headerSearch.styles";

const HeaderSearch: React.FC<PageProps> = () => {
  const [popoverOpened, setPopoverOpened] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [products, setProducts] = useState<Products>([]);
  const history = useHistory();

  const handleClick = (code: number) => {
    history.push(`/products/details/${code}`);
  };

  return (
    <HeaderSearchWrapper>
      <Popover
        withArrow
        shadow="md"
        opened={popoverOpened}
        position="bottom"
        transition="fade"
        onClose={() => setPopoverOpened(false)}
        trapFocus={false}
        closeOnEscape={false}
        target={
          <>
            <SearchIcon />
            <input
              className="search-input"
              type="text"
              value={searchTerm}
              onFocus={() => {
                setPopoverOpened(true);
              }}
              onChange={(event) => {
                const term = event.currentTarget.value;
                setSearchTerm(term);
                productService.search(term).then((json) => {
                  setProducts(json.data);
                });
              }}
              onBlur={() => setPopoverOpened(false)}
              placeholder="Products, Brands and Categories"
            />
          </>
        }
      >
        <table className="search-results">
          <tbody>
            {products.length > 0 ? (
              products.map((p, k) => {
                return (
                  <tr
                    key={`psr-${k}`}
                    onClick={() => handleClick(p.productCode)}
                  >
                    <td>{p.productCode}</td>
                    <td>{p.title}</td>
                  </tr>
                );
              })
            ) : (
              <tr key={`psr-noresults`}>
                <td>Your search yield no results</td>
              </tr>
            )}
          </tbody>
        </table>
      </Popover>

      <button>Search</button>
    </HeaderSearchWrapper>
  );
};
export default HeaderSearch;
