import { Button, Group, Paper, Text, Textarea, TextInput } from "@mantine/core";
import * as React from "react";
import { toast } from "react-toastify";
import { AdminPageProps } from "types/interface.types";
import { useStyles } from "../mantine.styles";
import { AccountPageWrapper } from "./account.styles";

const SettingsPage: React.FC<AdminPageProps> = () => {
  const { classes } = useStyles();

  return (
    <AccountPageWrapper>
      <Paper shadow="md" radius="lg">
        <div className={classes.wrapper}>
          <form
            className={classes.form}
            onSubmit={(event) => event.preventDefault()}
          >
            <Text size="lg" weight={700} className={classes.title}>
              System Information
            </Text>

            <div className={classes.fields}>
              <TextInput label="Your email" placeholder="Your email" required />
              <Textarea
                mt="md"
                label="Your Phone Details"
                placeholder="Phone"
                minRows={3}
                required
              />
              <Textarea
                mt="md"
                label="Your Address"
                placeholder="Address"
                minRows={3}
                required
              />

              <TextInput
                mt="md"
                label="Your Trading Hours"
                placeholder="Trading Hours"
                required
              />

              <Group position="right" mt="md">
                <Button
                  type="submit"
                  onClick={() => {
                    toast.warn("Under Construction");
                  }}
                  className={classes.control}
                >
                  Save Details
                </Button>
              </Group>
            </div>
          </form>
        </div>
      </Paper>
    </AccountPageWrapper>
  );
};

export default SettingsPage;
