import styled from "styled-components";
const AccountLandingWrapper = styled.div`
  h1,
  h2,
  h3,
  h4 {
    text-transform: none !important;
  }
  h4 {
    font-size: 1.5rem;
  }
  .edit {
    cursor: pointer;
  }
  .account {
    &__left-container {
    }
    &__right-container {
      padding-left:10px;

    }
  }
`;
const AccountMenuWrapper = styled.div`
  clear: both;
  min-width:220px;

  &.mobile {
    height: 40px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    border: 1px solid #c2c2c2;
    box-shadow: 1px 1px 4px #ebebeb;
    -moz-box-shadow: 1px 1px 4px #ebebeb;
    -webkit-box-shadow: 1px 1px 4px #ebebeb;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    padding: 5px 5px;
    outline: none;
    cursor: pointer;
    margin-bottom: 35px;
    svg,
    h4 {
      float: left;
    }
    svg {
      position: relative;
      top: 4px;
    }
    h4 {
      margin: 0 5px;
    }
    clear: both;
  }
`;

export { AccountLandingWrapper, AccountMenuWrapper };

