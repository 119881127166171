import { GlobalColors } from "globalStyles";
import styled from "styled-components";
import { StyledNavigationProps } from "types/interface.types";

const MenuLabel = styled("label")`
  position: fixed;
  top: 22px;
  right: 6.2vw;
  border-radius: 50%;
  width: 20px;
  cursor: pointer;
  z-index: 1000;
  text-align: center;
`;
// these are the three hamburger icons
const Icon = styled.span<StyledNavigationProps>`
  position: relative;
  background-color: ${(props) =>
    props.clicked ? GlobalColors.colorTransparent : GlobalColors.colorBlack};
  width: 20px;
  height: 3px;
  display: inline-block;
  margin: 1px 0;
  transition: all 0.3s;
  &::before,
  &::after {
    content: "";
    background-color: ${(props) =>
      props.clicked ? GlobalColors.colorWhite : GlobalColors.colorBlack};
    width: 20px;
    height: 3px;
    display: inline-block;
    position: absolute;
    left: 0;
    transition: all 0.3s;
  }
  &::before {
    top: ${(props) => (props.clicked ? "0" : "-0.4rem")};
    transform: ${(props) => (props.clicked ? "rotate(135deg)" : "rotate(0)")};
  }
  &::after {
    top: ${(props) => (props.clicked ? "0" : "0.4rem")};
    transform: ${(props) => (props.clicked ? "rotate(-135deg)" : "rotate(0)")};
  }
  // ${MenuLabel}:hover &::before {
  //   top: ${(props) => (props.clicked ? "0" : "-0.5rem")};
  // }
  // ${MenuLabel}:hover &::after {
  //   top: ${(props) => (props.clicked ? "0" : "0.5rem")};
  // }
`;
// when you click the hamburger menubar, this is the circle that fills the background black with animation
// do not remove top, right, height and width attributes
const NavBackground = styled.div<StyledNavigationProps>`
  position: fixed;
  top: 15px;
  right: 30px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  z-index: 600;
  background-color: black;
  transition: transform 0.8s;
  z-index: 999;
  transform: ${(props) => (props.clicked ? "scale(200)" : "scale(0)")};
`;

export { MenuLabel, NavBackground, Icon };

