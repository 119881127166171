/* eslint-disable @typescript-eslint/no-explicit-any */
import EditAttributesIcon from "@mui/icons-material/EditAttributes";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { LinearProgress } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridToolbarFilterButton,
  GridValueGetterParams
} from "@mui/x-data-grid";
import productService from "api/product.service";
import BusyIndicator from "components/busy/BusyIndicator";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { AdminPageProps } from "types/interface.types";
import { Product, Products } from "types/model.types";
import { AccountPageWrapper } from "./account.styles";
import EditProductDialog from "./dialogs/EditProduct";

const ProductPage: React.FC<AdminPageProps> = () => {
  const didMountRef = useRef(false);
  const [busy, setBusy] = useState(false);
  const [products, setProducts] = useState<Products>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | undefined>();
  const columns: GridColDef[] = [
    {
      field: "action",
      headerName: "",
      sortable: false,
      filterable: false,
      hideable: false,
      width: 60,
      renderCell: (cellValues: any) => {
        //https://smartdevpreneur.com/add-buttons-links-and-other-custom-cells-in-material-ui-datagrid/#How_to_Add_a_Button
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const handleClick = (event: any, cellValues: GridValueGetterParams) => {
          event.stopPropagation();
          const p: Product = cellValues.row;
          setSelectedProduct(p);
          setOpen(true);
        };

        return (
          <ModeEditIcon
            className="__action"
            onClick={(event) => {
              handleClick(event, cellValues);
            }}
          />
        );
      }
    },
    {
      field: "id",
      headerName: "ID",
      width: 0,
      hide: true,
      sortable: false,
      filterable: false,
      hideable: false
    },
    { field: "productCode", headerName: "Code", width: 80 },
    { field: "title", headerName: "Title", width: 340 },
    {
      field: "productDesc",
      headerName: "Description",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      hide: true,
      width: 154,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.productDesc || ""}`
    },
    { field: "brandName", headerName: "Brand", width: 100 },
    { field: "categoryName", headerName: "Category", width: 100 },
    {
      field: "featured",
      headerName: "Featured",
      width: 80,
      renderCell: (cellValues: any) => {
        //https://smartdevpreneur.com/add-buttons-links-and-other-custom-cells-in-material-ui-datagrid/#How_to_Add_a_Button
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const handleClick = (event: any, cellValues: any) => {
          const p: Product = cellValues.row;
          const newState = !p.featured;
          if (p.id) {
            productService.toggleFeature(p.id, newState).then(() => {
              const updatedProducts: Products = products.map((_: Product) =>
                _.id === p.id
                  ? {
                      ..._,
                      featured: newState
                    }
                  : _
              );
              setProducts(updatedProducts);
              toast.success(`Feature ${newState ? "enabled" : "disabled"}`);
            });
          }

          event.stopPropagation();
        };

        return (
          <>
            {/* <span>{JSON.stringify(cellValues.row.Featured)}</span> */}
            <EditAttributesIcon
              fontSize="large"
              onClick={(event) => {
                handleClick(event, cellValues);
              }}
              className={`__action ${
                cellValues.row.featured ? " __action--on" : ""
              }`}
            />
          </>
        );
      }
    },
    {
      field: "sellPrice1",
      headerName: "SP 1",
      type: "number",
      hide: true,
      width: 80
    },
    {
      field: "sellPrice2",
      headerName: "SP 2",
      type: "number",
      width: 80
    },
    {
      field: "sellPrice3",
      headerName: "SP 3",
      type: "number",
      hide: true,
      width: 80
    },
    {
      field: "sellPrice4",
      headerName: "SP 4",
      type: "number",
      hide: true,
      width: 80
    },
    {
      field: "retail",
      headerName: "Retail",
      type: "number",
      hide: true,
      width: 80
    },
    {
      field: "unitsCtn",
      headerName: "Units/Ctn",
      type: "number",
      width: 80
    },
    { field: "barcode", headerName: "Barcode", width: 130, type: "string" }
  ];

  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true;
      setBusy(true);
      productService.get(1, "ASC", undefined, undefined, -1).then((json) => {
        if (json) {
          setProducts(json.data);
        }
        setBusy(false);
      });
    }
  }, [products, selectedProduct]);

  const handleSave = (updatedProduct: Product | undefined): void => {
    if (updatedProduct) {
      const updatedProducts: Products = products.map((p: Product) =>
        p.id === updatedProduct.id
          ? {
              ...p,
              productDesc: updatedProduct.productDesc,
              categoryName: updatedProduct.categoryName,
              brandName: updatedProduct.brandName,
              featured: updatedProduct.featured
            }
          : p
      );

      setProducts(updatedProducts);
    }

    setOpen(false);
  };

  return (
    <AccountPageWrapper>
      <h1>Products</h1>
      <BusyIndicator open={busy}></BusyIndicator>
      <DataGrid
        getRowId={(e: any) => e.id}
        rows={products}
        loading={busy}
        columns={columns}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        components={{
          Toolbar: GridToolbarFilterButton,
          LoadingOverlay: LinearProgress
        }}
      />
      <EditProductDialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        product={selectedProduct}
        onSave={handleSave}
      />
    </AccountPageWrapper>
  );
};

export default ProductPage;
