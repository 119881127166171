import pageService from "api/page.service";
import AdminPageBoundary from "components/elements/AdminPageBoundary";
import RtfEditor from "components/elements/editor/RtfEditor";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import "reactjs-popup/dist/index.css";
import { PageProps, RichEditorRef } from "types/interface.types";
import FeaturedProducts from "./FeaturedProducts";
import { HomeLayoutWrapper } from "./landing.styles";
import TopSellingProducts from "./TopSellingProducts";

const Home: React.FC<PageProps> = ({ onChangeRoute }) => {
  const editorRef = useRef<RichEditorRef>(null);
  const didMountRef = useRef(false);
  const [markup, setMarkup] = useState<string>("");
  const [busy, setBusy] = useState<boolean>(true);
  const [edit, setEdit] = useState<boolean>(false);

  useEffect(() => {
    if (didMountRef.current) {
      // update
    } else {
      // initial mount: ComponentDidMount
      didMountRef.current = true;

      pageService.get("home").then((json) => {
        setMarkup(json.data.HtmlMarkup ?? "no page data");
        setBusy(false);
      });

      window.scrollTo(0, 0); // always scroll to top on 1st load
    }
  }, []);

  return (
    <HomeLayoutWrapper>
      <FeaturedProducts onChangeRoute={onChangeRoute} />
      <AdminPageBoundary
        busy={busy}
        onEditClicked={() => {
          setEdit(!edit);
        }}
        onSaveClicked={() => {
          const markup = editorRef.current?.getContent() ?? "";
          pageService.update("home", markup).then(() => {
            setEdit(false);
            setMarkup(markup);
            toast.success("Page updated");
          });
        }}
      >
        {edit ? (
          <RtfEditor content={markup} stale={false} ref={editorRef} />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: markup }} />
        )}
      </AdminPageBoundary>{" "}
      <TopSellingProducts onChangeRoute={onChangeRoute} />
    </HomeLayoutWrapper>
  );
};

export default Home;
