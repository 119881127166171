import styled from "styled-components";

const AboutLandingWrapper = styled.div`
  ul li {
    list-style-type: circle;
    margin-left: 30px !important;
  }
  min-height: 300px;
`;

export { AboutLandingWrapper };

