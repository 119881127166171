import AuthService from "api/auth.service";
import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { JwtAuthData, NavigationDesktopProps } from "types/interface.types";
import { NavItems, NavigationWrapper } from "./navigation-desktop.styles";

const NavigationDesktop: React.FC<NavigationDesktopProps> = ({
  className,
  position,
  onRouteChanged
}) => {
  const didMountRef = useRef(false);
  const [toggleProducts, setToggleProducts] = useState<boolean>(false);
  const [user, setUser] = useState<JwtAuthData | null>(null);

  useEffect(() => {
    function refetch() {
      const user = AuthService.getCurrentUser();
      setUser(user);
    }

    window.addEventListener("storage", () => {
      // When storage changes refetch
      refetch();
    });

    if (didMountRef.current) {
      // update
    } else {
      didMountRef.current = true;
      // initial mount
      const user = AuthService.getCurrentUser();
      if (user) {
        setUser(user);
      }
    }

    return () => {
      // When the component unmounts remove the event listener
      window.removeEventListener("storage", refetch);
    };
  }, [user]);

  const handleRouteChanged = (): void => {
    if (onRouteChanged) onRouteChanged();
  };

  return (
    <NavigationWrapper className={className} position={position}>
      <NavItems className="top-nav">
        <li className={`header header--type-standard`}>
          <NavLink
            id="header-nav_home"
            className={"first"}
            onClick={(): void => handleRouteChanged()}
            activeClassName="link-active"
            exact
            to="/"
            key="top-nav_home-link"
          >
            Home
          </NavLink>
        </li>
        <li className={`header header--type-standard`}>
          <NavLink
            id="header-nav_products"
            onClick={(): void => {
              setToggleProducts(!toggleProducts);
            }}
            activeClassName="link-active"
            to="/products"
            key="top-nav_products-link"
          >
            Products
          </NavLink>
        </li>
        <li className={`header header--type-standard`}>
          <NavLink
            id="header-nav_about-us"
            onClick={(): void => handleRouteChanged()}
            activeClassName="link-active"
            to="/about-us"
            key="top-nav_about-us"
          >
            About Us
          </NavLink>
        </li>
        <li className={`header header--type-standard`}>
          <NavLink
            id="header-nav_contact-us"
            onClick={(): void => handleRouteChanged()}
            activeClassName="link-active"
            to="/contact-us"
            key="top-nav_contact-us"
          >
            Contact Us
          </NavLink>
        </li>
        {/* <li className={`header header--type-dropdown`}>
          <NavLink id='header-nav_my-account' className={'link link-dropdown'} onClick={(e) => { setAccountMenuEl(e.currentTarget); setToggleAccounts(!toggleAccounts) }}
            activeClassName='link-active'
            to="/my-account"
            key='top-nav_my-account'>My Account {toggleAccounts ? <ArrowDropUp /> : <ArrowDropDown />}</NavLink>
          {
            accountMenuEl && <AccountMenu anchorRef={accountMenuEl} onClick={handleAccountMenuClicked} />
          }          
        </li> */}
        {user !== undefined && user !== null ? (
          <li className={`header header--type-standard`}>
            <NavLink
              id="header-nav_my-account"
              activeClassName="link-active"
              to="/my-account"
              key="top-nav_my-account"
            >
              My Account
            </NavLink>
          </li>
        ) : (
          <></>
        )}
        {/* <li className={`header header--type-dropdown`}>
          <NavLink
            id="header-nav_styling"
            onClick={(): void => handleRouteChanged()}
            activeClassName="link-active"
            to="/styles"
            key="top-styling"
          >
            Styling <StyleIcon />
          </NavLink>
        </li> */}
      </NavItems>
    </NavigationWrapper>
  );
};

export default NavigationDesktop;
