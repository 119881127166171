import styled from "styled-components";

const HomeLayoutWrapper = styled.div`
  min-height: 350px;
`;
const FeaturedProductWrapper = styled.div`
  margin-bottom: 20px;
  .card {
    min-height: 270px;
    &-title {
      min-height: 120px;
    }
  }
  .mantine-Card-cardSection {
    text-align: center;
    img {
      margin: auto;
    }
  }

  @media (min-width: 1024px) {
    .card {
      min-height: 230px;
      &-title {
        min-height: 100px;
      }
    }
  }
`;
export { HomeLayoutWrapper, FeaturedProductWrapper };
