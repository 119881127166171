import axios from "axios";
import { REST_URL } from "common/constants";
import { authHeader } from "./common.service";

class UserService {
  async get() {
    const response = await axios.post(
      REST_URL + "users/getUsers",
      {},
      authHeader()
    );

    return response ?? [];
  }

  async import(debtorId: string) {
    const response = await axios.post(
      REST_URL + "users/import",
      { debtorId },
      authHeader()
    );

    return response ?? {};
  }

  async changeLockState(id: string, state: boolean) {
    const response = await axios
      .post(
        REST_URL + "users/changeState",
        {
          id,
          state: state ? 1 : 0
        },
        authHeader()
      )
      .then((json) => json);

    return response;
  }
}

export default new UserService();
