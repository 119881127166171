import { GlobalColors, GlobalLayout } from "globalStyles";
import styled from "styled-components";

const AppWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;
const MainHeaderWrapper = styled.div`
  clear: both;
  width: 100%;
  height: ${GlobalLayout.Header_Height};
  position: fixed;
  top: 0;
  z-index: 200;
  background-color: ${GlobalColors.colorWhite};
`;
const MainContentWrapper = styled.div`
  clear: both;
  min-height: 350px;
  background-color: #fff;
  position: relative;
  margin-top: ${GlobalLayout.Header_Height};
  overflow: hidden;
  margin-bottom: 40px; // ${GlobalLayout.Footer_Height};

  .breadcrumb {
    margin-bottom: 8px;
  }
  @media (min-width: 2500px) {
    .home-banner {
      img {
        width: 100%;
      }
    }
  }

  .home {
    overflow: hidden;
    &-banner {
      justify-content: center;
      height: 420px;
      overflow: hidden;
      img {
        margin-left: auto;
        margin-right: auto;
      }
    }
    &-banner__feature {
      position: relative;
      top: -95px;

      &-item {
        overflow: hidden;
        background-color: #fff;
        height: 20vh;
        margin: 20px;
        width: 24.4vw;

        max-width: 20.4vw;
        max-height: 195px;

        @media (max-width: 1024px) {
          display: none;
        }

        img {
          width: 20.4vw;
          height: 16.2vh;

          @media (min-width: 2500px) {
            // 4K screen
            height: 12.9vh;
            width: 19.4vw;
          }
        }
      }
    }
  }

  /* The alert message box */
  .alert {
    padding: 20px;
    background-color: #52a8f5;
    color: white;
    margin-bottom: 15px;

    & > div > span {
      position: relative;
      top: -7px;
      left: 20px;
      font-weight: 700;
      text-indent: 20px;
    }
    &.minimise {
      border: 1px slid red !important;
      overflow: hidden;
      height: 60px;
    }
  }
  .message {
    padding-top: 15px;
    padding-left: 40px;
  }

  /* The close button */
  .closebtn {
    margin-left: 15px;
    color: white;
    font-weight: bold;
    float: right;
    font-size: 22px;
    line-height: 20px;
    cursor: pointer;
    transition: 0.3s;
    position: relative;
    top: 8px;
  }

  /* When moving the mouse over the close button */
  .closebtn:hover {
    color: #f7931d;
  }
`;

const MainLayoutInnerContentWrapper = styled.div`
  position: relative;
  width: ${GlobalLayout.Content_Width};
  margin: auto;
`;

const MainFooterWrapper = styled.div`
  margin-top: auto
  position:relative;
  bottom: 0;
  background-color: ${GlobalColors.colorFooterBackground};
`;

export {
  AppWrapper,
  MainHeaderWrapper,
  MainContentWrapper,
  MainFooterWrapper,
  MainLayoutInnerContentWrapper
};
