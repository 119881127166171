import { GlobalLayout } from "globalStyles";
import { LineOnHover } from "mixins";
import styled from "styled-components";

const FooterWrapper = styled.div`
  min-height: ${GlobalLayout.Footer_Height};

  @media (max-width: 998px) {
    // text-align: center;
  }

  @media (min-width: 998px) {
    // #wrapper {
    //   margin-right: 11vw;
    //   display: flex;
    //   justify-content: space-between;
    // }
  }
`;
const SocialMediaWrapper = styled.div`
  padding: 25px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 10.7vh;
  @media (max-width: 998px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: right;
  }
`;
const CopyrightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 5.7vh;
  @media (max-width: 998px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: right;
  }
`;
const IconsList = styled.ul`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  @media (max-width: 998px) {
    justify-content: center;
  }
`;
const ListItem = styled.li`
  cursor: pointer;
  padding-left: 5%;

  @media (min-width: 998px) {
    position: relative;
    ${LineOnHover({
      left: "40%",
      height: "1px",
      color: "#A9A9A9",
      width: "20px",
      top: "120%",
      time: "0.3s"
    })}
  }
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
`;
const Copyright = styled.p`
  color: #a9a9a9;
  margin-top: 11.2vh;
`;

export {
  FooterWrapper,
  Icon,
  CopyrightWrapper,
  Copyright,
  IconsList,
  SocialMediaWrapper,
  ListItem
};

