import { Image } from "@mantine/core";
import { LinearProgress } from "@mui/material";
import productService from "api/product.service";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ImageWrapper } from "./imageLoader.style";

type ImageProps = {
  id?: number;
  folder: string;
  name: string;
  height?: number;
  className?: string;
};

const ImageLoader: React.FC<ImageProps> = ({
  id,
  folder,
  name,
  height,
  className
}) => {
  const [image, setImage] = useState<string>("");
  const [busy, setBusy] = useState<boolean>(true);
  const history = useHistory();

  useEffect(() => {
    let mounted = true;
    (async () => {
      productService
        .getImage(folder, name)
        .then((res) => {
          if (mounted) {
            setImage(res.data.img);
            setBusy(false);
          }
        })
        .catch(() => {
          if (mounted) {
            setBusy(false);
          }
        });
    })();

    return () => {
      mounted = false;
    };
  }, [name, folder]);

  const handleImageClick = () => {
    if (id) history.push(`/products/details/${id}`);
  };

  return (
    <ImageWrapper onClick={handleImageClick}>
      {busy ? (
        <LinearProgress />
      ) : (
        <Image
          src={image}
          className={className ?? ""}
          height={height ?? 180}
          width={height ?? 180}
          alt={name}
        />
      )}
    </ImageWrapper>
  );
};

export default ImageLoader;
