import { Button, Group, Paper, Text } from "@mantine/core";
import NotificationAddIcon from "@mui/icons-material/NotificationAdd";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import notificationService from "api/notification.service";
import RtfEditor from "components/elements/editor/RtfEditor";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { AdminPageProps, RichEditorRef } from "types/interface.types";
import { useStyles } from "../mantine.styles";
import { AccountPageWrapper } from "./account.styles";

const SystemNotificationPage: React.FC<AdminPageProps> = () => {
  const editorRef = useRef<RichEditorRef>(null);
  const [markup, setMarkup] = useState<string | undefined>(undefined);
  const { classes } = useStyles();

  useEffect(() => {
    let mounted = true;

    notificationService.get().then((json) => {
      if (mounted) {
        setMarkup(json.data?.Message ?? "");
      }
    });

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <AccountPageWrapper>
      <Paper shadow="md" radius="lg">
        <div className={classes.wrapper}>
          <form
            className={classes.form}
            onSubmit={(event) => event.preventDefault()}
          >
            <Text size="lg" weight={700} className={classes.title}>
              System Notification
            </Text>

            <div className={classes.fields}>
              <RtfEditor
                content={markup}
                stale={false}
                ref={editorRef}
                placeHolder={""}
              />

              <Group position="right" mt="md">
                <Button
                  onClick={() => {
                    const markup: string =
                      editorRef.current?.getContent() ?? "";
                    notificationService.send(markup).then((json) => {
                      if (json.data.err) {
                        toast.error("Failed to send notification");
                      } else {
                        toast.success("Notification sent");
                      }
                    });
                  }}
                  leftIcon={<NotificationAddIcon style={{ color: "#fff" }} />}
                >
                  Send Notification
                </Button>
                {"  "}
                <Button
                  onClick={() => {
                    notificationService.clear();
                    setMarkup(undefined);
                  }}
                  leftIcon={<NotificationsOffIcon style={{ color: "#fff" }} />}
                >
                  Clear Notifications
                </Button>
              </Group>
            </div>
          </form>
        </div>
      </Paper>
    </AccountPageWrapper>
  );
};

export default SystemNotificationPage;
